import type { FC } from "react";

import Icon, { type IconProps } from "../Icon";

import styles from "../icons.module.scss";

const ShareIcon: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <g className={styles.shape}>
        <path d="M13.0001 19C12.0001 19 12.0001 14 12.0001 14C5.00008 14 4.50007 19 4.00008 19C2.10948 19 2.00011 8 12.0001 8C12.0001 8 12.0001 3 13.0001 3C14.0001 3 21.0001 9.5 21.0001 11C21.0001 12.5 14.0001 19 13.0001 19Z" />
      </g>
    </Icon>
  );
};

export default ShareIcon;
