import { FC } from "react";

import type { UrlString } from "~/domain/basic.types";

import styles from "./Avatar.module.scss";

interface AvatarProps {
  photo: UrlString;
}

const Avatar: FC<AvatarProps> = ({ photo }) => {
  return <img className={styles.avatar} src={photo} alt={"Avatar"} />;
};

export default Avatar;
