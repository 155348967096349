import React, { useEffect, useState } from 'react';
import { fetchFoses, fetchVenuses } from '../services/api';
import useStore from '../services/store/store';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';


const FieldsList = styled.ul`
  margin: 0;
  padding: 16px;
  list-style: none;


  li {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledGroup = styled(ToggleButtonGroup)`
    border: 0;
    margin: 4px;
    & {
      button {
        line-height: 1;
        padding: 4px 8px;
        border: none !important;
        background: rgba(0, 0, 0, 0.04);
        &.Mui-selected {
          background: rgba(0, 0, 0, 0.06);
          padding: 4px 8px;
          font-weight: 700;
        }
        &.Mui-disabled {
          border: none;
        }
      }

      button:not(:first-of-type) {
        border-radius: 8px;;
      }

      button:first-of-type {
        border-radius: 8px;;
      }
    }
`;

const TypesOverlay = (props) => {

  const [workTypes, setWorkTypes] = useState([]);
    const [selectedWorkTypes, setSelectedWorkTypes, openOverlays] = useStore((state) => [state.selectedWorkTypes, state.setSelectedWorkTypes, state.openOverlays]);

    useEffect(() => {
    const initOverlay = async () => {
      const res = await fetchVenuses(openOverlays[props.overlayType]);
      setWorkTypes(res.data);
    };

    initOverlay();
  }, []);

  return (
    <BasicDialog {...props}>
      <FieldsList>
        {workTypes.map(i => (
          <li>
            <FormControlLabel
            onChange={(e) => setSelectedWorkTypes(i.id)}
            checked={selectedWorkTypes.some(id => i.id === id)} key={i.id} control={<Checkbox />}
            label={i.display_name}
            />
            <div className="right">
              <StyledGroup
                value={'left'}
                exclusive
                size="small"
                aria-label="text alignment"
              >
                {i.works_count && (
                  <Tooltip title="works" placement="top" arrow>
                    <ToggleButton value="left" aria-label="left aligned">
                      {i.works_count}
                    </ToggleButton>
                  </Tooltip>
                )}
                {i.q_citations && (
                  <Tooltip title="citations" placement="top" arrow>
                    <ToggleButton className="Mui-selected" value="right" aria-label="right aligned">
                      {i.q_citations}
                    </ToggleButton>
                  </Tooltip>
                )}
              </StyledGroup>
            </div>
          </li>
        ))}
      </FieldsList>
    </BasicDialog>
  )
};

export default TypesOverlay;
