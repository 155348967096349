import React from 'react';

interface WorkspaceContextProps {
  disabled?: boolean;
  logicalUnitId?: string;
}

const initialWorkspaceContext: WorkspaceContextProps = {
  disabled: false,
};

const WorkspaceContext = React.createContext(initialWorkspaceContext);

interface WorkspaceContextProviderProps extends React.PropsWithChildren, WorkspaceContextProps {}

export default function WorkspaceContextProvider({
  disabled,
  logicalUnitId,
  children,
}: WorkspaceContextProviderProps) {
  return (
    <WorkspaceContext.Provider value={{ disabled, logicalUnitId }}>{children}</WorkspaceContext.Provider>
  );
}

export function useWorkspaceContext() {
  return React.useContext(WorkspaceContext);
}
