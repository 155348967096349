import { useState, type FC } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Dropdown } from "@mui/base";
import { ClickAwayListener, Popper } from "@mui/material";

import { useAuth } from "~/services/Auth";
// @ts-expect-error @TODO remove after modal refactor
import useStore from "~/services/store/store";

import Angle from "~/icons/Angle";
import SearchHistory from "~/icons/SearchHistory";
import Favorites from "~/icons/Favorites";
import SignOut from "~/icons/SignOut";

import styles from "./UserMenu.module.scss";

interface UserMenuProps {
  user: string;
}

const UserMenu: FC<UserMenuProps> = ({ user }) => {
  const auth = useAuth();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const setOpenOverlay = useStore((state: any) => state.setOpenOverlay);

  return (
    <Dropdown>
      <button
        className={styles.button}
        id="eai-user-menu-button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Angle
          className={styles.shevron}
          style={{
            transform: isOpen ? "rotateX(180deg)" : "",
          }}
        />
        {user}
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Popper
            open={true}
            anchorEl={document.getElementById("eai-user-menu-button")}
            placement="bottom-end"
            slots={{ root: "section" }}
            slotProps={{
              root: { className: styles.menu },
            }}
          >
            <header>
              <h2 className={styles["user-name"]}>{auth.getUserName()}</h2>
            </header>
            <div className={styles.content}>
              <ul className={styles["items-group"]}>
                <li
                  className={styles.item}
                  onClick={() => {
                    setOpenOverlay({ HISTORY: "HISTORY" });
                    setIsOpen(false);
                  }}
                >
                  <SearchHistory />
                  History
                </li>
                <NavLink
                  className={styles.item}
                  to="/favorites"
                  onClick={() => setIsOpen(false)}
                >
                  <Favorites />
                  Favorites
                </NavLink>
              </ul>
              <ul className={styles["items-group"]}>
                <li
                  className={styles.item}
                  onClick={() => {
                    auth.signout(() => {
                      history.push("/");
                    });
                    setIsOpen(false);
                  }}
                >
                  <SignOut />
                  Sign Out
                </li>
              </ul>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </Dropdown>
  );
};

export default UserMenu;
