import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const UserIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M8.00098 16C12.9647 16 16.001 12.9637 16.001 8C16.001 3.03627 12.9647 0 8.00098 0C3.03724 0 0.000976562 3.03627 0.000976562 8C0.000976562 12.9637 3.03724 16 8.00098 16ZM8.00098 2.66667C9.60098 2.66667 10.6676 3.73333 10.6676 5.33333C10.6676 6.93333 9.60098 8 8.00098 8C6.40098 8 5.33431 6.93333 5.33431 5.33333C5.33431 3.73333 6.40098 2.66667 8.00098 2.66667ZM5.50098 8.88889C5.84546 8.89018 6.04753 8.99889 6.2838 9.126C6.6191 9.30638 7.02325 9.52381 8.00098 9.52381C8.97729 9.52381 9.38169 9.30594 9.71671 9.12545C9.9536 8.99783 10.1558 8.88889 10.501 8.88889C11.0565 8.88889 12.4454 10.4762 12.4454 11.1111C12.4454 11.746 11.0469 13.3333 7.99614 13.3333C4.94542 13.3333 3.55653 11.746 3.55653 11.1111C3.55653 10.4762 4.94542 8.88681 5.50098 8.88889Z" />
      </g>
    </Icon>
  );
};

export default UserIcon;
