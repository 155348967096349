import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';
import { fetchInstitutions } from '../../services/api';
import InstitutionItem from './InstitutionItem';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;

  li + li {
    margin: 20px 0 0;
  }

`;

const pageSize = 15;

const Institutions = ({ clusterId }) => {

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await fetchInstitutions(clusterId, { params: { page: 1, size: pageSize } });
        console.log(res);
        setResults(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (clusterId) {
      init();
    }
  }, [clusterId]);

  // const onRequestPage = async (page) => {
  //   console.log(page);
  //   try {
  //     const { data } = await fetchInstitutions(clusterId, { params: { page, size: pageSize } });
  //     const list = [...data.items];
  //     setResults({ ...results, items: [...results.items, ...list] });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const onUpdate = (item, instituion) => {
    const list = [...results.items];
    const institutionIndex = list.findIndex(el => el.id === instituion.id);
    const itemIndex = list[institutionIndex].items.findIndex(el => el.id === item.id);
    list[institutionIndex].items[itemIndex] = item;
    setResults({ ...results, items: list });
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
        <CircularProgress />
      </Box>
    )
  }


  return results && (
    <ListWrapper>
      {/* <InfiniteScroll
        pageStart={0}
        loadMore={onRequestPage}
        hasMore={results.totalItems > results.items.length}
        useWindow={false}
        loader={<div className="loader" key={0}>Loading ...</div>}
      > */}
      {results.items.map((i) => (
        <li key={i.id}>
          <InstitutionItem
            id={i.id}
            onUpdate={(item) => onUpdate(item, i)}
            name={i.display_name}
            hindex={i.max_hindex}
            works={i.works_count}
            items={i.items}
          />
        </li>
      ))}
      {/* </InfiniteScroll> */}
    </ListWrapper>
  )
};

export default Institutions;
