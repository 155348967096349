import axios from "axios";
import { flow, makeAutoObservable } from "mobx";
import QueryString from "qs";
import type { AuthorId } from "~/domain/Author/Author.types";
import type { ClusterId } from "~/domain/Cluster/Cluster.types";
import { FieldOfStudyId } from "~/domain/FieldOfStudy/FieldOfStudy.types";
import type { InstitutionId } from "~/domain/Institution/Institution.types";
import type { SourceId } from "~/domain/Sources/Sources.types";
import type { Work } from "~/domain/Work/Work.types";
import { BASE_HOST } from "~/store/api/const";
import {
  PaginatedResponse,
  VanillaPaginatedRequestParams,
} from "~/store/api/response.types";

// @TODO move to the domain scope
export type Scope = "author" | "institution" | "search";
export type ScopeValueId = AuthorId | InstitutionId | ClusterId;

export type FilteredRequestParams = {
  foses: Array<FieldOfStudyId>;
  sources: Array<SourceId>;
  authors: Array<AuthorId>;
  institutions: Array<InstitutionId>;
  year_from: string;
  year_to: string;
};

class WorksState {
  private _total: number | null = 0;
  private _page?: number = 0;
  private _isLoading: boolean = false;
  private _works: Array<Work> = [];

  constructor() {
    makeAutoObservable(this);
  }

  get works() {
    return this._works;
  }

  get hasMore() {
    if (this._total === null) return true;

    return this._works.length < this._total;
  }

  get total() {
    return this._total;
  }

  get isFirstPageLoading() {
    return this._page === 1 && this._isLoading;
  }

  fetch = flow(function* (
    this: WorksState,
    params: VanillaPaginatedRequestParams & FilteredRequestParams,
    scope: Scope,
    scopeValueId: ScopeValueId,
  ) {
    this._page = +params.page;
    if (params.page === "1") this._total = null;
    this._isLoading = true;
    const { data } = yield axios.get<PaginatedResponse<Work>>(
      `${BASE_HOST}/api/v2/${mapScopeWithSubfolder(scope)}/${scopeValueId}/works?${QueryString.stringify(params, { arrayFormat: "repeat" })}`,
    );
    this._isLoading = false;

    if (params.page === "1") {
      this._works = data.items;
      this._total = data.total;
    } else {
      this._works = this._works.concat(data.items);
    }
  });
}

export default new WorksState();

type Subfolder = "authors" | "institution" | "clusters";

function mapScopeWithSubfolder(scope: Scope): Subfolder {
  if (scope === "author") return "authors";
  if (scope === "institution") return "institution";
  if (scope === "search") return "clusters";

  throw new Error("Unknown scope for mapping");
}
