import { FieldOfStudyId } from "~/domain/FieldOfStudy/FieldOfStudy.types";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const selectTotalWorksCount = (state: RootState) =>
  state.ActiveFilter.totalWorks;

export const selectTotalAuthorsCount = (state: RootState) =>
  state.ActiveFilter.totalAuthors;

export const selectScope = (state: RootState) => state.ActiveFilter.scope;

export const selectFieldsOfStudiesValues = (state: RootState) =>
  state.ActiveFilter.fieldsOfStudies.values;

export const selectSourcesValues = (state: RootState) =>
  state.ActiveFilter.sources.values;

export const selectPeriodValues = (state: RootState) =>
  state.ActiveFilter.period.values || [];

export const selectPeriodBoundaries = createSelector(
  selectPeriodValues,
  (values) => {
    return values.length
      ? {
          minYear: values[0].year,
          maxYear: values[values.length - 1].year + 1,
        }
      : null;
  },
);

export const selectFielsOfStudiesFilter = (state: RootState) =>
  state.ActiveFilter.fieldsOfStudies.object;

export const selectSourcesFilter = (state: RootState) =>
  state.ActiveFilter.sources.object;

export const selectPeriodFilter = (state: RootState) =>
  state.ActiveFilter.period.object;

export const selectFieldsOfStudiesQueryString = (state: RootState) => {
  return Object.entries(state.ActiveFilter.fieldsOfStudies.object)
    .reduce<Array<FieldOfStudyId>>(
      (filterQuery, [fieldOfStudyId, fieldOfStudyState]) => {
        if (fieldOfStudyState === "checked") {
          filterQuery.push(fieldOfStudyId);
        }

        return filterQuery;
      },
      [],
    )
    .join(",");
};

export const selectSourcesQueryString = (state: RootState) => {
  return Object.keys(state.ActiveFilter.sources.object).join(",");
};

export const selectSelectedFieldsOfStudiesAmount = (state: RootState) => {
  let counter = 0;

  Object.values(state.ActiveFilter.fieldsOfStudies.object).forEach(
    (state) => state === "checked" && counter++,
  );

  return counter;
};

export const selectSelectedSourcesAmount = (state: RootState) => {
  return Object.keys(state.ActiveFilter.sources.object).length;
};
