import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const LinkedInIcon: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <g className={styles.shape}>
        <rect width="24" height="24" rx="12" />
      </g>
      <path
        d="M8.68706 5.816V8.248H6.09506V5.816H8.68706ZM6.19106 17V9.608H8.60706V17H6.19106ZM15.0973 9.368C16.7293 9.368 17.8813 10.312 17.8813 12.424V17H15.4813V13.096C15.4813 11.992 15.0173 11.496 14.1213 11.496C13.3053 11.496 12.6973 11.976 12.6973 13.288V17H10.2813V9.608H12.6493V10.728H12.6813C13.1933 9.864 14.0573 9.368 15.0973 9.368Z"
        fill="white"
      />
    </Icon>
  );
};

export default LinkedInIcon;
