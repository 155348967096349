import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const SignOut: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="3x" viewBoxCoef="3x" {...props}>
      <g className={styles.shape}>
        <path d="M18.8176 4.24798C16.5753 3.32547 12.8475 2.10295 12.5 4.00008C12.5 4.00008 12.4261 4.29557 12.3352 5.00013L10.9342 5.00012C10.0477 5.00007 9.28387 5.00003 8.67221 5.08227C8.01669 5.1704 7.38834 5.36914 6.87868 5.87881C6.36902 6.38847 6.17027 7.01682 6.08214 7.67234C5.99991 8.28399 5.99995 9.0478 6 9.93429L6 11.0001H4.41421L4.70711 10.7072C5.09763 10.3167 5.09763 9.68354 4.70711 9.29302C4.31658 8.9025 3.68342 8.9025 3.29289 9.29302L1.29289 11.293C0.902369 11.6835 0.902369 12.3167 1.29289 12.7072L3.29289 14.7072C3.68342 15.0978 4.31658 15.0978 4.70711 14.7072C5.09763 14.3167 5.09763 13.6835 4.70711 13.293L4.41421 13.0001H9C9.55229 13.0001 10 12.5524 10 12.0001C10 11.4478 9.55229 11.0001 9 11.0001H8V10.0001C8 9.02905 8.00212 8.40134 8.06431 7.93883C8.12262 7.50509 8.21677 7.36914 8.29289 7.29302C8.36902 7.2169 8.50496 7.12275 8.9387 7.06443C9.40121 7.00225 10.0289 7.00013 11 7.00013H12.1465C12.0642 8.21481 12 9.85083 12 12.0001C12 14.21 12.0499 15.8253 12.1181 17.0001H9C8.44772 17.0001 8 16.5524 8 16.0001V15.0001C8 14.4478 7.55228 14.0001 7 14.0001C6.44772 14.0001 6 14.4478 6 15.0001V16.0001C6 17.657 7.34315 19.0001 9 19.0001H12.2939C12.4027 19.8222 12.5001 20.0152 12.5 20.0001C12.8473 21.8962 16.5713 20.6761 18.8139 19.7538C19.5937 19.4331 19.9835 19.2728 20.3141 18.8308C20.6446 18.3888 20.6868 17.9835 20.771 17.173C20.8886 16.0423 21 14.3496 21 11.9934C21 9.6396 20.8889 7.95095 20.7714 6.8234C20.6873 6.01558 20.6452 5.61168 20.3147 5.16984C19.9843 4.72801 19.5954 4.568 18.8176 4.24798Z" />
      </g>
    </Icon>
  );
};

export default SignOut;
