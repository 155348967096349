import { useCallback, type FC } from "react";
import type { Email } from "../../domain/Author/Author.types";
import Paper from "../../ui-kit/Paper";
import Avatar from "../../ui-kit/Avatar";
import Button from "../../ui-kit/Button";
import LinkedIn from "../../icons/LinkedIn";
import EmailIcon from "../../icons/Email";
import Share from "../../icons/Share";
import styles from "./InfoCard.module.scss";
import { Link } from "@mui/material";
// @ts-expect-error
import useStore from "../../services/store/store";
import { UrlString } from "../../domain/basic.types";

export interface InfoCardProps {
  id: string;
  name: string;
  photo?: string;
  status?: string;
  description?: string;
  articlesCount: number;
  articlesPercentile?: number;
  quotesCount: number;
  quotesPercentile?: number;
  hIndex?: number;
  hIndexPercentile?: number;
  linkedin?: UrlString;
  emails?: Array<Email>;
  googleScholar?: UrlString;
}

function useInfoCardStaticText() {
  return {
    articlesLabel: "Статей",
    quotesLabel: "Цитат",
    hIndexLabel: "H-индекс",
  };
}

interface StatisticItemProps {
  label: string;
  relative?: number;
  absolute: number;
}

const StatisticItem: FC<StatisticItemProps> = ({
  label,
  relative = 0,
  absolute,
}) => {
  return (
    <div className={styles.statistic}>
      <span className={styles.label}>{label}</span>
      <div className={styles.relative}>
        <div className={styles.percentile} style={{ width: relative }} />
        <div className={styles.absolute}>{absolute}</div>
      </div>
    </div>
  );
};

function handleShare(): void {
  navigator.clipboard.writeText(window.location.href);
}

const InfoCard: FC<InfoCardProps> = (props) => {
  const { articlesLabel, quotesLabel, hIndexLabel } = useInfoCardStaticText();
  const [setOpenOverlay] = useStore((state: any) => [state.setOpenOverlay]);

  const handleOpenEmails = useCallback(
    () => setOpenOverlay({ EMAILS: props.emails }),
    [props.emails, setOpenOverlay],
  );

  return (
    <Paper className={styles.card}>
      <header className={styles.header}>
        {props.photo && <Avatar photo={props.photo} />}
        <div className={styles.info}>
          <h1 className={styles.name}>{props.name}</h1>
          {props.status && <p className={styles.status}>{props.status}</p>}
          {props.description && (
            <p className={styles.description}>{props.description}</p>
          )}
        </div>
      </header>
      <div className={styles.statistics}>
        <StatisticItem
          label={articlesLabel}
          relative={props.articlesPercentile}
          absolute={props.articlesCount}
        />
        <StatisticItem
          label={quotesLabel}
          relative={props.quotesPercentile}
          absolute={props.quotesCount}
        />
        {!!props.hIndex && (
          <StatisticItem
            label={hIndexLabel}
            relative={props.hIndexPercentile}
            absolute={props.hIndex}
          />
        )}
      </div>
      <div className={styles.contacts}>
        {props.linkedin && (
          <Button accent="light" shape="square">
            <Link href={props.linkedin} target="_blank" display="inline-flex">
              <LinkedIn />
            </Link>
          </Button>
        )}
        {!!props.emails?.length && (
          <Button accent="light" shape="square" onClick={handleOpenEmails}>
            <EmailIcon />
          </Button>
        )}
        <Button
          className={styles.share}
          accent="light"
          shape="square"
          onClick={handleShare}
        >
          <Share />
        </Button>
      </div>
    </Paper>
  );
};

export default InfoCard;
