import { useEffect, useState, type FC } from "react";
import type { Work as WorkType, WorkId } from "../../domain/Work/Work.types";
import styled from "@emotion/styled";
import { Box, Stack } from "@mui/system";
// @ts-expect-error
import CustomChip from "../../common/chip";
import { Typography } from "@mui/material";
import { Author } from "../../domain/Author/Author.types";
import AuthorItem from "../AuthorItem/_AuthorItem";
import { getWorkById } from "~/store/api";

interface WorkProps {
  id: WorkId;
}

const ArticleWrapper = styled(Stack)`
  /* .main-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  } */
  cursor: pointer;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 400;

  .cited {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .field,
  .author {
    color: rgba(0, 0, 0, 0.42);
    margin-left: 8px;
  }
`;

const Work: FC<WorkProps> = ({ id }) => {
  const work = useWorkState(id);

  return (
    <ArticleWrapper spacing={2} display="flex">
      <Box>
        <span className="date">{work?.publication_date}</span>
      </Box>
      <Box sx={{ mt: "4px" }}>
        <Typography variant="h3">{work?.title}</Typography>
        <Stack direction="row" sx={{ mt: "4px" }} spacing={1} flexWrap="wrap">
          {work?.fos_list
            ?.slice(0, 3)
            .map((fos) => <CustomChip size="small" label={fos} key={fos} />)}
        </Stack>
      </Box>
      <Box sx={{ mt: "4px" }}>
        <Typography variant="h3">Abstract</Typography>
        <Typography>{work?.abstract}</Typography>
      </Box>
      <Box sx={{ mt: "4px" }}>
        <Typography variant="h3">LinkedIn</Typography>
        {work?.authors?.map((author: Author) => (
          <AuthorItem
            key={author.id}
            id={author.id}
            citedByCount={author.cited_by_count}
            name={author.display_name}
            hindex={author.hindex}
            worksCount={author.works_count}
            hasLinkedIn={!!author.linkedin_url}
            hasEmail={!!author.emails?.length}
            from={author.institution_name}
          />
        ))}
      </Box>
    </ArticleWrapper>
  );
};

export default Work;

function useWorkState(id: WorkId): WorkType | null {
  const [work, setWork] = useState<WorkType | null>(null);

  useEffect(() => {
    async function init() {
      const { data } = await getWorkById(id);

      setWork(data);
    }

    init();
  }, [id]);

  return work;
}
