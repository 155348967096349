import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  PureComponent,
  ReactNode,
  WheelEventHandler,
  createRef,
} from "react";

import cn from "classnames";
import { clamp, isEqual, round, throttle } from "lodash";

import Canvas from "./Canvas/Canvas";
import { TViewport, workspaceViewportService } from "./service";
import WorkspaceContextProvider from "./Workspace.context";

import { AUTO_SCROLL, WHEEL_MAX_STEP } from "./consts";

import styles from "./Workspace.module.scss";
import Button from "../../ui-kit/Button";

const BASIC_DISTANCE = 64;
const MAX_ZOOM = 100;

export interface IWorkspaceProps {
  contentId?: string;
  disabled?: boolean;
  unitId?: string;
  unitType?: string | null;
  children?: ReactNode;
  title?: string;
  focus?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export interface IWorkspaceState {
  width: number;
  height: number;
  disable: boolean;
}

export default class Workspace extends PureComponent<
  IWorkspaceProps,
  IWorkspaceState
> {
  private readonly workspaceRef = createRef<HTMLDivElement>();
  private raf: ReturnType<typeof window.requestAnimationFrame> | undefined =
    undefined;

  // For dragging
  private startX = 0;
  private startY = 0;
  private viewBoxOffsetX = 0;
  private viewBoxOffsetY = 0;

  private direction = {
    left: false,
    up: false,
    right: false,
    down: false,
  };

  public state: IWorkspaceState = {
    width: 0,
    height: 0,
    disable: false,
  };

  componentDidMount() {
    if (this.workspaceRef.current) {
      this.resizeObserver.observe(this.workspaceRef.current);
    }

    this.centering();
  }

  componentDidUpdate(prevProps: IWorkspaceProps, prevState: IWorkspaceState) {
    const isFocusChanged =
      JSON.stringify(prevProps.focus) !== JSON.stringify(this.props.focus);
    const isFirstMeaningfulPaint =
      prevState.width === 0 &&
      prevState.height === 0 &&
      prevState.width !== this.state.width &&
      prevState.height !== this.state.height;

    if (isFirstMeaningfulPaint) {
      this.centering();
    }

    if (isFocusChanged) {
      this.focus();
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
    this.cancelRaf();
  }

  focus() {
    const viewport = workspaceViewportService.getViewport();
    const { width: canvasWidth, height: canvasHeight } =
      workspaceViewportService.getCanvasElement().getBoundingClientRect();
    const { width: contentWidth, height: contentHeight } =
      workspaceViewportService.getGraphElement().getBoundingClientRect();
    const minCanvasSide = Math.min(canvasWidth, canvasHeight);
    const maxFocusSide = Math.max(
      this.props.focus?.width!,
      this.props.focus?.height!,
    );
    const newScale = minCanvasSide / maxFocusSide;

    const { width: focusWidth, height: focusHeight } = this.props.focus!;
    const x = canvasWidth - canvasWidth / 2 - focusWidth / 2;
    const y = canvasHeight - canvasHeight / 2 - focusHeight / 2;

    // this.move(this.viewBoxOffsetX + x - width / 2, this.viewBoxOffsetY + y - height / 2);
    // this.zoom((viewport.x - x) * newScale, (viewport.y - y) * newScale, newScale);
    this.zoom(x / newScale, -y / newScale, newScale);
  }

  render() {
    const { props, state } = this;

    return (
      <WorkspaceContextProvider
        disabled={this.props.disabled}
        logicalUnitId={props.unitId}
      >
        <div
          ref={this.workspaceRef}
          data-draggable={true}
          tabIndex={-1}
          className={cn(
            styles.container,
            "stacking-context",
            state.disable && styles.grabbing,
          )}
          onWheel={this.handleMouseWheel}
          onMouseDown={this.handleMouseDown}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
        >
          {state.disable && <div className={styles.disable} />}
          <h1 className={styles.title}>
            {this.props.title || "Zero level cluster"}
          </h1>
          <Canvas width={state.width} height={state.height}>
            {props.children}
          </Canvas>
          <div className={styles.tools}>
            <Button
              name="zoom-in"
              color="secondary"
              disabled={state.disable}
              onClick={() => this.handleRange(true)}
            >
              +
            </Button>
            <Button
              name="zoom-out"
              color="secondary"
              disabled={state.disable}
              onClick={() => this.handleRange(false)}
            >
              -
            </Button>
          </div>
        </div>
      </WorkspaceContextProvider>
    );
  }

  private handleResize: ResizeObserverCallback = (entries) => {
    entries.forEach((entry) => {
      const { width, height } = (entry && entry.contentRect) || {};

      if (this.state.width !== width || this.state.height !== height) {
        this.setState(
          {
            width,
            height,
          },
          () => {
            const viewport = workspaceViewportService.getViewport();
            const limitedViewport = this.getLimitedViewport(viewport);

            if (!isEqual(viewport, limitedViewport)) {
              workspaceViewportService.move(
                limitedViewport.x,
                limitedViewport.y,
              );
            }
          },
        );
      }
    });
  };
  private readonly resizeObserver = new window.ResizeObserver(
    this.handleResize,
  );

  private handleRange = (isAdd: boolean) => {
    const viewport = workspaceViewportService.getViewport();
    const newScale = round(
      viewport.scale + (isAdd ? WHEEL_MAX_STEP : -WHEEL_MAX_STEP),
      1,
    );

    if (newScale >= 0.1 && newScale <= MAX_ZOOM) {
      const multiplierScale = 1 - newScale / viewport.scale;
      const deltaX = (this.state.width / 2 - viewport.x) * multiplierScale;
      const deltaY = (this.state.height / 2 - viewport.y) * multiplierScale;
      const newXPosition = viewport.x + deltaX;
      const newYPosition = viewport.y + deltaY;

      this.zoom(newXPosition, newYPosition, newScale);
    }
  };

  private handleMouseWheelThrottled = throttle<
    WheelEventHandler<HTMLDivElement>
  >((e) => {
    const viewport = workspaceViewportService.getViewport();

    if (e.ctrlKey) {
      const bound = (
        this.workspaceRef.current as HTMLDivElement
      ).getBoundingClientRect();

      const deltaScale = clamp(
        -e.deltaY / 100,
        -WHEEL_MAX_STEP,
        WHEEL_MAX_STEP,
      );
      const normalizedScale = parseFloat(
        (viewport.scale + deltaScale).toFixed(2),
      );
      const newScale = clamp(normalizedScale, 0.1, MAX_ZOOM);
      const multiplierScale = 1 - newScale / viewport.scale;
      const deltaX = (e.clientX - viewport.x - bound.x) * multiplierScale;
      const deltaY = (e.clientY - viewport.y - bound.y) * multiplierScale;
      const newXPosition = viewport.x + deltaX;
      const newYPosition = viewport.y + deltaY;

      this.zoom(newXPosition, newYPosition, newScale);
    } else if (e.shiftKey) {
      const deltaX = e.deltaX * 2 || e.deltaY * 2;

      this.move(viewport.x - deltaX, viewport.y);
    } else {
      this.move(viewport.x - e.deltaX * 2, viewport.y - e.deltaY * 2);
    }
  }, 16);

  private handleMouseWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    e.persist();

    this.handleMouseWheelThrottled(e);
  };

  private handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    const targetElement = e.shiftKey ? e.currentTarget : e.target;

    // @ts-expect-error
    if (targetElement.dataset) {
      const element = targetElement as unknown as HTMLOrSVGElement;

      if (element.dataset.draggable) {
        const viewport = workspaceViewportService.getViewport();

        this.setState({ disable: true });

        window.addEventListener("mousemove", this.handleMouseMove, false);
        window.addEventListener("mouseup", this.handleMouseUp, false);

        this.startX = e.clientX;
        this.startY = e.clientY;
        this.viewBoxOffsetX = viewport.x;
        this.viewBoxOffsetY = viewport.y;
      }
    }
  };

  private handleMouseUp = () => {
    window.removeEventListener("mousemove", this.handleMouseMove, false);
    window.removeEventListener("mouseup", this.handleMouseUp, false);

    this.startX = 0;
    this.startY = 0;
    this.setState({ disable: false });
  };

  private handleMouseMove = (e: MouseEvent) => {
    const x = this.viewBoxOffsetX + (e.clientX - this.startX);
    const y = this.viewBoxOffsetY + (e.clientY - this.startY);

    this.move(x, y);
  };

  private handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { x, y } = workspaceViewportService.getViewport();
    const temp = 100;

    this.assignCodes(e.nativeEvent.code, true);

    switch (true) {
      case this.direction.down && this.direction.right:
        this.move(x + temp, y + temp);
        break;
      case this.direction.down && this.direction.left:
        this.move(x - temp, y + temp);
        break;
      case this.direction.up && this.direction.right:
        this.move(x + temp, y - temp);
        break;
      case this.direction.up && this.direction.left:
        this.move(x - temp, y - temp);
        break;
      case this.direction.down:
        this.move(x, y + temp);
        break;
      case this.direction.up:
        this.move(x, y - temp);
        break;
      case this.direction.right:
        this.move(x + temp, y);
        break;
      case this.direction.left:
        this.move(x - temp, y);
        break;
      default:
        break;
    }
  };

  private handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (e) =>
    this.assignCodes(e.nativeEvent.code, false);

  private autoScroll = (type: string) => {
    const { x, y } = workspaceViewportService.getViewport();
    const temp = 5;

    AUTO_SCROLL[type](this.move, x, y, temp);

    this.cancelRaf();
    this.raf = window.requestAnimationFrame(() => this.autoScroll(type));
  };

  private cancelRaf = () => {
    if (this.raf) {
      window.cancelAnimationFrame(this.raf);
      this.raf = undefined;
    }
  };

  private centering = () => {
    const { width: canvasWidth, height: canvasHeight } =
      workspaceViewportService.getCanvasElement().getBoundingClientRect();
    const { width: contentWidth, height: contentHeight } =
      workspaceViewportService.getGraphElement().getBoundingClientRect();
    const { y: contentTop } = workspaceViewportService
      .getGraphElement()
      .getBBox();
    const x = canvasWidth - canvasWidth / 2 - contentWidth / 2;
    const y = canvasHeight - canvasHeight / 2 - contentHeight / 2 - contentTop;
    this.zoom(x, y, 1);
  };

  private assignCodes = (code: string, value: boolean) => {
    switch (code) {
      case "ArrowLeft":
      case "KeyA":
        this.direction.left = value;
        break;
      case "ArrowUp":
      case "KeyW":
        this.direction.up = value;
        break;
      case "ArrowRight":
      case "KeyD":
        this.direction.right = value;
        break;
      case "ArrowDown":
      case "KeyS":
        this.direction.down = value;
        break;
      default:
    }
  };

  private move: (typeof workspaceViewportService)["move"] = (x, y) => {
    const { scale } = workspaceViewportService.getViewport();
    const limitedViewport = this.getLimitedViewport({ x, y, scale });

    workspaceViewportService.move(limitedViewport.x, limitedViewport.y);
  };

  private zoom: (typeof workspaceViewportService)["zoom"] = (x, y, scale) => {
    const limitedViewport = this.getLimitedViewport({ x, y, scale });

    workspaceViewportService.zoom(
      limitedViewport.x,
      limitedViewport.y,
      limitedViewport.scale,
    );
    // workspaceViewportService.zoom(x, y, scale);
  };

  private getLimitedViewport = ({ x, y, scale }: TViewport) => {
    const { width, height } = this.state;
    const bBox = workspaceViewportService.getGraphElement().getBBox();
    const paddingX = BASIC_DISTANCE + 48; // Icon + line sizes

    const minX = -(bBox.width + bBox.x) * scale + paddingX;
    const maxX = width - bBox.x * scale - paddingX;
    const minY = -(bBox.height + bBox.y) * scale + paddingX;
    const maxY = height - bBox.y * scale - paddingX;

    return {
      x: Math.min(Math.max(minX, x), maxX),
      y: Math.min(Math.max(minY, y), maxY),
      scale,
    };
  };
}
