import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Email: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <g className={styles.shape}>
        <path d="M21 12C21 19 20.5 20 12 20C3.5 20 3 19 3 12C3 10.3071 3.02924 8.96516 3.19381 7.9034C3.35326 6.8747 3.7132 6.99915 4.02034 7.38575C4.32748 7.77235 4.68061 8.1842 5.07706 8.59651C6.67608 10.2595 9.04861 12 12 12C14.9514 12 17.3239 10.2595 18.9229 8.59651C19.3193 8.1842 19.6725 7.77235 19.9796 7.38575C20.2868 6.99915 20.6467 6.87465 20.8062 7.9034C20.9708 8.96516 21 10.3071 21 12ZM8.85353 13.8535C9.0488 13.6582 9.0488 13.3417 8.85353 13.1464C8.65827 12.9511 8.34169 12.9511 8.14643 13.1464L5.14643 16.1464C4.95116 16.3417 4.95116 16.6582 5.14643 16.8535C5.34169 17.0488 5.65827 17.0488 5.85353 16.8535L8.85353 13.8535ZM15.8535 13.1464C15.6583 12.9511 15.3417 12.9511 15.1464 13.1464C14.9512 13.3417 14.9512 13.6582 15.1464 13.8535L18.1464 16.8535C18.3417 17.0488 18.6583 17.0488 18.8535 16.8535C19.0488 16.6582 19.0488 16.3417 18.8535 16.1464L15.8535 13.1464Z" />
        <path d="M19.402 6.5C19.0676 6.93812 18.6658 7.42111 18.2021 7.9034C16.6761 9.49043 14.5486 11 12 11C9.45135 11 7.32388 9.49043 5.7979 7.9034C5.33416 7.42111 4.93232 6.93812 4.59799 6.5C3.99424 5.70885 4.02034 5.48962 4.79815 5C6.08091 4.19253 8.5 4 12 4C15.5 4 17.9191 4.19253 19.2018 5C19.9796 5.48959 20.0057 5.70885 19.402 6.5Z" />
      </g>
    </Icon>
  );
};

export default Email;
