import React, { useEffect, useState } from "react";
import { fetchTasks } from "../services/api";
import useStore from "../services/store/store";
import BasicDialog from "./Basic";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const FieldsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    cursor: pointer;
  }
`;

const HistoryOvelay = (props) => {
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  const [historyList, setHistoryList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const initOverlay = async () => {
      try {
        const res = await fetchTasks({ params: { page: 1, size: 15 } });
        console.log(res);
        setHistoryList(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    initOverlay();
  }, []);

  const onSelect = (item) => {
    history.push(`/task/${item.id}`);
    setOpenOverlay({ HISTORY: "" });
  };

  return (
    <BasicDialog {...props}>
      <FieldsList>
        {historyList.map((i) => (
          <li
            key={i.id}
            onClick={() => onSelect(i)}
            style={{ opacity: i.status === "Finished" ? 1 : 0.1 }}
          >
            <Typography sx={{ mt: 1 }} variant="h4">
              {i.search_query}
            </Typography>
          </li>
        ))}
      </FieldsList>
    </BasicDialog>
  );
};

export default HistoryOvelay;
