import { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { actions } from "./state";
import type { FiltrationScope } from "./types";
import { useLocation } from "react-router-dom";
import { fetchFieldsOfStudies } from "./effects";

export function useSetFiltrationScope(scope: FiltrationScope) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.set(scope));
  }, [dispatch, scope]);
}

export function useFosFilterSetup() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  useEffect(() => {
    const fos = new URLSearchParams(search).get("fos")?.split(",") || [];

    init();

    async function init() {
      await dispatch(fetchFieldsOfStudies()).unwrap();

      dispatch(actions.setFieldsOfStudies(fos));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
