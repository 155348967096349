import { FC, ReactNode, useCallback } from "react";
import cn from "classnames";

import Button from "~/ui-kit/Button";

import { EventObject } from "../EventObject";

import styles from "./Toggler.module.scss";

type OptionId = string;
type OptionValue = ReactNode;
type TogglerOptions = Record<OptionId, OptionValue>;

export interface TogglerProps {
  value: keyof TogglerOptions;
  options: TogglerOptions;
  onChange: (event: EventObject<string>) => void;
  disabled?: boolean;
}

const Toggler: FC<TogglerProps> = ({
  value,
  onChange,
  options = {},
  disabled,
}) => {
  const handleToggle = useCallback(
    (event: EventObject<boolean>) => {
      onChange(new EventObject(event.meta.name!));
    },
    [onChange]
  );

  return (
    <div className={cn(styles.toggler)}>
      {Object.entries(options).map(([optionId, optionValue]) => (
        <Button
          key={optionId}
          className={cn(styles.option, optionId === value && styles.active)}
          name={optionId}
          onClick={handleToggle}
          color={optionId === value ? "primary" : "secondary"}
          disabled={disabled}
        >
          {optionValue}
        </Button>
      ))}
    </div>
  );
};

export default Toggler;
