import { FC } from "react";
import type { Folder } from "../../types";
import styles from "./styles.module.scss";
import FolderIcon from "~/icons/Folder";
import { NavLink } from "react-router-dom";
import { useAppState } from "~/state";

type FoldersListItemProps = Folder;

const FoldersListItem: FC<FoldersListItemProps> = (folder) => {
  const { name, count, id } = folder;
  const { Folder } = useAppState();

  return (
    <li className={styles["folder-list__item"]}>
      <header>
        <FolderIcon />
      </header>
      <NavLink
        to={`/monitoring/folder/${id}`}
        onClick={() => (Folder.folder = folder)}
      >
        <p>{name}</p>
        <span>{count} clusters</span>
      </NavLink>
    </li>
  );
};

export default FoldersListItem;
