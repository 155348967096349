import type { FC, HTMLProps } from "react";
import cn from "classnames";
import type { Author, AuthorId } from "../../domain/Author/Author.types";
import type { InstitutionId } from "../../domain/Institution/Institution.types";
import React from "react";
import Paper from "../../ui-kit/Paper";
import styles from "./TopAuthors.module.scss";
import Button from "../../ui-kit/Button";
// @ts-expect-error
import useStore from "../../services/store/store";
import { ClusterId } from "../../domain/Cluster/Cluster.types";
import AuthorItem from "../AuthorItem";

interface TopAuthorsProps {
  persons: Array<Author>;
  id: AuthorId | ClusterId | InstitutionId;
}

const TopAuthors: FC<TopAuthorsProps & HTMLProps<HTMLElement>> = ({
  persons,
  id,
  className,
}) => {
  const setOpenOverlay = useStore((state: any) => state.setOpenOverlay);

  return (
    <Paper className={styles.authors}>
      <header className={styles.header}>
        <h3 className={styles.title}>Top authors</h3>
      </header>
      <section className={cn(className, styles.content)}>
        {persons.map((i: Author) => (
          <AuthorItem
            key={i.id}
            id={i.id}
            citeCount={i.cited_by_count}
            name={i.display_name}
            hIndex={i.hindex}
            worksCount={i.works_count}
            linkedinUrl={i.linkedin_url}
            emails={i.emails}
            linkedinUpdateDate={i.linkedin_url_updated_date}
            institution={i.institution_name}
          />
        ))}
      </section>
      <Button
        onClick={() => setOpenOverlay({ Authors: { id } })}
        color="primary"
        accent="light"
      >
        Все
      </Button>
    </Paper>
  );
};

export default TopAuthors;
