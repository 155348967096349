import { FC, useEffect } from "react";
import monitoringViewStyles from "../Monitoring.module.scss";
import styles from "./FoldersLayout.module.scss";
import { useAppState } from "~/state";
import { observer } from "mobx-react-lite";
import EmptyPlaceholder from "./EmptyPlaceholder";
import Button from "~/ui-kit/Button";
import Add from "~/icons/Add";
import { FolderCreateDialog, FolderList } from "~/widgets/Folder";
import useModal from "~/ui-kit/useModal";

const FoldersLayout: FC = () => {
  const { Folders } = useAppState();

  useEffect(() => {
    Folders.fetch();
  }, [Folders]);

  const openCreateFolderDialog = useModal(
    (close) => (
      <FolderCreateDialog
        onCreate={async () => {
          await Folders.fetch();
          close();
        }}
        onCancel={close}
      />
    ),
    { maxWidth: "xs" },
    Folders.isCreating,
  );

  const createButton = (
    <Button
      color="primary"
      accent="light"
      className={styles["add-button"]}
      onClick={openCreateFolderDialog}
    >
      <Add width={24} height={24} />
      Add set
    </Button>
  );

  return (
    <main className={monitoringViewStyles.view}>
      <aside className={styles.folders}>
        <header>
          <h2>Monitoring </h2>
          {Folders.isEmpty === false && createButton}
        </header>
        {Folders.isEmpty === true && (
          <section className={styles.dialog}>
            <EmptyPlaceholder />
            {createButton}
          </section>
        )}
        <FolderList />
      </aside>
    </main>
  );
};

export default observer(FoldersLayout);
