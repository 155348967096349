import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const DocumentIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <path
        className={styles.shape}
        d="M2.66699 8.00418C2.66699 4.8836 2.66699 3.3849 3.41576 2.66513C4.10765 2.00003 5.44013 2.00002 8.00148 2C8.33519 2 9.00071 2 9.33366 2.06661C10.0098 2.20187 10.3337 2.33333 11.667 3.66667C13.0003 5 13.1355 5.34277 13.267 6C13.3337 6.33333 13.3355 7.83425 13.3318 8.16758C13.2986 11.1817 13.2825 12.6382 12.5466 13.3419C11.8586 14 10.5493 14 8.00033 14C5.4573 14 4.10342 14 3.41458 13.3388C2.66699 12.6211 2.66699 11.1247 2.66699 8.00418ZM5.33366 6.66667C5.33366 7.03486 5.63214 7.33333 6.00033 7.33333H10.0003C10.3685 7.33333 10.667 7.03486 10.667 6.66667C10.667 6.29848 10.3685 6 10.0003 6H6.00033C5.63214 6 5.33366 6.29848 5.33366 6.66667ZM6.00033 8.66667C5.63214 8.66667 5.33366 8.96514 5.33366 9.33333C5.33366 9.70152 5.63214 10 6.00033 10H8.66699C9.03518 10 9.33366 9.70152 9.33366 9.33333C9.33366 8.96514 9.03518 8.66667 8.66699 8.66667H6.00033Z"
      />
    </Icon>
  );
};

export default DocumentIcon;
