import { FC, useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Paper from "~/ui-kit/Paper";
import styles from "./Favourites.module.scss";
import FavouriteItem from "./FavouriteItem";
import { EventObject } from "~/ui-kit/EventObject";
import axios from "axios";
import { BASE_URL } from "~/store/api/const";
import {
  Favourite,
  FavouriteDTO,
  FavouriteId,
  FavouriteType,
  map,
} from "./types";
import {
  PaginatedResponse,
  VanillaPaginatedRequestParams,
} from "~/store/api/response.types";
import { Alert, CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router-dom";

interface FavouritesProps {
  onClick?: (e: string) => void;
  onDelete?: (e: EventObject<FavouriteId>) => void;
}

const Favourites: FC<FavouritesProps> = ({ onClick, onDelete }) => {
  const {
    favourites,
    isLoading,
    error,
    hasMoreFavourites,
    handleUpload,
    type,
    handleTypeChange,
    handleClick,
    isDeleting,
    handleDelete,
  } = useFavouritesAPI({ onClick, onDelete });

  let content = null;

  if (isLoading) {
    content = (
      <div className={styles.placeholder}>
        <CircularProgress />
      </div>
    );
  } else if (error) {
    content = <Alert severity="error">{error}</Alert>;
  } else if (isLoading === false && !favourites.length) {
    content = <Alert severity="info">There is no favourites filters yet</Alert>;
  } else {
    content = (
      <Scrollbars>
        <InfiniteScroll
          element="ul"
          pageStart={1}
          className={styles.content}
          loadMore={handleUpload}
          initialLoad={false}
          hasMore={hasMoreFavourites}
          useWindow={false}
          loader={
            <div key="loader" className={styles.placeholder}>
              <CircularProgress />
            </div>
          }
        >
          {favourites.map((favourite) => (
            <FavouriteItem
              className={cn(isDeleting[favourite.id] && styles.isProcessing)}
              id={favourite.id}
              key={favourite.id}
              type={favourite.type}
              name={favourite.name}
              filterString={favourite.filtersLabel}
              onClick={handleClick}
              onDelete={handleDelete}
            />
          ))}
        </InfiniteScroll>
      </Scrollbars>
    );
  }

  return (
    <Paper className={styles.panel}>
      <header className={styles.header}>
        <h1 className={styles.title}>Favourites</h1>
        <ul className={styles.typeSwitchers}>
          <li
            className={cn(styles.typeSwitcher, type === "all" && styles.active)}
            onClick={() => handleTypeChange(new EventObject("all"))}
          >
            All
          </li>
          <li
            className={cn(
              styles.typeSwitcher,
              type === "cluster" && styles.active,
            )}
            onClick={() => handleTypeChange(new EventObject("cluster"))}
          >
            Clusters
          </li>
          <li
            className={cn(
              styles.typeSwitcher,
              type === "author" && styles.active,
            )}
            onClick={() => handleTypeChange(new EventObject("author"))}
          >
            Authors
          </li>
          <li
            className={cn(
              styles.typeSwitcher,
              type === "institution" && styles.active,
            )}
            onClick={() => handleTypeChange(new EventObject("institution"))}
          >
            Institutions
          </li>
        </ul>
      </header>
      {content}
    </Paper>
  );
};

export default Favourites;

function useFavouritesAPI({
  onClick,
  onDelete,
}: {
  onClick?: (query: string) => void;
  onDelete?: (e: EventObject<FavouriteId>) => void;
}) {
  const [favourites, setFavourites] = useState<Array<Favourite>>([]);
  const [total, setTotal] = useState<number>(0);
  const [type, setType] = useState<
    "all" | "author" | "cluster" | "institution"
  >("all");
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState<Record<FavouriteId, boolean>>(
    {},
  );

  useEffect(() => {
    async function initialRequest() {
      try {
        setIsLoading(true);
        const { items, total } = await getUserFavourites({
          page: "1",
          size: "20",
          ...(type !== "all" ? { object_type: type } : {}),
        });

        setIsLoading(false);
        setFavourites(items);
        setTotal(total);
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Unknown error");
        }
      }
    }

    initialRequest();
  }, [type]);

  return {
    favourites,
    handleUpload: useCallback(
      async (page: number) => {
        try {
          const { items } = await getUserFavourites({
            page: `${page}`,
            size: "10",
            ...(type !== "all" ? { object_type: type } : {}),
          });

          setFavourites(favourites.concat(items));
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError("Unknown error");
          }
        }
      },
      [favourites, type],
    ),
    type,
    handleTypeChange: useCallback(
      (e: EventObject<"all" | "cluster" | "author" | "institution">) => {
        setType(e.value);
      },
      [],
    ),
    hasMoreFavourites: favourites.length < total,
    isLoading,
    error,
    handleClick: useCallback(
      (e: EventObject<FavouriteId>) => {
        const favourite = favourites.find((f) => f.id === e.value);

        if (!favourite) return null;

        onClick?.(favourite.searchParamsQueryString);

        history.push({
          pathname: `/${favourite.scope ? (favourite.scope === "task" ? `search/task/${favourite.scopeId}/` : `monitoring/folder/${favourite.scopeId}`) : ""}${favourite.type === "cluster" ? "cluster" : favourite.type}/${favourite.contextId}`,
          search: favourite.searchParamsQueryString,
        });
      },
      [favourites, history, onClick],
    ),
    isDeleting,
    handleDelete: useCallback(
      async (e: EventObject<FavouriteId>) => {
        try {
          setIsDeleting({ ...isDeleting, [e.value]: true });

          await deleteUserFavourite(e.value);

          onDelete?.(e);

          const newFavourites = [...favourites];
          const removedIndex = favourites.findIndex((f) => f.id === e.value);
          newFavourites.splice(removedIndex, 1);
          setTotal(total - 1);
          setFavourites(newFavourites);
        } catch (err) {
        } finally {
          setIsDeleting({ ...isDeleting, [e.value]: false });
        }
      },
      [favourites, isDeleting, onDelete, total],
    ),
  };
}

type GetFavouriteRequestParams = VanillaPaginatedRequestParams & {
  object_type?: FavouriteType;
};

const getUserFavourites = async (
  params: GetFavouriteRequestParams,
): Promise<PaginatedResponse<Favourite>> => {
  const { data } = await axios.get<PaginatedResponse<FavouriteDTO>>(
    `${BASE_URL}/favorite`,
    {
      params,
    },
  );

  return {
    ...data,
    items: data.items.map(map),
  };
};

const deleteUserFavourite = async (id: FavouriteId) => {
  return axios.delete(`${BASE_URL}/favorite/${id}`);
};
