import type { FC, HTMLProps, PropsWithChildren } from "react";

export interface IconProps extends PropsWithChildren<HTMLProps<SVGSVGElement>> {
  sizeCoef?: "1x" | "2x" | "3x" | "4x";
  viewBoxCoef?: "1x" | "2x" | "3x" | "4x";
  type?: "fill" | "stroke";
}

const UNIT = 8;
const SIZE = {
  "1x": 1 * UNIT,
  "2x": 2 * UNIT,
  "3x": 3 * UNIT,
  "4x": 4 * UNIT,
};

const Icon: FC<IconProps> = ({
  children,
  sizeCoef = "3x",
  viewBoxCoef = "3x",
  ...props
}) => {
  const s = SIZE[sizeCoef];
  const v = SIZE[viewBoxCoef];

  return (
    <svg
      width={s}
      height={s}
      viewBox={`0 0 ${v} ${v}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </svg>
  );
};

export default Icon;
