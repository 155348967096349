import { createSlice, type Draft, type PayloadAction } from "@reduxjs/toolkit";

import type {
  FieldOfStudy,
  FieldOfStudyId,
} from "~/domain/FieldOfStudy/FieldOfStudy.types";
import type { FiltrationScope } from "./types";

import { AuthorsState } from "../Authors";
import { WorksState } from "../Works";

import { RootState } from "../store";

import { fetchFieldsOfStudies } from "./effects";
import { SourceId } from "~/domain/Sources/Sources.types";

type CompositeFilterState = "checked" | "unchecked" | "indeterminate";

interface ActiveFilterStore {
  scope: FiltrationScope | null;
  totalWorks: number | null;
  totalAuthors: number | null;
  fieldsOfStudies: {
    values: Record<FieldOfStudyId, FieldOfStudy> | null;
    object: Record<FieldOfStudyId, CompositeFilterState>;
  };
  sources: {
    values: Array<{ id: string; name: string }> | null;
    object: Record<SourceId, true>;
  };
  period: {
    values: Array<{
      year: number;
      worksRelativeBasicYear: number;
    }> | null;
    object: {
      from?: number;
      to?: number;
    };
  };
}

const INITIAL_STATE: ActiveFilterStore = {
  scope: null,
  totalWorks: null,
  totalAuthors: null,
  fieldsOfStudies: {
    values: null,
    object: {},
  },
  sources: {
    values: null,
    object: {},
  },
  period: {
    values: null,
    object: {},
  },
};

export const activeFilterFactory = (initialState: ActiveFilterStore) => {
  return createSlice({
    name: "activeFilter",
    initialState,
    reducers: {
      set: (state, { payload }: PayloadAction<FiltrationScope>) => {
        state.scope = payload;
      },
      setFieldsOfStudies: (
        state,
        { payload }: PayloadAction<Array<FieldOfStudyId>>,
      ) => {
        Object.keys(state.fieldsOfStudies.object).forEach((fieldOfStudyId) => {
          delete state.fieldsOfStudies.object[fieldOfStudyId];
        });

        payload.forEach((fieldOfStudyId) => {
          state.fieldsOfStudies.object[fieldOfStudyId] = "checked";
        });
      },
      changeFieldsOfStudies: (
        state,
        {
          payload,
        }: PayloadAction<{
          parentId: FieldOfStudyId;
          childId: FieldOfStudyId;
          isSelected: boolean;
        }>,
      ) => {
        if (payload.isSelected) {
          state.fieldsOfStudies.object[payload.childId] = "checked";
        } else {
          delete state.fieldsOfStudies.object[payload.childId];
        }

        const subItems = state.fieldsOfStudies.values
          ? state.fieldsOfStudies.values[payload.parentId].children
          : [];

        if (state.fieldsOfStudies.object[payload.parentId] === "checked") {
          delete state.fieldsOfStudies.object[payload.parentId];

          subItems.forEach((item) => {
            if (item.id !== payload.childId) {
              state.fieldsOfStudies.object[item.id] = "checked";
            }
          });
        } else {
          if (
            subItems.every(
              (item) => state.fieldsOfStudies.object[item.id] === "checked",
            )
          ) {
            state.fieldsOfStudies.object[payload.parentId] = "checked";

            subItems.forEach(
              (item) => delete state.fieldsOfStudies.object[item.id],
            );
          } else if (
            subItems.some(
              (item) => state.fieldsOfStudies.object[item.id] === "checked",
            )
          ) {
            delete state.fieldsOfStudies.object[payload.parentId];
          }
        }
      },
      changeCompositeFieldsOfStudies: (
        state,
        { payload }: PayloadAction<{ id: FieldOfStudyId; isSelected: boolean }>,
      ) => {
        if (payload.isSelected) {
          state.fieldsOfStudies.object[payload.id] = "checked";
        } else {
          delete state.fieldsOfStudies.object[payload.id];
        }

        const subItems = state.fieldsOfStudies.values
          ? state.fieldsOfStudies.values[payload.id].children
          : [];

        subItems.forEach(
          (item) => delete state.fieldsOfStudies.object[item.id],
        );
      },
      changeSources: (
        state,
        { payload }: PayloadAction<{ id: SourceId; isSelected: boolean }>,
      ) => {
        if (payload.isSelected) {
          state.sources.object[payload.id] = true;
        } else {
          delete state.sources.object[payload.id];
        }
      },
      changePeriod: (
        state,
        { payload }: PayloadAction<{ from?: number; to?: number }>,
      ) => {
        if (payload.from) state.period.object.from = payload.from;
        if (payload.to) state.period.object.to = payload.to;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        WorksState.fetchWorksByAuthorId.fulfilled,
        (state, { payload }) => totalWorksReducerMutation(state, payload.total),
      );
      builder.addCase(
        WorksState.fetchWorksByInstitutionId.fulfilled,
        (state, { payload }) => totalWorksReducerMutation(state, payload.total),
      );
      builder.addCase(
        AuthorsState.fetchAuthorsByAuthorId.fulfilled,
        (state, { payload }) => {
          state.totalAuthors = payload.total;
        },
      );
      builder.addCase(fetchFieldsOfStudies.fulfilled, (state, { payload }) => {
        state.fieldsOfStudies.values = payload;
      });
    },
  });
};

const ActiveFilter = activeFilterFactory(INITIAL_STATE);

export default ActiveFilter.reducer;

export const actions = ActiveFilter.actions;

function totalWorksReducerMutation(
  state: Draft<RootState["ActiveFilter"]>,
  amount: number,
) {
  state.totalWorks = amount;
}
