import { createAsyncThunk } from "@reduxjs/toolkit";

import type {
  InstitutionId,
  InstitutionInfo,
} from "~/domain/Institution/Institution.types";

import { AppDispatch, RootState } from "~/store";

import { getInstitutionById } from "~/store/api";

export const fetchById = createAsyncThunk<
  InstitutionInfo,
  InstitutionId,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>("institution/fetchById", async (id: InstitutionId, { rejectWithValue }) => {
  try {
    const { data } = await getInstitutionById(id);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      return rejectWithValue(err.message);
    }

    throw err;
  }
});
