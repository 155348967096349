import { FC, useCallback } from "react";
import cn from "classnames";
import styles from "./Period.module.scss";
import { Slider } from "@mui/material";
import InputField from "~/ui-kit/InputField";
import Button from "~/ui-kit/Button";
import { useAppState } from "~/state";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";

const PeriodFilter: FC = () => {
  const { filters } = useAppState();
  const possibleValues = usePeriodFilterPossibleValues();
  const { from, to } = usePeriodFilterValue();
  const handleApply = useApplyFilterHandler();
  const isSelected = useCallback(
    (year: number) => {
      return year >= from && year <= to;
    },
    [from, to],
  );
  const getClosestBoundary = useCallback(
    (year: number) => {
      if (Math.abs(from - year) < Math.abs(to - year)) {
        return "from";
      } else {
        return "to";
      }
    },
    [from, to],
  );

  return (
    <section className={styles.filter}>
      <header>
        <h1 className={styles.title}>Period Filter</h1>
      </header>
      <div>
        <ul className={styles.worksByYears}>
          {possibleValues.map((worksByYear) => (
            <Tippy
              content={
                <>
                  <p className={styles.year}>{worksByYear.year}</p>
                  <p className={styles.count}>{worksByYear.count}</p>
                </>
              }
              placement="bottom"
              arrow={false}
              key={worksByYear.year}
            >
              <li
                className={cn(
                  styles.worksByYear,
                  isSelected(worksByYear.year) && styles.selected,
                )}
                style={{
                  height: `${worksByYear.amount}%`,
                  width: `${100 / possibleValues.length}%`,
                }}
                onClick={() => {
                  const closestBoundary = getClosestBoundary(worksByYear.year);

                  if (closestBoundary === "from") {
                    filters.periodFilter.value = { from: worksByYear.year };
                  } else {
                    filters.periodFilter.value = { to: worksByYear.year };
                  }
                }}
              />
            </Tippy>
          ))}
        </ul>
        <Slider
          style={{
            marginTop: "-13px",
            marginLeft: `${100 / possibleValues.length / 2}%`,
            marginRight: `${100 / possibleValues.length / 2}%`,
            display: "flex",
            width: "unset",
          }}
          step={1}
          min={filters.periodFilter.minPossibleValue}
          max={filters.periodFilter.maxPossibleValue}
          value={[from, to]}
          onChange={(_, value) => {
            if (!Array.isArray(value)) return;

            filters.periodFilter.value = {
              from: value[0],
              to: value[1],
            };
          }}
        />
      </div>
      <div className={styles.rangeBoundariesInput}>
        <InputField
          value={`${from}`}
          name="from"
          onChange={(e) => (filters.periodFilter.value = { from: +e.value })}
        />
        —
        <InputField
          value={`${to}`}
          name="to"
          onChange={(e) => (filters.periodFilter.value = { to: +e.value })}
        />
      </div>
      <ul className={styles.predefinedRanges}>
        <li
          className={styles.predefinedRange}
          onClick={() => {
            filters.periodFilter.value = {
              from: possibleValues[possibleValues.length - 1].year,
              to: possibleValues[possibleValues.length - 1].year + 1,
            };
          }}
        >
          Last year
        </li>
        <li
          className={styles.predefinedRange}
          onClick={() => {
            filters.periodFilter.value = {
              from: possibleValues[possibleValues.length - 5].year,
              to: possibleValues[possibleValues.length - 1].year + 1,
            };
          }}
        >
          Last 5 years
        </li>
        <li
          className={styles.predefinedRange}
          onClick={() => {
            filters.periodFilter.value = {
              from: possibleValues[possibleValues.length - 10].year,
              to: possibleValues[possibleValues.length - 1].year + 1,
            };
          }}
        >
          Last 10 years
        </li>
      </ul>
      <Button color="primary" onClick={handleApply}>
        Apply period
      </Button>
    </section>
  );
};

export default observer(PeriodFilter);

function useApplyFilterHandler() {
  const { filters } = useAppState();
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(() => {
    history.push({
      pathname,
      search: filters.queryString,
    });
  }, [filters.queryString, history, pathname]);
}

function usePeriodFilterPossibleValues() {
  const { filters } = useAppState();

  return filters.periodFilter.possibleValues;
}

function usePeriodFilterValue() {
  const { filters } = useAppState();

  return {
    from:
      filters.periodFilter.value.from || filters.periodFilter.minPossibleValue,
    to: filters.periodFilter.value.to || filters.periodFilter.maxPossibleValue,
  };
}
