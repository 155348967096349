import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
} from "react";
// @ts-expect-error
import { postCredentials, fetchUser } from "~/services/api";
// @ts-expect-error
import useToken from "~/hooks/useToken";
// @ts-expect-error
import useStore from "~/services/store/store";

export interface Credentials {
  username: string;
  password: string;
}

const authContext = createContext<{
  user: {
    email: string;
    full_name: string | null;
    id: number;
    is_active: boolean;
    is_supervisor: boolean;
  } | null;
  signin: (credentials: Credentials, cb: () => void) => void;
  signout: (cb: () => void) => void;
  isAuthorized: boolean;
  getUserName: () => string;
}>({
  user: null,
  signin: (credentials: Credentials, cb: () => void) => {},
  signout: (cb: () => void) => {},
  isAuthorized: false,
  getUserName: () => "Guest",
});

export const ProvideAuth: FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const { user, setUser } = useStore((state: any) => ({
    setUser: state.setUser,
    user: state.user,
  }));
  const { token, setToken } = useToken();

  const signin = async (credentials: Credentials, cb: () => void) => {
    const bodyFormData = new FormData();
    bodyFormData.append("username", credentials.username);
    bodyFormData.append("password", credentials.password);
    bodyFormData.append("grant_type", "password");

    const res = await postCredentials(bodyFormData);
    setToken(res.data.access_token);
    const user = await fetchUser();
    setUser(user.data);
    cb();
  };

  const signout = (cb?: () => void) => {
    setUser(null);
    setToken(null);
    cb?.();
  };

  return {
    user,
    signin,
    signout,
    isAuthorized: !!token,
    getUserName() {
      if (this.isAuthorized) {
        return this.user?.full_name || this.user?.email || "Anonym";
      } else {
        return "Guest";
      }
    },
  };
}
