import axios, { AxiosResponse } from "axios";
import { action, flow, makeAutoObservable } from "mobx";
import { BASE_URL } from "~/store/api/const";
import { Folder } from "./types";

export class FoldersState {
  private _folders: Array<Folder> | null = null;
  private _fetchingProcess: boolean = false;
  private fetchingError: string | null = null;

  private _creatingProcess: boolean = false;
  creatingError: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get folders(): Array<Folder> | null {
    return this._folders;
  }

  get isEmpty(): boolean | undefined {
    return this._folders ? this._folders.length === 0 : undefined;
  }

  get isLoading() {
    return this._fetchingProcess;
  }

  get isCreating() {
    return this._creatingProcess;
  }

  fetch = flow(function* (this: FoldersState) {
    this._fetchingProcess = true;

    try {
      const { data }: AxiosResponse<Array<Folder>> = yield axios.get<
        Array<Folder>
      >(`${BASE_URL}/folder`);

      this._folders = data;
    } catch (err) {
      if (err instanceof Error) {
        this.fetchingError = err.message;
      } else {
        throw err;
      }
    } finally {
      this._fetchingProcess = false;
    }
  });

  async createFolder({ name, file }: { name: string; file: File }) {
    let status = null;
    this._creatingProcess = true;

    const creationForm = new FormData();
    creationForm.append("name", name);
    creationForm.append("file", file);

    try {
      await axios.post<string>(`${BASE_URL}/folder/upload`, creationForm);
      status = true;
    } catch (err) {
      if (err instanceof Error) {
        this.creatingError = err.message;
        status = false;
      } else {
        throw err;
      }
    } finally {
      this._creatingProcess = false;
    }

    return status;
  }
}

export default new FoldersState();
