import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const LinkedInSquareIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <path
        className={styles.shape}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.34315 2 2 3.34315 2 5V11C2 12.6569 3.34315 14 5 14H11C12.6569 14 14 12.6569 14 11V5C14 3.34315 12.6569 2 11 2H5ZM3.99121 5.06179C3.99121 4.47686 4.46761 4.00098 5.05319 4.00098C5.63831 4.00098 6.11434 4.47686 6.11432 5.06179C6.11432 5.64696 5.63831 6.12305 5.05319 6.12305C4.46761 6.12305 3.99121 5.64699 3.99121 5.06179ZM4.24747 6.57129H5.85683C5.92826 6.57129 5.98613 6.62919 5.98613 6.70059V11.8708C5.98613 11.9422 5.92826 12.0001 5.85683 12.0001H4.24747C4.17604 12.0001 4.11816 11.9422 4.11816 11.8708V6.70059C4.11816 6.62919 4.17604 6.57129 4.24747 6.57129ZM9.95117 6.44336C9.30479 6.44336 8.82697 6.72123 8.53716 7.03696V6.70116C8.53716 6.62976 8.47928 6.57186 8.40785 6.57186H6.86661C6.79518 6.57186 6.7373 6.62976 6.7373 6.70116V11.8714C6.7373 11.9428 6.79518 12.0007 6.86661 12.0007H8.47245C8.54388 12.0007 8.60176 11.9428 8.60176 11.8714V9.31331C8.60176 8.45131 8.83589 8.11549 9.43678 8.11549C10.0912 8.11549 10.1432 8.65385 10.1432 9.35766V11.8714C10.1432 11.9428 10.2011 12.0007 10.2725 12.0007H11.879C11.9504 12.0007 12.0083 11.9428 12.0083 11.8714V9.03544C12.0083 7.75367 11.7639 6.44336 9.95117 6.44336Z"
      />
    </Icon>
  );
};

export default LinkedInSquareIcon;
