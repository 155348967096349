import { HTMLProps, useEffect, type FC } from "react";
import cn from "classnames";
import type { AuthorId } from "../../../domain/Author/Author.types";
import TopAuthors from "../../../components/TopAuthors";
import styles from "./AuthorCoauthors.module.scss";
import { AuthorsState } from "../../../store/Authors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Alert, Box, CircularProgress } from "@mui/material";

interface AuthorCoauthorsProps {
  id: AuthorId;
}

const AuthorCoauthors: FC<AuthorCoauthorsProps & HTMLProps<HTMLElement>> = ({
  id,
  className,
}) => {
  const { persons, isLoading, error } = usePersonCoauthors(id);

  if (!persons.length && isLoading) {
    return (
      <Box className={className} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" className={className}>
        {error}
      </Alert>
    );
  }

  return (
    <section className={cn(className, styles.coauthors)}>
      <TopAuthors
        className={cn(isLoading && styles.loading)}
        id={id}
        persons={persons}
      />
    </section>
  );
};

export default AuthorCoauthors;

function usePersonCoauthors(id: AuthorId) {
  const dispatch = useAppDispatch();
  const topAuthors = useAppSelector(AuthorsState.selectTopCoauthors);
  const isLoading = useAppSelector(AuthorsState.selectLoadingState);
  const error = useAppSelector(AuthorsState.selectFetchManyError);

  useEffect(() => {
    dispatch(
      AuthorsState.fetchAuthorsByAuthorId({ id, page: "1", size: "15" }),
    );
  }, [dispatch, id]);

  return {
    persons: topAuthors,
    isLoading,
    error,
  };
}
