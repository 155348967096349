import { IconButton, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { addToFavorite } from '../services/api';

const MainText = ({ selectedCluster }) => {

  const onFavoriteToggle = async () => {
    try {
      await addToFavorite(selectedCluster.id);
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <IconButton onClick={onFavoriteToggle} color="secondary" sx={{ marginRight: '-16px' }} aria-label="directions">
        {!selectedCluster.is_favorite
          ? <StarBorderIcon /> : <StarIcon style={{ color: '#FFCC00' }} />}
      </IconButton>
      <Typography variant="h1" gutterBottom>
        {selectedCluster.name}
      </Typography>
    </Stack>
  )
};

export default MainText;
