import { FC } from "react";
import EmptyPlaceholderImage from "./folders-empty-placeholder.svg";
import styles from "./EmptyPlaceholder.module.scss";

const EmptyPlaceholder: FC = () => {
  return (
    <figure className={styles["empty-placeholder"]}>
      <img src={EmptyPlaceholderImage} alt="Empty monitoring folders" />
      <figcaption className={styles["empty-placeholder-message-title"]}>
        Bulk search
        <p className={styles["empty-placeholder-message-description"]}>
          Add your own request groups for mass surveillance
        </p>
      </figcaption>
    </figure>
  );
};

export default EmptyPlaceholder;
