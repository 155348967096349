import React, { useEffect } from 'react';
import { fetchFoses } from '../services/api';
import useStore from '../services/store/store';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';


const FieldsList = styled.ul`
  margin: 0;
  padding: 16px;
  list-style: none;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const SortMain = (props) => {

  const [value, setValue] = useStore((state) => [state.mainSort, state.setMainSort]);

  return (
    <BasicDialog {...props}>
      <FieldsList>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <FormControlLabel value="" control={<Radio />} label="Relevance" />
            <FormControlLabel value="date_asc" control={<Radio />} label="Date Ascending" />
            <FormControlLabel value="date_desc" control={<Radio />} label="Date Descending" />
            <FormControlLabel value="cited_desc" control={<Radio />} label="Citation count" />
          </RadioGroup>
        </FormControl>
      </FieldsList>
    </BasicDialog>
  )
};

export default SortMain;
