import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const PortfolioIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M3.1103 4.08845C3.29789 2.99405 3.7291 2.14059 4.43684 1.53395C5.39684 0.711096 6.69032 0.5 8 0.5C9.30968 0.5 10.6032 0.711096 11.5632 1.53395C12.2709 2.14059 12.7021 2.99405 12.8897 4.08845C15.1277 4.46843 15.5 5.34367 15.5 7.16667C15.5 9.44576 14.1927 10.2622 10.5 10.4654C9.75532 10.5064 9.74918 10.4726 9.66362 10.0025C9.64658 9.9088 9.62638 9.79782 9.59657 9.66667C9.44704 9.00887 9.02312 8.83333 8 8.83333C6.97688 8.83333 6.55296 9.00887 6.40343 9.66667C6.37362 9.79782 6.35342 9.9088 6.33638 10.0025C6.25082 10.4726 6.24468 10.5064 5.5 10.4654C1.80728 10.2622 0.5 9.44576 0.5 7.16667C0.5 5.34367 0.872335 4.46843 3.1103 4.08845ZM4.85824 3.90569C5.72578 3.85422 6.76237 3.83333 8 3.83333C9.23763 3.83333 10.2742 3.85422 11.1418 3.90569C10.9848 3.37069 10.7456 3.02827 10.4785 2.79938C9.98017 2.37224 9.19032 2.16667 8 2.16667C6.80968 2.16667 6.01983 2.37224 5.52149 2.79938C5.25445 3.02827 5.01523 3.37069 4.85824 3.90569Z" />
        <path d="M8 12.1667C7.66767 12.1667 7.39226 12.1514 7.16667 12.1084C6.77631 12.0341 6.58537 11.864 6.39882 11.6978C6.18774 11.5097 5.98229 11.3266 5.5 11.293C5.10635 11.2656 4.75144 11.2292 4.40573 11.191C3.59184 11.1011 2.73875 11.0558 1.95027 10.8226C1.61701 10.724 1.33333 10.6402 1.33333 11.3333C1.33333 14.7424 2.58333 15.5 8 15.5C13.4167 15.5 14.6667 14.7424 14.6667 11.3333C14.6667 10.6402 14.383 10.724 14.0498 10.8226C13.2613 11.0558 12.4082 11.1011 11.5943 11.191C11.2486 11.2292 10.8936 11.2656 10.5 11.293C10.0177 11.3266 9.81225 11.5097 9.60118 11.6978C9.41463 11.864 9.22368 12.0341 8.83333 12.1084C8.60774 12.1514 8.33233 12.1667 8 12.1667Z" />
      </g>
    </Icon>
  );
};

export default PortfolioIcon;
