import { Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import React from 'react';
import BarChartComponent from '../charts/BarChart';

const Trends = ({ selectedCluster }) => {
  return (
    <Stack sx={{ background: 'rgba(0, 0, 0, 0.02)', borderRadius: '8px', padding: '16px' }}>
      <Typography variant="h3" mb="16px">
        Trends
      </Typography>

      <BarChartComponent plot_data={selectedCluster.plot_data} />
    </Stack>
  )
};

export default Trends;
