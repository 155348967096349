import { useCallback, useEffect, type FC } from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress } from "@mui/material";
import {
  type FilteredRequestParams,
  type Scope,
  type ScopeValueId,
} from "~/state/works";
import { useAppState } from "~/state";
// @ts-expect-error
import useStore from "~/services/store/store";
import type { Work, WorkId } from "~/domain/Work/Work.types";
import WorkItem from "../WorkItem";
import type { VanillaPaginatedRequestParams } from "~/store/api/response.types";
import styles from "./WorksList.module.scss";
import QueryString from "qs";

interface WorksListProps {
  scope: Scope;
  scopeValueId: ScopeValueId;
}

const WorksList: FC<WorksListProps> = ({ scope, scopeValueId }) => {
  useLocationSyncEffect();
  useFilterEffect(scope, scopeValueId);

  const { Works, filters } = useAppState();
  const handleWorkClick = useWorkOpenHandler();

  return (
    <InfiniteScroll
      key={scopeValueId}
      element="ul"
      className={cn(
        styles.list,
        Works.works.length && Works.isFirstPageLoading && styles.blur,
      )}
      initialLoad={false}
      pageStart={1}
      loadMore={(page) => {
        Works.fetch(
          {
            page: `${page}`,
            ...filters.curFilObj,
          } as VanillaPaginatedRequestParams & FilteredRequestParams,
          scope,
          scopeValueId,
        );
      }}
      hasMore={Works.hasMore}
      useWindow={true}
      loader={
        <li key="loader" className={styles.loader}>
          <CircularProgress />
        </li>
      }
    >
      {Works.works.length ? (
        Works.works.map((work: Work) => (
          <WorkItem
            key={work.id}
            id={work.id}
            publicationDate={work.publication_date}
            fieldsOfStudy={work.fos_list}
            title={work.title}
            citeCount={work.cited_by_count}
            authors={work.authors_list}
            onClick={handleWorkClick}
          />
        ))
      ) : (
        <div>There are no any works satisfied the search criteria</div>
      )}
    </InfiniteScroll>
  );
};

export default observer(WorksList);

function useLocationSyncEffect() {
  const { search } = useLocation();
  // @ts-expect-error
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  // @ts-expect-error
  const closeOpenOverlays = useStore((state) => state.closeOpenOverlays);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const activeWork = query.get("work");

    activeWork ? setOpenOverlay({ Work: activeWork }) : closeOpenOverlays();
  }, [closeOpenOverlays, search, setOpenOverlay]);
}

function useWorkOpenHandler() {
  const [setOpenOverlay] = useStore((state: any) => [state.setOpenOverlay]);
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(
    (id: WorkId) => {
      setOpenOverlay({ Work: id });
      history.push({
        pathname,
        search: `?work=${id}`,
      });
    },
    [history, pathname, setOpenOverlay],
  );
}

function useFilterEffect(scope: Scope, scopeValueId: ScopeValueId) {
  const { Works } = useAppState();
  const { search } = useLocation();

  useEffect(() => {
    const params = QueryString.parse(search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    Works.fetch(
      {
        page: "1",
        ...params,
      } as VanillaPaginatedRequestParams & FilteredRequestParams,
      scope,
      scopeValueId,
    );
  }, [Works, scope, scopeValueId, search]);
}
