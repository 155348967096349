import React from "react";
import { Provider } from "react-redux";
import { AppStateProvider } from "./state";
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#005BFF",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "rgba(0, 0, 0, 0.06)",
      contrastText: "rgba(0, 0, 0, 0.72)",
    },
    white: {
      main: "#fff",
    },
    lightBlue: {
      main: "rgba(0, 150, 255, 0.08)",
      contrastText: "#005BFF",
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: 30,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AppStateProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppStateProvider>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
