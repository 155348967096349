import { type ChangeEventHandler, type FC, useCallback, useMemo } from "react";
import styles from "./Sources.module.scss";
import Scrollbars from "react-custom-scrollbars-2";
import { Checkbox } from "@mui/material";
import Button from "~/ui-kit/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useAppState } from "~/state";
import { observer } from "mobx-react-lite";

const SourcesFilter: FC = () => {
  const { title } = useStaticMarkupTexts();
  const { filters } = useAppState();
  const handleSourceToggle = useSourceToggleHandler();
  const handleApply = useApplyFilterHandler();

  return (
    <section className={styles.filter}>
      <header>
        <h1 className={styles.title}>{title}</h1>
      </header>
      <Scrollbars>
        <ul>
          {filters.sourcesPossibleValues.map((source) => (
            <li key={source.id}>
              <p className={styles.item}>
                <Checkbox
                  id={source.id}
                  name={source.id}
                  checked={filters.sourcesSelectedValues[source.id]}
                  onChange={handleSourceToggle}
                />
                <label htmlFor={source.id} className={styles.label}>
                  {source.display_name}
                </label>
                <span className={styles.counter}>{source.works_count}</span>
              </p>
            </li>
          ))}
        </ul>
      </Scrollbars>
      <Button
        className={styles.applyButton}
        color="primary"
        onClick={handleApply}
      >
        <div className={styles.text}>
          <p>Apply</p>
          <p>{filters.sourcesCounter} sources</p>
        </div>
      </Button>
    </section>
  );
};

export default observer(SourcesFilter);

function useStaticMarkupTexts() {
  return useMemo(
    () => ({
      title: "Sources of information",
    }),
    [],
  );
}

function useApplyFilterHandler() {
  const { filters } = useAppState();
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(() => {
    history.push({
      pathname,
      search: filters.queryString,
    });
  }, [filters.queryString, history, pathname]);
}

function useSourceToggleHandler() {
  const { filters } = useAppState();

  return useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const id = e.target.name;

      filters.toggleSource(id, e.target.checked);
    },
    [filters],
  );
}
