import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Xls: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="3x" viewBoxCoef="3x" {...props}>
      <g className={styles.shape}>
        <path d="M14.993 10.004H5.98476C5.48427 10.004 4.99074 9.50404 5.00013 9.00404C5.05061 6.30331 5.25774 4.81827 6.10804 4.00169C7.14684 3.004 9.14739 3.004 12.993 3.004C13.4941 3.004 14.4932 3.004 14.993 3.10385C16.0081 3.30686 16.4944 3.504 18.4962 5.50401C20.4981 7.50403 20.7011 8.01819 20.8985 9.00404C20.9985 9.50404 21.0041 11.5041 20.9985 12.0041C20.9976 12.0887 20.9967 12.1726 20.9957 12.2556C20.9458 16.7768 20.9217 18.9614 19.8169 20.0169C18.7838 21.004 16.827 21.004 13 21.004C9.16014 21.004 6.52148 20.9989 5.48427 20.0041C4.95453 19.4961 5.10532 19.0041 5.48425 19.0041H14.993C18.4962 19.0041 18.9967 18.5041 18.9967 15.0041C18.9967 12.3184 18.9967 11.0753 18.3763 10.4999C17.8416 10.004 16.8462 10.004 14.993 10.004Z" />
        <path d="M2.78091 11.3793C2.4359 10.9481 1.8066 10.8782 1.37534 11.2232C0.94408 11.5682 0.874159 12.1975 1.21917 12.6287L2.71941 14.504L1.21917 16.3793C0.874159 16.8106 0.94408 17.4399 1.37534 17.7849C1.8066 18.1299 2.4359 18.06 2.78091 17.6287L4.00004 16.1048L5.21917 17.6287C5.56418 18.06 6.19347 18.1299 6.62473 17.7849C7.05599 17.4399 7.12592 16.8106 6.78091 16.3793L5.28066 14.504L6.78091 12.6287C7.12592 12.1975 7.05599 11.5682 6.62473 11.2232C6.19347 10.8782 5.56418 10.9481 5.21917 11.3793L4.00004 12.9033L2.78091 11.3793Z" />
        <path d="M10 12.004C10 11.4517 9.55232 11.004 9.00004 11.004C8.44775 11.004 8.00004 11.4517 8.00004 12.004V17.004C8.00004 17.5563 8.44775 18.004 9.00004 18.004H11C11.5523 18.004 12 17.5563 12 17.004C12 16.4517 11.5523 16.004 11 16.004H10V12.004Z" />
        <path d="M15.1623 11.004C13.9681 11.004 13 11.9721 13 13.1663C13 14.097 13.5956 14.9233 14.4785 15.2176L15.8891 15.6878C15.9553 15.7099 16 15.7719 16 15.8418C16 15.9314 15.9274 16.004 15.8378 16.004H14C13.4478 16.004 13 16.4517 13 17.004C13 17.5563 13.4478 18.004 14 18.004H15.8378C17.032 18.004 18 17.0359 18 15.8418C18 14.911 17.4045 14.0848 16.5215 13.7904L15.111 13.3203C15.0447 13.2982 15 13.2362 15 13.1663C15 13.0767 15.0727 13.004 15.1623 13.004H17C17.5523 13.004 18 12.5563 18 12.004C18 11.4517 17.5523 11.004 17 11.004H15.1623Z" />
      </g>
    </Icon>
  );
};

export default Xls;
