import { RootState } from "~/store";

import { ResearcherId, selectors } from "./slice";

export const selectTimelineByResearcherId =
  (id: ResearcherId) => (state: RootState) =>
    selectors.selectById(state, id)?.interestsTimeline || null;

export const selectLoading = (state: RootState) =>
  state.ResearchersInterestsTimeline.loading;

export const selectErrorByResearcherId =
  (id: ResearcherId) => (state: RootState) =>
    state.ResearchersInterestsTimeline.errors?.[id] || null;
