import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import ActiveFilter from "./ActiveFilter";
import Authors from "./Authors";
import Works from "./Works";
import Institutions from "./Institutions";
import ResearchersInterestsTimeline from "./ResearchersInterestsTimeline";
import Search from "./Search";

const store = configureStore({
  reducer: {
    Search,
    ActiveFilter: ActiveFilter,
    Authors: Authors,
    Works: Works,
    Institutions,
    ResearchersInterestsTimeline,
  },
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
