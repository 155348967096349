import type { FC } from "react";
import cn from "classnames";

import { EventObject } from "../EventObject";

import styles from "./InputField.module.scss";

export interface InputFieldProps {
  value: string;
  onChange: (event: EventObject<string>) => void;
  placeholder?: string;
  className?: string;
  color?: "primary" | "secondary";
  name?: string;
}

const InputField: FC<InputFieldProps> = ({
  value,
  onChange,
  placeholder,
  className,
  color = "secondary",
  name,
}) => {
  return (
    <fieldset
      className={cn(className, styles.inputField, styles[color])}
      tabIndex={0}
    >
      <input
        className={styles.input}
        value={value}
        onChange={(e) => onChange(new EventObject(e.target.value, { name }))}
        placeholder={placeholder}
      />
    </fieldset>
  );
};

export default InputField;
