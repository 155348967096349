import { FC, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Authors.module.scss";
import { ListItem } from "~/components/List";
import type { AuthorId } from "~/domain/Author/Author.types";
import AuthorItem from "~/components/AuthorItem/_AuthorItem";
import Button from "~/ui-kit/Button";
import { Checkbox, CircularProgress } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { useAppState } from "~/state";
import InfiniteScroll from "react-infinite-scroller";
import { observer } from "mobx-react-lite";
import { InstitutionId } from "~/domain/Institution/Institution.types";
import { useHistory, useLocation } from "react-router-dom";

const AuthorsFilter: FC = () => {
  const { title, peopleTabTitle, organizationsTabTitle } =
    useStaticMarkupTexts();
  const { filters } = useAppState();
  const [type, setType] = useState<"person" | "institution">("person");
  const handleApply = useApplyFilterHandler();

  return (
    <section className={styles.filter}>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <nav className={styles.tabs}>
          <li
            className={cn(styles.tab, type === "person" && styles.activeTab)}
            onClick={() => setType("person")}
          >
            {peopleTabTitle}
          </li>
          <li
            className={cn(
              styles.tab,
              type === "institution" && styles.activeTab,
            )}
            onClick={() => setType("institution")}
          >
            {organizationsTabTitle}
          </li>
        </nav>
      </header>
      <Scrollbars>
        <InfiniteScroll
          key={type}
          element="ul"
          className={styles.content}
          pageStart={filters.getAuthorsPageByType(type)}
          initialLoad={false}
          loadMore={(page) =>
            filters.uploadAuthorsPossibleValuesByType(type, page)
          }
          hasMore={
            filters.getAuthorsPossibleValuesByType(type).length <
            filters.getAuthorsTotalPossibleValuesByType(type)
          }
          useWindow={false}
          loader={
            <div key="loader" className={styles.loader}>
              <CircularProgress />
            </div>
          }
        >
          {filters.getAuthorsPossibleValuesByType(type).map((author) => (
            <ListItem key={author.id}>
              <Checkbox
                id={author.id}
                checked={
                  filters.getAuthorsSelectedValuesByType(type)[author.id] ||
                  false
                }
                onChange={(e) => {
                  filters.toggleAuthorByType(type, author.id, e.target.checked);
                }}
              />
              <label htmlFor={author.id}>
                <AuthorItem
                  id={author.id}
                  // @ts-expect-error
                  hindex={author.hindex}
                  name={author.display_name}
                  // @ts-expect-error
                  from={author.institution_name}
                  // @ts-expect-error
                  hasEmail={!!author.emails?.length}
                  // @ts-expect-error
                  hasLinkedIn={!!author.linkedin_url}
                  worksCount={author.works_count}
                  // @ts-expect-error
                  citedByCount={author.cited_by_count}
                  isPerson={type === "person"}
                  lastActivePeriod="5y"
                />
              </label>
            </ListItem>
          ))}
        </InfiniteScroll>
      </Scrollbars>
      <footer className={styles.footer}>
        <ul className={styles.selected}>
          {Object.keys(filters.allAuthorsSelectedValues).map(
            (authorId: AuthorId | InstitutionId) => (
              <li
                className={styles.badge}
                key={authorId}
                onClick={() =>
                  filters.toggleAuthorByType(null, authorId, false)
                }
              >
                {
                  filters.allAuthorsPossibleValues.find(
                    (author) => author.id === authorId,
                  )?.display_name
                }
              </li>
            ),
          )}
        </ul>
        <Button
          className={styles.applyButton}
          color="primary"
          onClick={handleApply}
        >
          <div className={styles.text}>
            <p>Apply filter</p>
            <p>{filters.authorsCounter} authors</p>
          </div>
        </Button>
      </footer>
    </section>
  );
};

export default observer(AuthorsFilter);

function useStaticMarkupTexts() {
  return useMemo(
    () => ({
      title: "Person and Institution filter",
      peopleTabTitle: "Authors",
      organizationsTabTitle: "Institutions",
    }),
    [],
  );
}

function useApplyFilterHandler() {
  const { filters } = useAppState();
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(() => {
    history.push({
      pathname,
      search: filters.queryString,
    });
  }, [filters.queryString, history, pathname]);
}
