import React, { FC, useLayoutEffect, useRef } from 'react';

import { TSubscriber, workspaceViewportService } from '../service';

import { ICanvasProps } from './types';

import styles from './Canvas.module.scss';

const Canvas: FC<ICanvasProps> = ({ children, width, height }) => {
  const canvasRef = useRef<SVGSVGElement | null>(null);
  const graphRef = useRef<SVGGElement | null>(null);

  useLayoutEffect(() => {
    const canvasElement = canvasRef.current as SVGSVGElement;
    const graphElement = graphRef.current as SVGGElement;

    const handleViewportChange: TSubscriber = ({ x, y, scale }) => {
      graphElement.setAttribute('style', `transform: translate3d(${x}px, ${y}px, 0) scale(${scale})`);
    };
    const handleWheel = (e: WheelEvent) =>
      (e.target as HTMLTextAreaElement)?.type !== 'textarea' && e.preventDefault();

    canvasElement.addEventListener('wheel', handleWheel, { passive: false });

    workspaceViewportService.inititalize();
    workspaceViewportService.setCanvasElement(canvasElement);
    workspaceViewportService.setGraphElement(graphElement);
    workspaceViewportService.subscribe(handleViewportChange);

    return () => {
      canvasElement.removeEventListener('wheel', handleWheel);

      workspaceViewportService.unsubscribe(handleViewportChange);
      workspaceViewportService.clear();
    };
  }, []);

  useLayoutEffect(() => {
    const { current: graphElement } = graphRef;

    if (graphElement) {
      const { x, y, scale } = workspaceViewportService.getViewport();

      graphElement.setAttribute('style', `transform: translate3d(${x}px, ${y}px, 0) scale(${scale})`);
    }
  }, [children, width, height]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-draggable={true}
      version="1.1"
      id="canvas"
      ref={canvasRef}
      className={styles.canvas}
      width={width}
      height={height}
      viewBox={`${0} ${0} ${width} ${height}`}
    >
      <g ref={graphRef}>{children}</g>
    </svg>
  );
};

export default Canvas;
