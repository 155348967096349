import axios from "axios";

export const CONTENT_TYPES = {
  APPLICATION_JSON: "application/json",
  TEXT_PLAIN: "text/plain",
};

// axios.interceptors.response.use(res => res, function (error) {
//     if (error?.response?.data?.detail) {
//         toastr.error(error.response.data.detail);
//     } else if (error?.response?.status >= 400) {
//         toastr.error('Произошла ошибка');
//     }
//     return Promise.reject(error);
// });

const post = (url, data = {}, requestId = null) =>
  axios.post(url, data, { requestId });

const put = (url, data = {}, requestId = null) =>
  axios.put(url, data, { requestId });

const get = (url, options) => axios.get(url, options);

const del = (url, requestId = null) => axios.delete(url, { requestId });

const api =
  process.env.NODE_ENV === "development"
    ? "https://explority.ai/"
    : "https://explority.ai/";

export const fetchFoses = () => get(`${api}api/v1/fos`);

export const createTask = (model) => post(`${api}api/v1/search_tasks/`, model);

export const fetchTaskStatus = (id) => get(`${api}api/v1/search_tasks/${id}`);

export const fetchClusters = (id, model) =>
  get(`${api}api/v1/search_tasks/${id}/clusters/`, { ...model });

export const fetchFavoriteClusters = () =>
  get(`${api}api/v1/clusters/favorites`);

export const fetchAuthors = (id, model) =>
  get(`${api}api/v2/clusters/${id}/authors/`, { ...model });

export const fetchInstitutions = (id, model) =>
  get(`${api}api/v1/clusters/${id}/institutions/`, { ...model });

export const fetchVenuses = (id) => get(`${api}api/v1/clusters/${id}/venues/`);

export const searchAuthorLinkedin = (id) =>
  get(`${api}api/v1/authors/${id}/search_linkedin/`);

export const searchAuthorEmails = (id) =>
  get(`${api}api/v1/authors/${id}/search_emails/`);

export const downloadAuthors = (id) =>
  axios({
    url: `${api}api/v1/clusters/${id}/authors/xls/`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${"Authors"}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });

export const fetchTasks = (model) =>
  get(`${api}api/v1/search_tasks/`, { ...model });

export const fetchWorks = (id, model) =>
  get(`${api}api/v1/clusters/${id}/works/`, { ...model });

export const fetchWork = (id) => get(`${api}api/v1/works/${id}`);

export const copyRegions = (model) =>
  post(`${api}api/v1/regions/copy/`, { ...model });

export const fetchUsers = () => get(`${api}api/v1/users/`);

export const fetchLocations = (params) =>
  get(`${api}api/v1/locations/`, { params });

export const fetchLocation = (id) => get(`${api}api/v1/locations/${id}/`);

export const putLocation = (id, model) =>
  put(`${api}api/v1/locations/${id}/`, { ...model });

export const fetchScenarios = (params) =>
  get(`${api}api/v1/scenarios/`, { params });

export const fetchDefaultParams = () =>
  get(`${api}api/v1/scenarios/default_params/`);

export const postDepot = (model) => post(`${api}api/v1/depots/`, { ...model });

export const putDepot = (id, model) =>
  put(`${api}api/v1/depots/${id}/`, { ...model });

export const deleteDepot = (id) => del(`${api}api/v1/depots/${id}/`);

export const putScenario = (model, id) =>
  put(`${api}api/v1/scenarios/${id}/`, { params: { ...model } });

export const postScenario = (model) =>
  post(`${api}api/v1/scenarios/`, { ...model });

export const deleteScenario = (id) => del(`${api}api/v1/scenarios/${id}/`);

export const getCalculateTask = (id) =>
  get(`${api}api/v1/scenarios/${id}/calculate/`);

export const getTaskById = (id) => get(`${api}api/v1/tasks/${id}/`);

export const removeTask = (id) => get(`${api}api/v1/tasks/${id}/`);

export const addToFavorite = (id) =>
  get(`${api}api/v1/clusters/${id}/favorite/`);

export const fetchScenario = (id) => get(`${api}api/v1/scenarios/${id}/`);

export const fetchNearest = (ids) =>
  get(`${api}api/v1/regions/nearest/?region_ids=${ids}`);

export const uploadXLS = (formData, params) => {
  return post(
    `${api}api/v1/locations/xls?region_id=${params.region_id}&force_delete=${params.force_delete}`,
    formData,
    {
      headers: {
        "Content-Type":
          "type=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    },
  );
};

const conf = {
  headers: { "content-type": "multipart/form-data" },
};

export const postCredentials = (model) =>
  axios.post(`${api}api/v1/login/access-token/`, model);

export const fetchUser = () => post(`${api}api/v1/login/test-token/`);

export const exportToXSL = (id, region) =>
  axios({
    url: `${api}api/v1/tasks/${id}/xls/`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${region}.xls`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });

export const downLoadExampleXLS = () =>
  axios({
    url: `${api}api/v1/locations/xls/template/`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.xls"); //or any other extension
    document.body.appendChild(link);
    link.click();
  });

export const postUser = (model) => post(`${api}api/v1/users/`, { ...model });

export const putUser = (id, model) =>
  post(`${api}api/v1/users/${id}/`, { ...model });

export const deleteUser = (id) => del(`${api}api/v1/users/${id}/`);
