import * as effects from "./effects";
import * as hooks from "./hooks";
import * as selectors from "./selectors";
import { actions } from "./state";

export { default } from "./state";

export const SearchSlice = {
  ...selectors,
  ...actions,
  ...effects,
  ...hooks,
};
