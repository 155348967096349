import { useCallback, useEffect, useState, type FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import AuthorInfo from "./AuthorInfo";
import AuthorCoauthors from "./AuthorCoauthors";

import InterestsChart from "../../components/InterestsChart";
import Filters from "../../components/Filters";

import type { AuthorId } from "../../domain/Author/Author.types";

import styles from "./Author.module.scss";
import { useSetFiltrationScope } from "../../store/ActiveFilter/hooks";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { Alert, CircularProgress } from "@mui/material";
import { InterestsTimelineState } from "~/store/ResearchersInterestsTimeline";
import { EventObject } from "~/ui-kit/EventObject";
import { FieldsOfStudyLevel } from "~/store/ResearchersInterestsTimeline/effects";
import { useAppState } from "~/state";
import Works from "~/components/Works";

interface AuthorViewParams {
  id: AuthorId;
}

function useAuthorInterests(id: AuthorId) {
  const { filters } = useAppState();
  const dispatch = useAppDispatch();
  const interestsTimeline = useAppSelector(
    InterestsTimelineState.selectTimelineByResearcherId(id),
  );
  const isLoading = useAppSelector(InterestsTimelineState.selectLoading);
  const error = useAppSelector(
    InterestsTimelineState.selectErrorByResearcherId(id),
  );
  const [level, setLevel] = useState<FieldsOfStudyLevel>(0);
  const { search } = useLocation();

  useEffect(() => {
    filters.init(null, null, "author", id, new URLSearchParams(search));
  }, [filters, id, search]);

  useEffect(() => {
    if (!interestsTimeline)
      dispatch(
        InterestsTimelineState.fetchAuthorInterestsTimeline({ id, level: 0 }),
      );
  }, [dispatch, id, interestsTimeline]);

  return {
    worksByYear: interestsTimeline,
    isLoading,
    error,
    fieldsOfStudyLevel: level,
    fetchInterestsTimeline: useCallback(
      (e: EventObject<FieldsOfStudyLevel>) => {
        setLevel(e.value);
        dispatch(
          InterestsTimelineState.fetchAuthorInterestsTimeline({
            id,
            level: e.value,
          }),
        );
      },
      [dispatch, id],
    ),
  };
}

const Author: FC = () => {
  const { id } = useParams<AuthorViewParams>();
  useSetFiltrationScope("authors");
  const {
    worksByYear,
    isLoading,
    error,
    fieldsOfStudyLevel,
    fetchInterestsTimeline,
  } = useAuthorInterests(id);

  return (
    <main className={styles.view}>
      <aside className={styles.info}>
        <AuthorInfo id={id} />
      </aside>
      <section className={styles.content}>
        <div>
          {isLoading && <CircularProgress />}
          {!!error && <Alert severity="error">{error}</Alert>}
          {!isLoading && !error && worksByYear && (
            <InterestsChart
              worksByYears={worksByYear}
              key={id}
              fieldsOfStudyLevel={fieldsOfStudyLevel}
              onFieldsOfStudyLevelChange={fetchInterestsTimeline}
            />
          )}
        </div>
        <Filters />
        <div className={styles.filterResults}>
          <div className={styles.works}>
            <Works scope="author" scopeValueId={id} />
          </div>
          <AuthorCoauthors className={styles.coauthors} id={id} />
        </div>
      </section>
    </main>
  );
};

export default Author;
