import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import type {
  InstitutionId,
  InstitutionInfo,
} from "~/domain/Institution/Institution.types";

import { RootState } from "~/store";
import { fetchById } from "./effects";

const institutionAdapter = createEntityAdapter<InstitutionInfo>();

export const selectors = institutionAdapter.getSelectors(
  (state: RootState) => state.Institutions,
);

interface InstitutionsInitialState {
  fetchOneLoading: InstitutionId | null;
  fetchOneErrors: Record<InstitutionId, string> | null;
}

const Institutions = createSlice({
  name: "institutions",
  initialState: institutionAdapter.getInitialState<InstitutionsInitialState>({
    fetchOneLoading: null,
    fetchOneErrors: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchById.pending, (state, { meta }) => {
      state.fetchOneLoading = meta.arg;

      if (state.fetchOneErrors && state.fetchOneErrors[meta.arg]) {
        delete state.fetchOneErrors[meta.arg];
      }

      if (
        state.fetchOneErrors &&
        Object.keys(state.fetchOneErrors).length === 0
      ) {
        state.fetchOneErrors = null;
      }
    });
    builder.addCase(fetchById.fulfilled, (state, { payload }) => {
      state.fetchOneLoading = null;

      institutionAdapter.setOne(state, payload);
    });
    builder.addCase(fetchById.rejected, (state, { payload, meta }) => {
      state.fetchOneLoading = null;

      if (!state.fetchOneErrors) state.fetchOneErrors = {};

      state.fetchOneErrors[meta.arg] = payload || "Unhandled exception";
    });
  },
});

export default Institutions.reducer;
