import { createAsyncThunk } from "@reduxjs/toolkit";

import type { AuthorId } from "~/domain/Author/Author.types";
import type { InstitutionId } from "~/domain/Institution/Institution.types";

import {
  getAuthorInterestsByYears as getAuthorInterestsTimeline,
  getInstitutionFieldsOfStudiesByYears as getInstitutionInterestsTimeline,
} from "../api";
import { WorksByYear } from "~/domain/Work/Work.types";
import { AppDispatch, RootState } from "../store";

export type FieldsOfStudyLevel = 0 | 1 | 2 | 3;

export const fetchAuthorInterestsTimeline = createAsyncThunk<
  WorksByYear,
  {
    id: AuthorId;
    level: FieldsOfStudyLevel;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>("interestsTimeline/byAuthorId", async (args, { rejectWithValue }) => {
  try {
    const { data } = await getAuthorInterestsTimeline(args);

    return data;
  } catch (err: unknown) {
    if (err instanceof Error) {
      return rejectWithValue(err.message);
    }

    throw err;
  }
});

export const fetchInstitutionInterestsTimeline = createAsyncThunk<
  WorksByYear,
  { id: InstitutionId; level: FieldsOfStudyLevel },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>("interestsTimeline/byInstitutionId", async (args, { rejectWithValue }) => {
  try {
    const { data } = await getInstitutionInterestsTimeline(args);

    return data;
  } catch (err: unknown) {
    if (err instanceof Error) {
      return rejectWithValue(err.message);
    }

    throw err;
  }
});
