import { FC } from "react";
import styles from "./styles.module.scss";
import type { ClusterId } from "~/domain/Cluster/Cluster.types";
import { useAppState } from "~/state";
import { observer } from "mobx-react-lite";
import { Alert, LinearProgress } from "@mui/material";
import { Topic } from "~/widgets/Folder/Folder.state";
import { NavLink, useRouteMatch } from "react-router-dom";
import cn from "classnames";

interface SecondLevelClusterListProps {
  id: ClusterId;
}

const SecondLevelClusterList: FC<SecondLevelClusterListProps> = ({ id }) => {
  const { Folder } = useAppState();
  const { url } = useRouteMatch();

  if (!Folder.isClusterOpen(id)) return null;

  let content = null;

  if (Folder.secondLevelClusteringStatuses[id]?.status === "IN_PROGRESS") {
    content = (
      <label>
        <LinearProgress
          variant="determinate"
          value={Folder.secondLevelClusteringStatuses[id]?.percent || 0}
        />
      </label>
    );
  }

  if (Folder.secondLevelClusteringStatuses[id]?.status === "FAILED") {
    content = (
      <>
        <Alert severity="error">{Folder.secondLevelClusteringError[id]}</Alert>
      </>
    );
  }

  if (
    Folder.secondLevelClusteringStatuses[id]?.status === "FINISHED" &&
    !Folder.secondLevelClustersProcesses[id] &&
    Folder.getSecondLevelClusters(id)
  ) {
    content = (
      <>
        {Folder.getSecondLevelClusters(id)?.map((sc: Topic) => (
          <NavLink
            to={`${url}/cluster/${sc.id}`}
            activeClassName={styles.active}
            key={sc.id}
          >
            <li key={sc.id}>
              <p>{sc.name}</p>
              <span
                className={cn(
                  styles.trend,
                  sc.trend[Folder.trendBy] === 0 && styles.neutral,
                  sc.trend[Folder.trendBy] > 0 && styles.positive,
                  sc.trend[Folder.trendBy] < 0 && styles.negative,
                )}
              >
                {sc.trend[Folder.trendBy]}
              </span>
            </li>
          </NavLink>
        ))}
      </>
    );
  }

  return <ul className={styles["cluster-list__second-level"]}>{content}</ul>;
};

export default observer(SecondLevelClusterList);
