import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Folder: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M1.69912 5.20043C1.21097 5.68859 1.21097 6.48004 1.69912 6.9682C2.18728 7.45635 2.97874 7.45635 3.46689 6.9682L4.71086 5.72422C4.74726 6.71064 4.80976 7.5815 4.91168 8.34587C5.10451 9.7921 5.45604 11.0152 6.17556 11.9506C6.92999 12.9314 7.96191 13.4365 9.16578 13.6989C10.3279 13.9522 11.7532 14.001 13.4163 14.001C14.1067 14.001 14.6663 13.4413 14.6663 12.751C14.6663 12.0606 14.1067 11.501 13.4163 11.501C11.7461 11.501 10.5673 11.4456 9.69815 11.2562C8.87077 11.0759 8.44435 10.7997 8.15712 10.4263C7.83498 10.0076 7.5615 9.30361 7.38975 8.01546C7.30643 7.39057 7.25116 6.66506 7.21644 5.81885L8.36579 6.9682C8.85395 7.45635 9.6454 7.45635 10.1336 6.9682C10.6217 6.48004 10.6217 5.68859 10.1336 5.20043L6.80022 1.8671C6.5571 1.62397 6.23499 1.50193 5.91634 1.50098C5.57885 1.50197 5.27126 1.62829 5.03246 1.8671L1.69912 5.20043Z" />
      </g>
    </Icon>
  );
};

export default Folder;
