import type {
  InstitutionId,
  InstitutionInfo,
} from "~/domain/Institution/Institution.types";

import { RootState } from "~/store";

import { selectors } from "./slice";

export const selectById =
  (id: InstitutionId) =>
  (state: RootState): InstitutionInfo | null =>
    selectors.selectById(state, id) || null;

export const selectFetchOneLoading = (state: RootState) =>
  state.Institutions.fetchOneLoading;

export const selectFetchOneError = (id: InstitutionId) => (state: RootState) =>
  state.Institutions.fetchOneErrors?.[id] || null;
