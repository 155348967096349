import axios, { AxiosResponse } from "axios";
import { BASE_HOST, BASE_URL } from "./const";
import type { Author, AuthorId } from "../../domain/Author/Author.types";
import type {
  PaginatedRequestParams,
  PaginatedResponse,
} from "./response.types";
import type { ClusterId } from "../../domain/Cluster/Cluster.types";
import { InstitutionId } from "../../domain/Institution/Institution.types";
import { WorksByYear } from "~/domain/Work/Work.types";
import { FieldsOfStudyLevel } from "../ResearchersInterestsTimeline/effects";

export const getAuthorsByClusterId = async ({
  id,
  ...params
}: PaginatedRequestParams<ClusterId>) => {
  return axios.get<PaginatedResponse<Author>>(
    `${BASE_URL}/clusters/${id}/authors`,
    { params },
  );
};

export const getAuthorsByAuthorId = async ({
  id,
  ...params
}: PaginatedRequestParams<AuthorId>) => {
  return axios.get<PaginatedResponse<Author>>(
    `${BASE_URL}/authors/${id}/co_authors`,
    {
      params,
    },
  );
};

export const getAuthorsByInstitutionId = async ({
  id,
  ...params
}: PaginatedRequestParams<InstitutionId>) => {
  return axios.get<PaginatedResponse<Author>>(
    `${BASE_URL}/institution/${id}/authors`,
    {
      params,
    },
  );
};

export const getAuthorById = async (id: AuthorId) => {
  return axios.get<Author>(`${BASE_URL}/authors/${id}`);
};

export type ContactType = "email" | "linkedin";
const updateAuthorContactsRequest: Record<
  ContactType,
  (id: AuthorId) => Promise<AxiosResponse<Author>>
> = {
  email: (id) => axios.get<Author>(`${BASE_URL}/authors/${id}/search_emails`),
  linkedin: (id) =>
    axios.get<Author>(`${BASE_URL}/authors/${id}/search_linkedin`),
};

export const updateAuthorContactsById = async (
  id: AuthorId,
  contactType: ContactType,
) => {
  return updateAuthorContactsRequest[contactType](id);
};

export const getAuthorInterestsByYears = async (options: {
  id: AuthorId;
  level: FieldsOfStudyLevel;
}) => {
  return axios.get<WorksByYear>(
    `${BASE_HOST}/api/v2/authors/${options.id}/fos_by_year`,
    {
      params: {
        level: options.level,
      },
    },
  );
};
