import React, { useCallback } from "react";
import type { FC } from "react";
import classNames from "classnames";
import * as d3 from "d3";
import Tippy from "@tippyjs/react";
import { EventObject } from "../../ui-kit/EventObject";
import "tippy.js/dist/tippy.css";
import styles from "./CirclePackingChart.module.scss";

type Leaf = {
  name: string;
  id?: string | number;
  value: number;
};

type Branch = {
  name: string;
  id?: string | number;
  children: Array<Branch | Leaf>;
};

export type Tree = Branch;

interface CirclePackingChartProps {
  data: d3.HierarchyCircularNode<Branch>; //Tree;
  focus?: string;
  onFocus?: (e: EventObject<string | number>) => {};
  onClick?: (e: EventObject<string | number>) => {};
}

export function buildCirclePackingChartData(
  data: Tree,
): d3.HierarchyCircularNode<Branch> {
  const dimentions = {
    width: 928,
    height: 928,
    paddings: 4,
  };
  const hierarchy = d3
    .hierarchy(data)
    .sum((d: any) => d.value)
    .sort((a: any, b: any) => b.value - a.value);
  const packGenerator = d3
    .pack<Tree>()
    .size([dimentions.width, dimentions.height])
    .padding(dimentions.paddings);
  const color = d3
    .scaleLinear(["#282b2d", "#fff"])
    .domain([0, 5])
    .interpolate(d3.interpolateHcl);
  const root = packGenerator(hierarchy);

  return root;
}

const CirclePackingChart: FC<CirclePackingChartProps> = ({
  data,
  focus,
  onFocus,
  onClick,
}) => {
  const color = d3
    .scaleLinear(["#282b2d", "#fff"])
    .domain([0, 5])
    .interpolate(d3.interpolateHcl);
  const root = buildCirclePackingChartData(data.data);

  const colores = {
    1: "#3d4042",
    focused: "#36414a",
    // focused: 'red',
    work: "#5e676e",
    hovered: "#fff",
  };
  const getColor = useCallback(
    (d: d3.HierarchyCircularNode<Branch>) => {
      if (d.data.id === focus) {
        return colores.focused;
      }

      return d.depth === 1 ? colores[1] : color(d.depth);
    },
    [focus],
  );
  const handleClick = useCallback(
    (node: d3.HierarchyCircularNode<Branch>) => {
      if (!node.data.children) {
        onClick?.(new EventObject(node.data.id!));
      } else {
        onFocus?.(new EventObject(node.data.id!, { data: node }));
      }
    },
    [onClick, onFocus],
  );

  return (
    <>
      {root
        .descendants()
        .slice(1)
        .map((d) => {
          return (
            <Tippy
              key={d.data.id}
              content={d.data.name}
              className={classNames(d.data.children && styles.hidden)}
            >
              <circle
                className={styles.circle}
                r={d.r}
                cx={d.x}
                cy={d.y}
                fill={getColor(d)}
                onClick={() => handleClick(d)}
              />
            </Tippy>
          );
        })}
    </>
  );
};

export default CirclePackingChart;
