import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const SearchHistory: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="3x" viewBoxCoef="3x" {...props}>
      <g className={styles.shape}>
        <path d="M15 15C15.4796 15 15.9461 14.9437 16.3931 14.8374L18.1743 18.2019C18.5619 18.934 19.4697 19.2133 20.2018 18.8257C20.934 18.4381 21.2133 17.5304 20.8257 16.7982L19.0439 13.4326C20.2459 12.3353 21 10.7557 21 9C21 5.68629 18.3137 3 15 3C11.6863 3 9 5.68629 9 9C9 12.3137 11.6863 15 15 15ZM15 12C13.3431 12 12 10.6569 12 9C12 7.34315 13.3431 6 15 6C16.6569 6 18 7.34315 18 9C18 10.6569 16.6569 12 15 12Z" />
        <path d="M4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11H5.5C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8H4.5Z" />
        <path d="M4.5 12C3.67157 12 3 12.6716 3 13.5C3 14.3284 3.67157 15 4.5 15H7.5C8.32843 15 9 14.3284 9 13.5C9 12.6716 8.32843 12 7.5 12H4.5Z" />
        <path d="M3 17.5C3 16.6716 3.67157 16 4.5 16H11.5C12.3284 16 13 16.6716 13 17.5C13 18.3284 12.3284 19 11.5 19H4.5C3.67157 19 3 18.3284 3 17.5Z" />
      </g>
    </Icon>
  );
};

export default SearchHistory;
