import React, { useEffect, useState } from 'react';
import { fetchAuthor, fetchFoses, fetchWork } from '../services/api';
import useStore from '../services/store/store';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import { Box, Stack } from '@mui/system';
import CustomChip from '../common/chip';
import { Typography } from '@mui/material';
import List from '../AuthorsList/List';

const FieldsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const ArticleWrapper = styled(Stack)`
  /* .main-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  } */
  cursor: pointer;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 400;

  .cited {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .field, .author {
    color: rgba(0, 0, 0, 0.42);
    margin-left: 8px;
  }
`;

const ArticleOverlay = (props) => {

  const [result, setResult] = useState(null);

  useEffect(() => {
    const initOverlay = async () => {
      const res = await fetchWork(props.open);
      // console.log(res);
      setResult(res.data);
    };

    initOverlay();
  }, [props.open]);

  const onUpdate = (item) => {
    const list = [...result.authors];
    list[list.findIndex(el => el.id === item.id)] = item;
    setResult({ ...result, authors: list });
  }

  return (
    <BasicDialog {...props}>
      {result &&(
      <ArticleWrapper container spacing={2} display="flex">
        <Box item>
          <span className="date">{result.publication_date}</span>
        </Box>
        <Box item spacing={1} sx={{ mt: '4px' }}>
          <Typography variant="h3">
            {result.title}
          </Typography>
          <Stack direction="row" sx={{ mt: '4px' }} spacing={1} flexWrap="wrap">
            {result.fos_list.splice(0, 3).map(fos => (
              <CustomChip size="small" label={fos} />
            ))}
          </Stack>
        </Box>
        <Box item sx={{ mt: '4px' }}>
          <Typography variant="h3">
            Abstract
          </Typography>
          <Typography>
            {result.abstract}
          </Typography>
        </Box>
         <Box item sx={{ mt: '4px' }}>
          <Typography variant="h3">
            LinkedIn
          </Typography>
          <Typography>
              <List list={result.authors || []} onUpdate={onUpdate} />
          </Typography>
        </Box>
      </ArticleWrapper>
      )}
    </BasicDialog>
  )
};

export default ArticleOverlay;
