import { useEffect, type FC } from "react";
import type { AuthorId } from "../../../domain/Author/Author.types";
import InfoCard from "../../../components/InfoCard";
import Card from "../../../ui-kit/Card";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AuthorsState } from "../../../store/Authors";
import { Alert, Box, CircularProgress } from "@mui/material";

interface AuthorInfoProps {
  id: AuthorId;
}

function getStaticMarkupTexts() {
  return {
    education: "Education",
    experience: "Experience",
    awards: "Awards",
  };
}

function useAuthorInfo(id: AuthorId) {
  const dispatch = useAppDispatch();
  const author = useAppSelector(AuthorsState.selectAuthorById(id));
  const isLoading = useAppSelector(
    AuthorsState.selectAuthorRequestLoadingState,
  );
  const error = useAppSelector(AuthorsState.selectFetchOneError);

  useEffect(() => {
    dispatch(AuthorsState.fetchAuthorById(id));
  }, [dispatch, id]);

  return {
    author,
    isLoading: isLoading,
    error,
  };
}

const AuthorInfo: FC<AuthorInfoProps> = ({ id }) => {
  const { author, isLoading, error } = useAuthorInfo(id);
  const { education, experience, awards } = getStaticMarkupTexts();

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <InfoCard
        id={id}
        name={author?.display_name}
        description={author?.institution_name}
        articlesCount={author?.works_count}
        quotesCount={author?.cited_by_count}
        hIndex={author?.hindex}
        linkedin={author?.linkedin_url}
        emails={author?.emails}
      />
      <Card title={education} collapsible={true} disabled={true} />
      <Card title={experience} collapsible={true} disabled={true} />
      <Card title={awards} collapsible={true} disabled={true} />
    </>
  );
};

export default AuthorInfo;
