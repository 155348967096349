import { Alert, CircularProgress, TextField } from "@mui/material";
import { FC, useState } from "react";
import Button from "~/ui-kit/Button";
import styles from "./styles.module.scss";
import Xls from "~/icons/Xls";
import { observer } from "mobx-react-lite";
import { useAppState } from "~/state";

interface FolderCreateDialogProps {
  onCreate: () => void;
  onCancel: () => void;
}

const FolderCreateDialog: FC<FolderCreateDialogProps> = ({
  onCreate,
  onCancel,
}) => {
  const [folderName, setFolderName] = useState("");
  const [queriesFile, setQueriesFile] = useState<File | null>(null);
  const { Folders } = useAppState();

  return (
    <form className={styles["dialog-form"]}>
      <header>
        <h1>Adding a request group</h1>
        {Folders.creatingError && (
          <Alert severity="error">{Folders.creatingError}</Alert>
        )}
      </header>
      <section>
        <TextField
          id="folder-name"
          label="Group name"
          variant="filled"
          required
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <fieldset>
          <legend>Write up to 10,000 queries in English</legend>
          <TextField
            id="queries-list"
            label={queriesFile?.name}
            variant="filled"
            disabled
            InputProps={{
              endAdornment: (
                <label className={styles["upload-button"]}>
                  <Xls />
                  <input
                    type="file"
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        setQueriesFile(e.target.files[0]);
                      } else {
                        setQueriesFile(null);
                      }
                    }}
                  />
                </label>
              ),
            }}
          />
          <aside>
            Up to 256 characters per request. To separate, use Enter or the
            symbols , . ;
          </aside>
        </fieldset>
      </section>
      <footer>
        <Button
          color="primary"
          disabled={!folderName || !queriesFile || Folders.isCreating}
          onClick={async () => {
            const status = await Folders.createFolder({
              name: folderName,
              file: queriesFile!,
            });

            if (status) {
              onCreate();
            }
          }}
        >
          Add request group
          {Folders.isCreating && (
            <CircularProgress color="secondary" size={30} />
          )}
        </Button>
        <Button
          color="primary"
          accent="light"
          onClick={onCancel}
          disabled={Folders.isCreating}
        >
          Cancel
        </Button>
      </footer>
    </form>
  );
};

export default observer(FolderCreateDialog);
