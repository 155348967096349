import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { Alert, LinearProgress, Skeleton, TextField } from "@mui/material";

import { useAppState } from "~/state";
import styles from "./styles.module.scss";
import Button from "~/ui-kit/Button";
import classNames from "classnames";
import Search from "~/icons/Search";
import FolderContentClusterList from "./Cluster/List/FolderContentClusterList";
import { runInAction } from "mobx";

const FolderContent: FC = () => {
  const { Folder } = useAppState();

  let content = (
    <>
      <section className={styles["pattern-filter"]}>
        <Skeleton height={48} animation="wave" variant="rounded" />
      </section>
      <section className={styles["trend-filters"]}>
        <Skeleton height={32} width={35} animation="wave" variant="rounded" />
        <Skeleton height={32} width={64} animation="wave" variant="rounded" />
        <Skeleton height={32} width={64} animation="wave" variant="rounded" />
        <Skeleton height={32} width={64} animation="wave" variant="rounded" />
      </section>
      <FolderContentClusterList />
    </>
  );

  if (Folder.status?.status === "IN_PROGRESS") {
    content = (
      <>
        <label>
          <LinearProgress
            variant="determinate"
            value={Folder.status?.percent || 0}
          />
          Usually takes a few seconds
        </label>
      </>
    );
  }

  if (Folder.status?.status === "FAILED") {
    content = (
      <>
        <Alert severity="error">{Folder.clusteringError}</Alert>
      </>
    );
  }

  if (
    Folder.status?.status === "FINISHED" &&
    !Folder.isClustersLoading &&
    Folder.clusters
  ) {
    content = (
      <>
        <section className={styles["pattern-filter"]}>
          <TextField
            className={styles["pattern-field"]}
            placeholder="Find among elements"
            id="queries-list"
            autoComplete="off"
            value={Folder.searchPattern}
            onChange={(e) => (Folder.searchPattern = e.target.value)}
            InputProps={{
              startAdornment: <Search width={24} />,
            }}
          />
        </section>
        <section className={styles["trend-filters"]}>
          <p>Trend</p>
          <Button
            className={classNames(Folder.trendBy === "week" && styles.active)}
            onClick={() => runInAction(() => (Folder.trendBy = "week"))}
          >
            Week
          </Button>
          <Button
            className={classNames(Folder.trendBy === "month" && styles.active)}
            onClick={() => runInAction(() => (Folder.trendBy = "month"))}
          >
            Month
          </Button>
          <Button
            className={classNames(
              Folder.trendBy === "quarter" && styles.active,
            )}
            onClick={() => runInAction(() => (Folder.trendBy = "quarter"))}
          >
            Quarter
          </Button>
        </section>
        <FolderContentClusterList />
      </>
    );
  }

  return <section className={styles.folder}>{content}</section>;
};

export default observer(FolderContent);
