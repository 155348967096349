import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import BasicDialog from './Basic';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import CustomChip from '../common/chip';
import useStore from '../services/store/store';

function valuetext(value) {
  return `${value}°C`;
}

const PerioadOverlay = (props) => {

  const [period, setPeriod] = useStore(state => [state.period, state.setPeriod]);

  const handleChange = (e) => {
    const arr = e.target.value;
    setPeriod({ from: arr[0], to: arr[1] });
  }

  return (
    <BasicDialog {...props}>
      <Stack sx={{p: 4}}>
        <Box>
          <Slider
            min={1997}
            max={2023}
            getAriaLabel={() => 'Temperature range'}
            value={[period.from, period.to]}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > :not(style)': { m: 1 },
          }}
        >
          <TextField
            id="demo-helper-text-aligned"
            label="from"
            type="number"
            size="small"
            value={period.from}
            onChange={e => setPeriod({ from: +e.target.value })}
          />
          <TextField
            id="demo-helper-text-aligned-no-helper"
            label="to"
            value={period.to}
            type="number"
            size="small"
            onChange={e => setPeriod({ to: +e.target.value })}
          />
        </Box>
        <Stack direction="row" spacing={1}>
          <CustomChip onClick={() => setPeriod({ from: 2021, to: 2023 })} label="Last year" />
          <CustomChip onClick={() => setPeriod({ from: 2017, to: 2023 })} label="5 years" />
          <CustomChip onClick={() => setPeriod({ from: 2012, to: 2023 })} label="10 years" />
        </Stack>
      </Stack>
    </BasicDialog>
  );
};

export default PerioadOverlay;
