import styled from "@emotion/styled";
import LanguageIcon from "@mui/icons-material/Language";
// @ts-expect-error
import useStore from "../../services/store/store";
import { Link } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import CircularProgress from "@mui/material/CircularProgress";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Avatar, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, type FC } from "react";
import { AuthorId, Email } from "../../domain/Author/Author.types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AuthorsState } from "../../store/Authors";

interface AuthorItemProps {
  id: AuthorId;
  citeCount: number;
  name: string;
  hIndex?: number;
  worksCount: number;
  linkedinUrl?: string;
  linkedinUpdateDate?: string;
  emails?: Array<Email>;
  institution?: string;
}

export const StyledGroup = styled(ToggleButtonGroup)`
  border: 0;
  margin: 4px;
  & {
    button {
      line-height: 1;
      padding: 4px 8px;
      border: none !important;
      background: rgba(0, 0, 0, 0.04);
      &.Mui-selected {
        background: rgba(0, 0, 0, 0.06);
        padding: 4px 8px;
        font-weight: 700;
      }
      &.Mui-disabled {
        border: none;
      }
    }

    button:not(:first-of-type) {
      border-radius: 8px;
    }

    button:first-of-type {
      border-radius: 8px;
    }
  }
`;

const ListItemWrapper = styled(Box)`
  padding: 8px 0;
  .linkedin__icon,
  .email__icon {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background: #96bafc;
    width: 16px;
    height: 16px;
  }

  .email__icon {
    background: #4caf50;
  }

  .email__icon_empty {
    background: #ef5350;
  }

  .linkedin__icon_empty {
    background: #001a34;
    opacity: 0.2;
  }

  .author {
    display: flex;
    &__contacts {
      display: flex;
      flex: 1 0 40px;
      justify-content: space-between;
    }
    &__linkedin,
    &__email {
      cursor: pointer;
      &__item {
        font-size: 20px;
      }
    }
    &__info {
      margin-left: 8px;
      .info__name {
      }
      .info__universtity {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.42);
      }
    }
  }
`;

const AuthorItem: FC<AuthorItemProps> = ({
  id,
  linkedinUrl: _linkedinUrl,
  emails: _emails,
  linkedinUpdateDate: _linkedinUpdateDate,
  name,
  institution,
  worksCount,
  hIndex,
}) => {
  const {
    handleEmailClick,
    handleLinkedinClick,
    isLoading,
    linkedinUrl,
    linkedinUpdateDate,
    emails,
  } = useAuthorContactsUpdateHandlers(
    id,
    _linkedinUrl,
    _emails,
    _linkedinUpdateDate,
  );

  return (
    <ListItemWrapper display="flex" flex={1} justifyContent="space-between">
      <div className="left">
        <div className="author">
          <div className="author__contacts">
            <div
              onClick={() => handleLinkedinClick(id)}
              className="author__linkedin"
            >
              {linkedinUrl && (
                <Tooltip title="link" placement="top" arrow>
                  <Avatar className="linkedin__icon" alt="Remy Sharp">
                    in
                  </Avatar>
                </Tooltip>
              )}
              {!linkedinUrl && !linkedinUpdateDate && (
                <Tooltip title="not searched" placement="top" arrow>
                  <Avatar
                    className="linkedin__icon linkedin__icon_empty"
                    alt="Remy Sharp"
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" size={30} />
                    ) : (
                      <LanguageIcon fontSize="small" />
                    )}
                  </Avatar>
                </Tooltip>
              )}
              {!linkedinUrl && linkedinUpdateDate && (
                <Tooltip title="not found" placement="top" arrow>
                  <Avatar
                    className="linkedin__icon linkedin__icon_empty"
                    alt="Remy Sharp"
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : (
                      <NotInterestedIcon fontSize="small" />
                    )}
                  </Avatar>
                </Tooltip>
              )}
            </div>
            <div onClick={() => handleEmailClick(id)} className="author__email">
              {!!emails?.length && (
                <Avatar className="email__icon" alt="Remy Sharp">
                  @
                </Avatar>
              )}
              {!emails?.length && (
                <Tooltip title="not found" placement="top" arrow>
                  <Avatar
                    className="email__icon email__icon_empty"
                    alt="Remy Sharp"
                  >
                    {isLoading ? (
                      <CircularProgress size={30} />
                    ) : (
                      <Avatar
                        className="email__icon email__icon_empty"
                        alt="Remy Sharp"
                      >
                        <s>@</s>
                      </Avatar>
                    )}
                  </Avatar>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="author__info">
            <Link to={`/author/${id}`}>
              <Typography
                color={linkedinUrl ? "#005BFF" : "rgba(0, 0, 0, 0.92)"}
                variant="h4"
              >
                {name}
              </Typography>
            </Link>
            {institution && (
              <div className="info__universtity">{institution}</div>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        <StyledGroup
          value={"left"}
          exclusive
          size="small"
          aria-label="text alignment"
        >
          {worksCount && (
            <Tooltip title="works" placement="top" arrow>
              <ToggleButton value="left" aria-label="left aligned">
                {worksCount}
              </ToggleButton>
            </Tooltip>
          )}
          {hIndex && (
            <Tooltip title="h-index" placement="top" arrow>
              <ToggleButton
                className="Mui-selected"
                value="right"
                aria-label="right aligned"
              >
                {hIndex}
              </ToggleButton>
            </Tooltip>
          )}
        </StyledGroup>
      </div>
    </ListItemWrapper>
  );
};

export default AuthorItem;

function useAuthorContactsUpdateHandlers(
  id: AuthorId,
  linkedinUrl?: string,
  emails?: Array<Email>,
  linkedinUpdateDate?: string,
) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    AuthorsState.selectUpdateContactsLoadingState(id),
  );
  const [setOpenOverlay] = useStore((state: any) => [state.setOpenOverlay]);
  const author = useAppSelector(AuthorsState.selectAuthorById(id));

  return {
    isLoading,
    linkedinUrl: linkedinUrl || author?.linkedin_url,
    linkedinUpdateDate: linkedinUpdateDate || author?.linkedin_url_updated_date,
    emails: emails && emails.length ? emails : author?.emails,
    handleLinkedinClick: useCallback(
      (id: AuthorId) => {
        if (author?.linkedin_url) {
          window.open(author?.linkedin_url, "_blank")?.focus();

          return;
        }

        dispatch(
          AuthorsState.updateContactsByAuthorId({
            id,
            contactType: "linkedin",
          }),
        );
      },
      [author?.linkedin_url, dispatch],
    ),
    handleEmailClick: useCallback(
      (id: AuthorId) => {
        if (author?.emails?.length) {
          setOpenOverlay({ EMAILS: author?.emails });

          return;
        }

        dispatch(
          AuthorsState.updateContactsByAuthorId({ id, contactType: "email" }),
        );
      },
      [author?.emails, dispatch, setOpenOverlay],
    ),
  };
}
