import { Skeleton } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss";

const ListItemSkeleton: FC = () => {
  return (
    <li className={styles["folder-list__item"]}>
      <div>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={32}
          className={styles.skeleton}
        />
      </div>
    </li>
  );
};

export default ListItemSkeleton;
