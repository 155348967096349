import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const LayersIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M1.84302 3.69038L6.42506 1.15746C8.01105 0.280751 8.01139 0.280941 9.59724 1.15746C11.3867 2.14652 12.3899 2.70121 14.1793 3.69038C15.9414 4.66444 15.9416 4.66457 14.1793 5.63877L9.59724 8.17168C8.01118 9.04837 8.03045 9.05913 6.42506 8.17168L1.84302 5.63877C0.053652 4.6496 0.0536066 4.67954 1.84302 3.69038Z" />
        <path d="M1.58447 7.16667C0.862137 7.56696 0.500977 7.78243 0.500977 7.9979C0.500977 8.23771 0.948331 8.47752 1.84302 8.9721L6.42506 11.505C8.01132 12.3819 8.01141 12.3818 9.54121 11.536C9.55966 11.5258 9.57833 11.5155 9.59724 11.505L14.1793 8.9721C15.8509 8.04804 15.9368 8.00041 14.4374 7.16667C13.857 6.84394 13.7494 6.82855 13.1377 7.16667L9.92751 8.94127C9.57626 9.13555 9.2669 9.30667 8.99913 9.42671C8.7016 9.56009 8.38303 9.66529 8.02124 9.66653C7.65927 9.66776 7.33889 9.56468 7.03767 9.43135C6.75484 9.30617 6.42746 9.12518 6.0518 8.91749L2.88455 7.16667C2.27302 6.82861 2.16458 6.84519 1.58447 7.16667Z" />
        <path d="M13.1377 10.5L9.91421 12.282C9.55765 12.4792 9.24211 12.6536 8.96677 12.7744C8.67107 12.9042 8.35502 13.0034 7.99603 12.9998C7.63985 12.9963 7.3237 12.8922 7.02676 12.7599C6.74674 12.6351 6.42257 12.4558 6.05079 12.2503L2.88455 10.5C2.27301 10.162 2.16458 10.1785 1.58447 10.5C0.862136 10.9003 0.500977 11.1158 0.500977 11.3312C0.500977 11.571 0.948331 11.8109 1.84302 12.3054L6.42506 14.8383C8.01132 15.7152 8.01141 15.7152 9.54121 14.8693C9.55966 14.8591 9.57833 14.8488 9.59724 14.8383L14.1793 12.3054C15.8509 11.3814 15.9368 11.3337 14.4375 10.5C13.857 10.1773 13.7494 10.1619 13.1377 10.5Z" />
      </g>
    </Icon>
  );
};

export default LayersIcon;
