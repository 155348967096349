import { Box, LinearProgress, Typography } from "@mui/material";
import { FC } from "react";
import { useAppSelector } from "~/store/hooks";
import { SearchSlice } from "~/store/Search";

const SearchStatus: FC = () => {
  const state = useAppSelector(SearchSlice.selectState);
  const result = useAppSelector(SearchSlice.selectResult);
  const isLoading = useAppSelector(SearchSlice.isLoading);

  return (
    <>
      {!!result.length && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{result.length} results</Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        {!!state && <div>{state}</div>}
        <Box flex={1}>{isLoading && <LinearProgress />}</Box>
      </Box>
    </>
  );
};

export default SearchStatus;
