import { Checkbox } from "@mui/material";
import {
  useCallback,
  useMemo,
  useState,
  type FC,
  type ChangeEventHandler,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "~/ui-kit/Button";
import styles from "./FieldsOfStudiesFilter.module.scss";
import Scrollbars from "react-custom-scrollbars-2";
import Angle from "~/icons/Angle";
import { EventObject } from "~/ui-kit/EventObject";
import { observer } from "mobx-react-lite";
import { useAppState } from "~/state";

const FieldsOfStudiesFilter: FC = () => {
  const { title } = useStaticMarkupTexts();
  const { filters } = useAppState();
  const handleFieldOfStudyToggle = useFieldOfStudyToggleHandler();
  const handleApply = useApplyFilterHandler();
  const [parentItemsStates, handleParentItemStateToggle] =
    useListParentItemsStates();

  return (
    <section className={styles.filter}>
      <header>
        <h1 className={styles.title}>{title}</h1>
      </header>
      <Scrollbars>
        <ul>
          {Object.entries(filters.fieldsOfStudiesPossibleValues).map(
            ([fieldOfStudyId, parentFieldOfStudy]) => (
              <li key={fieldOfStudyId}>
                <p className={styles.item}>
                  <Checkbox
                    id={fieldOfStudyId}
                    checked={
                      !!filters.fieldsOfStudiesSelectedValues[fieldOfStudyId]
                    }
                    indeterminate={parentFieldOfStudy.children.some(
                      (item) => filters.fieldsOfStudiesSelectedValues[item.id],
                    )}
                    name={fieldOfStudyId}
                    onChange={handleFieldOfStudyToggle}
                  />
                  {!!parentFieldOfStudy.children.length && (
                    <Button
                      shape="icon"
                      name={fieldOfStudyId}
                      onClick={handleParentItemStateToggle}
                    >
                      <Angle
                        className={styles.shevron}
                        style={{
                          transform: parentItemsStates[fieldOfStudyId]
                            ? "rotate(0)"
                            : "rotate(-90deg)",
                        }}
                      />
                    </Button>
                  )}
                  <label htmlFor={fieldOfStudyId} className={styles.label}>
                    {parentFieldOfStudy.display_name}
                  </label>
                </p>
                {!!parentFieldOfStudy.children.length &&
                  parentItemsStates[fieldOfStudyId] && (
                    <ul>
                      {parentFieldOfStudy.children.map((fieldOfStudy) => (
                        <li key={fieldOfStudy.id} className={styles.item}>
                          <Checkbox
                            id={fieldOfStudy.id}
                            checked={
                              !!(
                                filters.fieldsOfStudiesSelectedValues[
                                  fieldOfStudy.id
                                ] ||
                                filters.fieldsOfStudiesSelectedValues[
                                  parentFieldOfStudy.id
                                ]
                              )
                            }
                            name={`${fieldOfStudy.id},${fieldOfStudyId}`}
                            onChange={handleFieldOfStudyToggle}
                          />
                          <label
                            className={styles.label}
                            htmlFor={fieldOfStudy.id}
                          >
                            {fieldOfStudy.display_name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
              </li>
            ),
          )}
        </ul>
      </Scrollbars>
      <Button
        className={styles.applyButton}
        color="primary"
        onClick={handleApply}
      >
        <div className={styles.text}>
          <p>Apply</p>
          <p>{filters.fieldsOfStudiesCounter} fields</p>
        </div>
      </Button>
    </section>
  );
};

export default observer(FieldsOfStudiesFilter);

function useStaticMarkupTexts() {
  return useMemo(
    () => ({
      title: "Choose fields of studies",
    }),
    [],
  );
}

function useFieldOfStudyToggleHandler() {
  const { filters } = useAppState();

  return useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const [id, parentId] = e.target.name.split(",");

      filters.toggleFieldOfStudy(id, parentId, e.target.checked);
    },
    [filters],
  );
}

function useApplyFilterHandler() {
  const { filters } = useAppState();
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(() => {
    history.push({
      pathname,
      search: filters.queryString,
    });
  }, [filters.queryString, history, pathname]);
}

function useListParentItemsStates(): [
  Record<string, boolean>,
  (e: EventObject<boolean>) => void,
] {
  const { filters } = useAppState();
  const [parentItemsStates, setParentItemsStates] = useState<
    Record<string, boolean>
  >(() =>
    Object.keys(filters.fieldsOfStudiesPossibleValues).reduce<
      Record<string, boolean>
    >((state, fieldOfStudyId) => {
      state[fieldOfStudyId] = false;

      return state;
    }, {}),
  );
  const handleParentItemStateToggle = useCallback(
    (e: EventObject<boolean>) => {
      setParentItemsStates({
        ...parentItemsStates,
        [e.meta.name!]: !parentItemsStates[e.meta.name!],
      });
    },
    [parentItemsStates],
  );

  return [parentItemsStates, handleParentItemStateToggle];
}
