import { Box, Stack } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import CustomChip from "../common/chip";
import AreaChartComponent from "../charts/AreaChart";
import styled from "@emotion/styled";
import { clsx } from "clsx";

const SearchResultWrapper = styled(Stack)`
  padding: 24px 16px;
  flex: 1;
  min-width: 0;

  &.zero-cluster {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    border-left: none;
    padding-left: 24px;
    margin: 12px 0;
  }

  padding-left: 40px;
  border-left: 1px solid rgba(0, 48, 120, 0.1);

  .percentage,
  .count {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;

    color: #749c00;
  }

  .count {
    color: rgba(0, 0, 0, 0.42);
    display: flex;
    align-items: center;
    font-weight: 400;
    &:before {
      margin: 0 8px;
      content: "";
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.12);
    }
  }
`;

const SearchResult = ({
  active,
  fos_list,
  name,
  works,
  growth,
  plot_data,
  zeroCluster = false,
}) => {
  const foses = fos_list[0].slice(0, 2);

  return (
    <SearchResultWrapper
      className={clsx({ active: active, "zero-cluster": zeroCluster })}
      spacing={2}
      direction="row"
      display="flex"
    >
      <Box style={{ width: "80px" }}>
        <AreaChartComponent plot_data={plot_data} />
      </Box>
      <Box display="flex">
        <Stack spacing={0} flexShrink={1}>
          <span>{name}</span>
          <Box display="flex">
            {growth && <div className="percentage">{Math.floor(growth)} %</div>}
            <div className="count">{works}</div>
          </Box>
          {!zeroCluster && (
            <Stack spacing={1} mt="8px" flexShrink={1} direction="row">
              {foses.map((i) => (
                <CustomChip size="small" label={i} />
              ))}
            </Stack>
          )}
        </Stack>
      </Box>
    </SearchResultWrapper>
  );
};

export default SearchResult;
