import React, { useEffect, useMemo, useCallback } from "react";
import useStore from "../services/store/store";
import ArticleOverlay from "./ArticleOverlay";
import AuthorsOverlay from "./AuthorsOverlay";
import EmailsOverlay from "./EmailsOverlay";
import FieldsOverlay from "./FieldsOverlay";
import FieldsOverlayTypes from "./FieldsOverlayTypes";
import HistoryOverlay from "./HistoryOverlay";
import LoginOverlay from "./LoginOverlay";
import PeriodOverlay from "./PeriodOverlay";
import PeriodOverlayTypes from "./PeriodOverlayTypes";
import SortClusters from "./SortClusters";
import SortMain from "./SortMain";
import TypesOverlay from "./TypesOverlay";
import BasicDialog from "./Basic";
import Work from "../components/Work";
import { useLocation, useHistory } from "react-router-dom";
import AuthorsList from "../components/AuthorsList";
import {
  FieldsOfStudiesFilter,
  SourcesFilter,
  AuthorsFilter,
  PeriodFilter,
} from "~/components/Filters";

const Overlays = ({ open }) => {
  const openOverlays = useStore((state) => state.openOverlays);
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  const closeOpenOverlays = useStore((state) => state.closeOpenOverlays);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const handleClose = useCloseFilterHandler();

  useEffect(() => {
    closeOpenOverlays();
  }, [closeOpenOverlays, pathname]);

  useEffect(() => {
    const activeWork = query.get("work");

    activeWork ? setOpenOverlay({ Work: activeWork }) : closeOpenOverlays();
  }, [closeOpenOverlays, query, setOpenOverlay]);

  return (
    <>
      {openOverlays["FIELDS"] && (
        <FieldsOverlay
          open={openOverlays["FIELDS"]}
          overlayType="FIELDS"
          title={"Select field of study"}
        />
      )}
      {openOverlays["ARTICLES_FIELDS"] && (
        <FieldsOverlayTypes
          open={openOverlays["ARTICLES_FIELDS"]}
          overlayType="ARTICLES_FIELDS"
          title={"Select field of study"}
        />
      )}
      {openOverlays["ARTICLES_TYPES"] && (
        <TypesOverlay
          open={openOverlays["ARTICLES_TYPES"]}
          overlayType="ARTICLES_TYPES"
          title={"Select work type"}
        />
      )}
      {openOverlays["ARTICLES_PERIOD"] && (
        <PeriodOverlayTypes
          open={openOverlays["ARTICLES_PERIOD"]}
          overlayType="ARTICLES_PERIOD"
          title={"Period"}
        />
      )}
      {openOverlays["SORT_MAIN"] && (
        <SortMain
          open={openOverlays["SORT_MAIN"]}
          overlayType="SORT_MAIN"
          title={"Sort"}
        />
      )}
      {openOverlays["SORT_ClUSTERS"] && (
        <SortClusters
          open={openOverlays["SORT_ClUSTERS"]}
          overlayType="SORT_ClUSTERS"
          title={"Sort"}
        />
      )}
      {openOverlays["PERIOD"] && (
        <PeriodOverlay
          open={openOverlays["PERIOD"]}
          overlayType="PERIOD"
          title={"Period"}
        />
      )}
      {openOverlays["AUTHORS"] && (
        <AuthorsOverlay
          open={openOverlays["AUTHORS"]}
          overlayType="AUTHORS"
          title={"Authors"}
          size={"sm"}
        />
      )}
      {openOverlays["Authors"] && (
        <BasicDialog
          open={openOverlays["Authors"]}
          overlayType="Authors"
          title="Authors"
          size="sm"
        >
          <AuthorsList id={openOverlays["Authors"].id} />
        </BasicDialog>
      )}
      {openOverlays["EMAILS"] && (
        <EmailsOverlay
          open={openOverlays["EMAILS"]}
          overlayType="EMAILS"
          title={"Emails"}
          size={"sm"}
        />
      )}
      {openOverlays["ARTICLE"] && (
        <ArticleOverlay
          open={openOverlays["ARTICLE"]}
          overlayType="ARTICLE"
          title={""}
          size={"sm"}
        />
      )}
      {openOverlays["Work"] && (
        <BasicDialog
          open={openOverlays["Work"]}
          onClose={() => {
            query.delete("work");
            history.push({
              pathname,
              search: query.toString(),
            });
          }}
          overlayType="Work"
          title=""
          size="sm"
        >
          <Work id={openOverlays["Work"]} />
        </BasicDialog>
      )}
      {openOverlays["FieldsOfStudiesFilter"] && (
        <BasicDialog
          open={openOverlays["FieldsOfStudiesFilter"]}
          overlayType="FieldsOfStudiesFilter"
          onClose={handleClose.fieldsOfStudies}
        >
          <FieldsOfStudiesFilter />
        </BasicDialog>
      )}
      {openOverlays["SourcesFilter"] && (
        <BasicDialog
          open={openOverlays["SourcesFilter"]}
          overlayType="SourcesFilter"
          onClose={handleClose.sources}
          style={{ height: "calc(100vh/2)" }}
        >
          <SourcesFilter />
        </BasicDialog>
      )}
      {openOverlays["AuthorsFilter"] && (
        <BasicDialog
          open={openOverlays["AuthorsFilter"]}
          overlayType="AuthorsFilter"
          onClose={handleClose.authors}
          style={{
            height: "calc(100vh/2)",
            margin: "0 -24px",
          }}
          size="md"
        >
          <AuthorsFilter />
        </BasicDialog>
      )}
      {openOverlays["PeriodFilter"] && (
        <BasicDialog
          open={openOverlays["PeriodFilter"]}
          overlayType="PeriodFilter"
          onClose={handleClose.period}
        >
          <PeriodFilter />
        </BasicDialog>
      )}
      {openOverlays["HISTORY"] && (
        <HistoryOverlay
          open={openOverlays["HISTORY"]}
          overlayType="HISTORY"
          title={"Search history"}
        />
      )}
      {openOverlays["LOGIN"] && (
        <LoginOverlay
          open={openOverlays["LOGIN"]}
          overlayType="LOGIN"
          size="xs"
          title={"Log In"}
        />
      )}
    </>
  );
};

export default Overlays;

function useCloseFilterHandler() {
  return {
    fieldsOfStudies: useCallback(() => {}, []),
    sources: useCallback(() => {}, []),
    authors: useCallback(() => {}, []),
    period: useCallback(() => {}, []),
  };
}
