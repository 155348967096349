import { type FC } from "react";

interface CursorProps {
  x: number;
  height: number;
}

export const Cursor: FC<CursorProps> = ({ x, height }) => {
  return (
    <line x1={x} x2={x} y1={0} y2={height} stroke="black" strokeWidth={1} />
  );
};

export default Cursor;
