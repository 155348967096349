import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ContactType,
  getAuthorById,
  getAuthorsByAuthorId,
  getAuthorsByClusterId,
  getAuthorsByInstitutionId,
  updateAuthorContactsById,
} from "../api";
import type {
  PaginatedRequestParams,
  PaginatedResponse,
} from "../api/response.types";
import type { Author, AuthorId } from "../../domain/Author/Author.types";
import type { ClusterId } from "../../domain/Cluster/Cluster.types";
import { RootState } from "../store";
import { InstitutionId } from "../../domain/Institution/Institution.types";

const authorsAdapter = createEntityAdapter<Author>();

const Authors = createSlice({
  name: "authors",
  initialState: authorsAdapter.getInitialState<{
    fetchOneLoading: boolean;
    fetchOneError: string | null;
    fetchManyLoading: boolean;
    fetchManyError: string | null;
    updateContacts: {
      id: AuthorId | null;
      loading: boolean;
      error: string | null;
    };
  }>({
    fetchOneLoading: false,
    fetchOneError: null,
    fetchManyLoading: false,
    fetchManyError: null,
    updateContacts: {
      id: null,
      loading: false,
      error: null,
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuthorsByClusterId.fulfilled, (state, { payload }) => {
      if (payload.page === 1) {
        authorsAdapter.setAll(state, payload.items);
      } else {
        authorsAdapter.addMany(state, payload.items);
      }
    });
    builder.addCase(fetchAuthorsByAuthorId.pending, fetchManyLoadingReducer);
    builder.addCase(fetchAuthorsByAuthorId.fulfilled, fetchManySuccessReducer);
    builder.addCase(fetchAuthorsByAuthorId.rejected, fetchManyFailReducer);
    builder.addCase(
      fetchAuthorsByInstitutionId.pending,
      fetchManyLoadingReducer,
    );
    builder.addCase(
      fetchAuthorsByInstitutionId.fulfilled,
      fetchManySuccessReducer,
    );
    builder.addCase(fetchAuthorsByInstitutionId.rejected, fetchManyFailReducer);
    builder.addCase(fetchAuthorById.fulfilled, (state, { payload }) => {
      authorsAdapter.setAll(state, [payload]);
      state.fetchOneLoading = false;
      state.fetchOneError = null;
    });
    builder.addCase(fetchAuthorById.pending, (state) => {
      state.fetchOneLoading = true;
      state.fetchOneError = null;
    });
    builder.addCase(fetchAuthorById.rejected, (state, { payload }) => {
      state.fetchOneLoading = false;
      // @ts-expect-error
      state.fetchOneError = payload;
    });
    builder.addCase(updateContactsByAuthorId.pending, (state, { meta }) => {
      state.updateContacts.id = meta.arg.id;
      state.updateContacts.loading = true;
      state.updateContacts.error = null;
    });
    builder.addCase(
      updateContactsByAuthorId.fulfilled,
      (state, { payload }) => {
        state.updateContacts.id = null;
        state.updateContacts.loading = false;
        state.updateContacts.error = null;
        authorsAdapter.setOne(state, payload);
      },
    );
    builder.addCase(updateContactsByAuthorId.rejected, (state, { payload }) => {
      state.updateContacts.loading = false;
      // @ts-expect-error
      state.updateContacts.error = payload;
    });
  },
});

function fetchManyLoadingReducer(state: RootState["Authors"]) {
  state.fetchManyLoading = true;
  state.fetchManyError = null;
}

// @ts-expect-error
function fetchManyFailReducer(state: RootState["Authors"], { payload }) {
  state.fetchManyLoading = false;
  state.fetchManyError = payload;
}

function fetchManySuccessReducer(
  state: RootState["Authors"],
  { payload }: PayloadAction<PaginatedResponse<Author>>,
) {
  authorsAdapter.addMany(state, payload.items);
  state.fetchManyLoading = false;
  state.fetchManyError = null;
}

const selectors = authorsAdapter.getSelectors(
  (state: RootState) => state.Authors,
);

export default Authors.reducer;

const fetchAuthorsByClusterId = createAsyncThunk(
  "authors/fetchByClusterId",
  async (params: PaginatedRequestParams<ClusterId>, { rejectWithValue }) => {
    try {
      const { data } = await getAuthorsByClusterId(params);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const fetchAuthorsByAuthorId = createAsyncThunk(
  "coauthors/fetchByAuthorId",
  async (params: PaginatedRequestParams<AuthorId>, { rejectWithValue }) => {
    try {
      const { data } = await getAuthorsByAuthorId(params);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

const fetchAuthorsByInstitutionId = createAsyncThunk(
  "authors/fetchByInstitutionId",
  async (
    params: PaginatedRequestParams<InstitutionId>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await getAuthorsByInstitutionId(params);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err);
      }

      throw err;
    }
  },
);

const fetchAuthorById = createAsyncThunk(
  "author/fetchById",
  async (id: AuthorId, { rejectWithValue }) => {
    try {
      const { data } = await getAuthorById(id);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

interface UpdateAuthorContactsParams {
  id: AuthorId;
  contactType: ContactType;
}

const updateContactsByAuthorId = createAsyncThunk(
  "author/updateContactsByAuthorId",
  async (params: UpdateAuthorContactsParams, { rejectWithValue }) => {
    try {
      const { data } = await updateAuthorContactsById(
        params.id,
        params.contactType,
      );

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

const selectTopCoauthors = createSelector(selectors.selectAll, (authors) =>
  authors.slice(1, 6),
);

const selectAll = (state: RootState) => selectors.selectAll(state);

const selectAuthorById = (id: AuthorId) => (state: RootState) =>
  selectors.selectById(state, id) || null;

const selectAuthorRequestLoadingState = (state: RootState) =>
  state.Authors.fetchOneLoading;

const selectFetchOneError = (state: RootState) => state.Authors.fetchOneError;

const selectLoadingState = (state: RootState) => state.Authors.fetchManyLoading;

const selectFetchManyError = (state: RootState) => state.Authors.fetchManyError;

const selectUpdateContactsLoadingState =
  (id: AuthorId) => (state: RootState) =>
    state.Authors.updateContacts.id === id
      ? state.Authors.updateContacts.loading
      : false;

const selectUpdateContactsError = (id: AuthorId) => (state: RootState) =>
  state.Authors.updateContacts.id === id
    ? state.Authors.updateContacts.error
    : null;

export const AuthorsState = {
  fetchAuthorsByClusterId,
  fetchAuthorsByAuthorId,
  fetchAuthorsByInstitutionId,
  fetchAuthorById,
  updateContactsByAuthorId,
  selectTopCoauthors,
  selectAuthorById,
  selectAuthorRequestLoadingState,
  selectFetchOneError,
  selectAll,
  selectLoadingState,
  selectFetchManyError,
  selectUpdateContactsLoadingState,
  selectUpdateContactsError,
};
