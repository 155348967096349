import React, { useEffect, useState } from 'react';
import { fetchFoses } from '../services/api';
import useStore from '../services/store/store';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import clsx from 'clsx';


const FieldsList = styled.ul`
  margin: 0;
  padding: 16px;
  list-style: none;
  ul {
    list-style: none;
    margin: 0;
    margin-left: 16px;
    padding: 0;
  }

   .active {
    color: #005BFF;
  }

  .icon-wrapper {
    padding: 10px 0;
  }
`;

const FieldsOverlayTypes = (props) => {

  const [foses, setFoses] = useStore((state) => [state.foses, state.setFoses]);
  const [selectedFoses, setSelectedFoses] = useStore((state) => [state.selectedFosesTypes, state.setSelectedFosesTypes]);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    const initOverlay = async () => {
      const res = await fetchFoses();
      setFoses(res.data);
    };

    initOverlay();
  }, []);

  const handleToggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter(i => i !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const isOpen = (id) => open.includes(id);

  const hasSelectedChildren = (item) => item.children.some((i) => selectedFoses.includes(i.id));

  return (
    <BasicDialog {...props}>
      <FieldsList>
        {foses.map(i => (
          <li>
            <Stack direction="row">
            <FormControlLabel
            onChange={(e) => setSelectedFoses(i)}
            checked={selectedFoses.includes(i.id)} key={i.id} control={<Checkbox />}
            label={i.display_name}
                className={clsx({ active: hasSelectedChildren(i) })}
            />
              {i.children && i.children.length && (
              <IconButton sx={{ flexShrink: '0' }} onClick={() => handleToggle(i.id)} aria-label="delete" size="small">
                {!isOpen(i.id) && <KeyboardArrowRightIcon fontSize="inherit" />}
                {isOpen(i.id) && <KeyboardArrowDownIcon fontSize="inherit" />}
              </IconButton>
              )}
            </Stack>
            {i.children && i.children.length && isOpen(i.id)? (
              <ul>
                {i.children.sort((a, b) => a.display_name.localeCompare(b.display_name)).map(child => (
                  <li>
                    <FormControlLabel
                      onChange={(e) => setSelectedFoses(child)}
                      checked={selectedFoses.includes(child.id)} key={child.id} control={<Checkbox />}
                      label={child.display_name}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </FieldsList>
    </BasicDialog>
  )
};

export default FieldsOverlayTypes;
