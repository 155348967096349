import {
  useCallback,
  type FC,
  type ReactNode,
  type MouseEventHandler,
} from "react";
import { ButtonBase } from "@mui/material";
import cn from "classnames";

import styles from "./Button.module.scss";
import { EventObject } from "../EventObject";

interface ButtonProps {
  className?: string;
  children?: ReactNode;
  color?: "primary" | "secondary";
  onClick?: (event: EventObject<boolean>) => void;
  disabled?: boolean;
  name?: string;
  accent?: "light";
  shape?: "square" | "icon";
}

const Button: FC<ButtonProps> = ({
  className,
  children,
  color = "secondary",
  onClick,
  disabled,
  name,
  shape,
  accent,
}) => {
  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      onClick?.(new EventObject(true, { name: e.currentTarget.name }));
    },
    [onClick],
  );

  return (
    <ButtonBase
      className={cn(
        styles.uiKit,
        className,
        styles.button,
        styles[color],
        disabled && styles.disabled,
        shape && styles[shape],
        accent && styles[accent],
      )}
      onClick={handleClick}
      disabled={disabled}
      name={name}
    >
      {children}
    </ButtonBase>
  );
};

export default Button;
