import { useCallback, useEffect, useState, type FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import InstitutionInfo from "./InstitutionInfo";
import InstitutionAuthors from "./InstitutionAuthors";

import InterestsChart from "../../components/InterestsChart";
import Filters from "../../components/Filters";

import {
  useFosFilterSetup,
  useSetFiltrationScope,
} from "../../store/ActiveFilter/hooks";

import type { InstitutionId } from "../../domain/Institution/Institution.types";

import styles from "./InstitutionView.module.scss";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { Alert, CircularProgress } from "@mui/material";
import { InterestsTimelineState } from "~/store/ResearchersInterestsTimeline";
import { EventObject } from "~/ui-kit/EventObject";
import { FieldsOfStudyLevel } from "~/store/ResearchersInterestsTimeline/effects";
import { useAppState } from "~/state";
import { observer } from "mobx-react-lite";
import Works from "~/components/Works";

interface InstitutionViewParams {
  id: InstitutionId;
}

function useInstitutionInterests(id: InstitutionId) {
  const dispatch = useAppDispatch();
  const interestsTimeline = useAppSelector(
    InterestsTimelineState.selectTimelineByResearcherId(id),
  );
  const isLoading = useAppSelector(InterestsTimelineState.selectLoading);
  const error = useAppSelector(
    InterestsTimelineState.selectErrorByResearcherId(id),
  );
  const [level, setLevel] = useState<FieldsOfStudyLevel>(0);
  useFosFilterSetup();

  useEffect(() => {
    if (!interestsTimeline)
      dispatch(
        InterestsTimelineState.fetchInstitutionInterestsTimeline({
          id,
          level: 0,
        }),
      );
  }, [dispatch, id, interestsTimeline]);

  return {
    interestsTimeline,
    isLoading,
    error,
    fieldsOfStudyLevel: level,
    fetchInterestsTimeline: useCallback(
      (e: EventObject<FieldsOfStudyLevel>) => {
        setLevel(e.value);
        dispatch(
          InterestsTimelineState.fetchInstitutionInterestsTimeline({
            id,
            level: e.value,
          }),
        );
      },
      [dispatch, id],
    ),
  };
}

const InstitutionView: FC = () => {
  const { id } = useParams<InstitutionViewParams>();
  useSetFiltrationScope("institutions");
  const {
    interestsTimeline,
    isLoading,
    error,
    fetchInterestsTimeline,
    fieldsOfStudyLevel,
  } = useInstitutionInterests(id);
  const { search } = useLocation();
  const { filters } = useAppState();

  useEffect(() => {
    filters.init(null, null, "institution", id, new URLSearchParams(search));
  }, [filters, id, search]);

  return (
    <main className={styles.view}>
      <aside className={styles.info}>
        <InstitutionInfo id={id} />
      </aside>
      <section className={styles.content}>
        <div>
          {isLoading && <CircularProgress />}
          {!!error && <Alert severity="error">{error}</Alert>}
          {!isLoading && !error && interestsTimeline && (
            <InterestsChart
              worksByYears={interestsTimeline}
              onFieldsOfStudyLevelChange={fetchInterestsTimeline}
              fieldsOfStudyLevel={fieldsOfStudyLevel}
            />
          )}
        </div>
        <Filters />
        <div className={styles.filterResults}>
          <div className={styles.works}>
            <Works scope="institution" scopeValueId={id} />
          </div>
          <InstitutionAuthors className={styles.coauthors} id={id} />
        </div>
      </section>
    </main>
  );
};

export default observer(InstitutionView);
