import { type FC, type HTMLProps, type PropsWithChildren } from "react";
import cn from "classnames";

import styles from "./Paper.module.scss";

const Paper: FC<PropsWithChildren<HTMLProps<HTMLElement>>> = ({
  children,
  className,
  style,
}) => {
  return (
    <section className={cn(styles.paper, className)} style={style}>
      {children}
    </section>
  );
};

export default Paper;
