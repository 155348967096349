import React from "react";
import cn from "classnames";
import SearchResult from "./SearchRestult";
import styled from "@emotion/styled";
import { Skeleton, Stack } from "@mui/material";
import { clsx } from "clsx";
import { NavLink, useRouteMatch } from "react-router-dom";
import styles from "./Clusters.module.scss";

const SearchResultList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px -16px;

  li {
    cursor: pointer;
    padding: 0 16px;
    display: flex;
  }

  a > li {
    &.active {
      background: rgba(0, 150, 255, 0.08);
    }
  }
`;

const Clusters = ({
  clusterId,
  onSelectCluster,
  list = [],
  loading = false,
}) => {
  const { url } = useRouteMatch();

  return (
    <SearchResultList>
      {list.map((i, index) => (
        <NavLink
          key={i.id}
          to={`${url}/cluster/${i.id}`}
          activeClassName="active"
          className={styles.cluster}
        >
          <li
            className={clsx({ active: clusterId === i.id })}
            onClick={() => onSelectCluster(index)}
          >
            <SearchResult
              className={cn(index === 0 && "zero-cluster")}
              plot_data={i.plot_data}
              works={i.works_count}
              name={i.name}
              zeroCluster={index === 0}
              growth={i.growth}
              fos_list={[i.fos_list]}
              active={clusterId === i.id}
            />
          </li>
        </NavLink>
      ))}
      {loading && (
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={90} />
          <Skeleton variant="rectangular" width={"100%"} height={90} />
          <Skeleton variant="rectangular" width={"100%"} height={90} />
        </Stack>
      )}
    </SearchResultList>
  );
};

export default Clusters;
