import { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Cite: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <g className={styles.shape}>
        <path d="M12 10.2596C12.3034 10.2596 12.5998 10.2879 12.8864 10.3419C14.9472 10.7303 16.5 12.4465 16.5 14.5048C16.5 16.8494 14.4853 18.75 12 18.75C9.51473 18.75 7.5 16.8494 7.5 14.5048C7.5 12.1602 9.51473 10.2596 12 10.2596Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.2561 12.1572L12.2519 5.88029C12.7114 5.23002 13.6427 5.0543 14.332 5.48781C15.0213 5.92133 15.2076 6.79989 14.748 7.45016C14.748 7.45016 13.6135 9.21261 12.8865 10.3419C11.0782 11.0508 8.2561 12.1572 8.2561 12.1572Z"
        />
      </g>
    </Icon>
  );
};

export default Cite;
