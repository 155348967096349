import { DialogProps } from "@mui/material";
import Dialog from "./Dialog/Dialog";
import { useModal as useReactModal } from "react-modal-hook";
import Cross from "~/icons/Cross";
import styles from "./Dialog/Dialog.module.scss";
import Paper from "./Paper";
import React from "react";
import Button from "./Button";

export default function useModal(
  render: (
    close: ReturnType<typeof useReactModal>[1],
  ) => DialogProps["children"],
  options?: Omit<DialogProps, "children" | "open">,
  disabled?: boolean,
  deps?: React.DependencyList | undefined,
) {
  const isDisabled = disabled === undefined ? false : disabled;
  const dependencyList = deps ? [...deps] : [];

  const [handleOpen, handleClose] = useReactModal(
    () => (
      <Dialog
        className={styles.dialog}
        fullWidth
        maxWidth="md"
        PaperComponent={Paper}
        PaperProps={{ className: styles.container }}
        onClose={() => !isDisabled && handleClose()}
        {...options}
        open
      >
        <Button
          className={styles.closeButton}
          shape="icon"
          disabled={isDisabled}
          onClick={handleClose}
        >
          <Cross />
        </Button>
        {render(handleClose)}
      </Dialog>
    ),
    [dependencyList],
  );

  return handleOpen;
}
