import axios from "axios";
import { flow, makeAutoObservable } from "mobx";
import type { Author, AuthorId } from "~/domain/Author/Author.types";
import type { ClusterId } from "~/domain/Cluster/Cluster.types";
import type {
  FieldOfStudy,
  FieldOfStudyId,
} from "~/domain/FieldOfStudy/FieldOfStudy.types";
import type {
  Institution,
  InstitutionId,
} from "~/domain/Institution/Institution.types";
import type { Source, SourceId } from "~/domain/Sources/Sources.types";
import { getFieldsOfStudies } from "~/store/api";
import { BASE_URL } from "~/store/api/const";
import { PaginatedResponse } from "~/store/api/response.types";
import { FilteredRequestParams } from "../works";
import Period from "./Period";
import QueryString from "qs";
import { TaskId } from "~/domain/SearchTask/SearchTask.types";
import { FolderId } from "~/widgets/Folder/Folder.state";

type FilterContext = "author" | "institution" | "search";
type FilterContextValueId = AuthorId | InstitutionId | ClusterId;
type Subfolder = "authors" | "institution" | "clusters";
type ObjectTypeDTO = "author" | "institution" | "cluster";

function mapFilterContextWithSubfolder(context: FilterContext): Subfolder {
  if (context === "author") return "authors";
  if (context === "institution") return "institution";
  if (context === "search") return "clusters";

  throw new Error("Unknown context for mapping");
}

function mapFilterContextWithObjectTypeDTO(
  context: FilterContext | null,
): ObjectTypeDTO {
  if (context === "author") return "author";
  if (context === "institution") return "institution";
  if (context === "search") return "cluster";
  if (context === null) throw new Error("Context doesn't set");

  throw new Error("Unknown context for mapping");
}

export const MIN_PERIOD_YEAR = 2014;
export const MAX_PERIOD_YEAR = 2023; // 2024

type FilterScope = "task" | "monitoring";
type FilterScopeValueId = TaskId | FolderId;

export class FiltersState {
  private context: FilterContext | null = null;
  private contextValueId: FilterContextValueId | null = null;
  private scope: FilterScope | null = null;
  private scopeValueId: FilterScopeValueId | null = null;
  private fieldsOfStudies: Record<FieldOfStudyId, true> = {};
  private _fieldsOfStudiesPossibleValues: Record<FieldOfStudyId, FieldOfStudy> =
    {};
  private sources: Record<SourceId, true> = {};
  private _sourcesPossibleValues: Array<Source> = [];
  private authors: Record<AuthorId, true> = {};
  private _authorsPossibleValues: Array<Author> = [];
  private authorsPossibleValuesPage: number = 1;
  private _authorsTotalPossibleValues: number = 0;
  private institutions: Record<InstitutionId, true> = {};
  private _institutionsPossibleValues: Array<Institution> = [];
  private _institutionsTotalPossibleValues: number = 0;
  private institutionsPossibleValuesPage: number = 1;
  private searchPattern: string = "";
  private sort: { type: string; direction: "asc" | "desc" } | null = null;
  private favouriteId: string | null = null;
  periodFilter = Period;

  constructor() {
    makeAutoObservable(this);
  }

  async init(
    scope: FilterScope | null,
    scopeValueId: FilterScopeValueId | null,
    context: FilterContext,
    contextValueId: FilterContextValueId,
    searchParams: URLSearchParams,
  ) {
    this.setFromURLSearchParams(searchParams);
    this.context = context;
    this.contextValueId = contextValueId;
    this.scope = scope;
    this.scopeValueId = scopeValueId;
    await this.periodFilter.init(context, contextValueId);

    await Promise.allSettled([
      this.checkIsFavourite(),
      this.fetchFieldsOfStudiesPossibleValues(),
      this.fetchSourcesPossibleValues(context, contextValueId),
      this.fetchAuthorsPossibleValues(context, contextValueId),
      this.fetchInstitutionsPossibleValues(context, contextValueId),
    ]);
  }

  private async fetchFieldsOfStudiesPossibleValues() {
    const { data } = await getFieldsOfStudies();

    this.fieldsOfStudiesPossibleValues = data.reduce<
      Record<FieldOfStudyId, FieldOfStudy>
    >((possibleValues, fieldOfStudy) => {
      possibleValues[fieldOfStudy.id] = fieldOfStudy;

      return possibleValues;
    }, {});
  }

  get fieldsOfStudiesSelectedValues() {
    return this.fieldsOfStudies;
  }

  toggleFieldOfStudy(
    id: FieldOfStudyId,
    parentId: FieldOfStudyId | null,
    isSelected: boolean,
  ) {
    if (isSelected) this.fieldsOfStudies[id] = true;
    else delete this.fieldsOfStudies[id];

    if (parentId) {
      const subItems =
        this.fieldsOfStudiesPossibleValues[parentId]?.children || [];

      if (this.fieldsOfStudiesSelectedValues[parentId]) {
        delete this.fieldsOfStudiesSelectedValues[parentId];

        subItems.forEach((item) => {
          if (item.id !== id) {
            this.fieldsOfStudiesSelectedValues[id] = true;
          }
        });
      } else {
        if (
          subItems.every((item) => this.fieldsOfStudiesSelectedValues[item.id])
        ) {
          this.fieldsOfStudiesSelectedValues[parentId] = true;

          subItems.forEach(
            (item) => delete this.fieldsOfStudiesSelectedValues[item.id],
          );
        } else if (
          subItems.some((item) => this.fieldsOfStudiesSelectedValues[item.id])
        ) {
          delete this.fieldsOfStudiesSelectedValues[parentId];
        }
      }
    } else {
      const subItems = this.fieldsOfStudiesPossibleValues[id]?.children || [];

      subItems.forEach(
        (item) => delete this.fieldsOfStudiesSelectedValues[item.id],
      );
    }

    this.checkIsFavourite();
  }

  get fieldsOfStudiesQueryString() {
    return Object.entries(this.fieldsOfStudiesSelectedValues).reduce<
      Array<FieldOfStudyId>
    >((filterQuery, [fieldOfStudyId, fieldOfStudyState]) => {
      if (fieldOfStudyState) {
        filterQuery.push(fieldOfStudyId);
      }

      return filterQuery;
    }, []);
  }

  get fieldsOfStudiesPossibleValues() {
    return this._fieldsOfStudiesPossibleValues;
  }

  set fieldsOfStudiesPossibleValues(value) {
    this._fieldsOfStudiesPossibleValues = value;
  }

  async fetchSourcesPossibleValues(
    context: FilterContext,
    contextValueId: FilterContextValueId,
  ) {
    const url = `${BASE_URL}/${mapFilterContextWithSubfolder(context)}/${contextValueId}/venues`;

    const { data } = await axios.get<Array<Source>>(url);

    this.sourcesPossibleValues = data;
  }

  get sourcesSelectedValues() {
    return this.sources;
  }

  toggleSource(id: SourceId, isSelected: boolean) {
    if (isSelected) this.sources[id] = true;
    else delete this.sources[id];

    this.checkIsFavourite();
  }

  get sourcesQueryString() {
    return Object.entries(this.sourcesSelectedValues).reduce<Array<SourceId>>(
      (filterQuery, [sourceId, sourceState]) => {
        if (sourceState) {
          filterQuery.push(sourceId);
        }

        return filterQuery;
      },
      [],
    );
  }

  get sourcesPossibleValues() {
    return this._sourcesPossibleValues;
  }

  set sourcesPossibleValues(value) {
    this._sourcesPossibleValues = value;
  }

  get curFilObj(): FilteredRequestParams {
    return {
      ...(this.fieldsOfStudiesQueryString
        ? { foses: this.fieldsOfStudiesQueryString }
        : {}),
      ...(this.sourcesQueryString ? { sources: this.sourcesQueryString } : {}),
      ...(this.authorsQueryString ? { authors: this.authorsQueryString } : {}),
      ...(this.institutionsQueryString
        ? { institutions: this.institutionsQueryString }
        : {}),
      ...(this.periodFilter.value.from
        ? { year_from: `${this.periodFilter.value.from}` }
        : {}),
      ...(this.periodFilter.value.to
        ? { year_to: `${this.periodFilter.value.to}` }
        : {}),
    } as FilteredRequestParams;
  }

  get queryString() {
    return QueryString.stringify(
      {
        ...(this.fieldsOfStudiesQueryString
          ? { foses: this.fieldsOfStudiesQueryString }
          : {}),
        ...(this.sourcesQueryString
          ? { sources: this.sourcesQueryString }
          : {}),
        ...(this.authorsQueryString
          ? { authors: this.authorsQueryString }
          : {}),
        ...(this.institutionsQueryString
          ? { institutions: this.institutionsQueryString }
          : {}),
        ...(this.periodFilter.value.from
          ? { year_from: `${this.periodFilter.value.from}` }
          : {}),
        ...(this.periodFilter.value.to
          ? { year_to: `${this.periodFilter.value.to}` }
          : {}),
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
      },
    );
  }

  async fetchAuthorsPossibleValues(
    context: FilterContext,
    contextValueId: FilterContextValueId,
    page: number = 1,
  ) {
    if (this.context === null || this.contextValueId === null)
      throw new Error("Context is not defined");

    const url = [
      BASE_URL,
      mapFilterContextWithSubfolder(this.context),
      this.contextValueId,
      this.context === "author" ? "co_authors" : "authors",
    ].join("/");

    const { data } = await axios.get<PaginatedResponse<Author>>(url, {
      params: { page },
    });

    this.authorsPossibleValues = data.items;
    this.authorsTotalPossibleValues = data.total;
  }

  async uploadAuthorsPossibleValues(page: number) {
    if (this.context === null || this.contextValueId === null)
      throw new Error("Context is not defined");

    this.fetchAuthorsPossibleValues(this.context, this.contextValueId, page);
  }

  get authorsPossibleValues() {
    return this._authorsPossibleValues;
  }

  get authorsTotalPossibleValues() {
    return this._authorsTotalPossibleValues;
  }

  set authorsPossibleValues(value) {
    this._authorsPossibleValues.push(...value);
  }

  get authorsSelectedValues() {
    return this.authors;
  }

  toggleAuthor(id: AuthorId, isSelected: boolean) {
    if (isSelected) this.authors[id] = true;
    else delete this.authors[id];

    this.checkIsFavourite();
  }

  set authorsTotalPossibleValues(value) {
    this._authorsTotalPossibleValues = value;
  }

  async fetchInstitutionsPossibleValues(
    context: FilterContext,
    contextValueId: FilterContextValueId,
    page: number = 1,
  ) {
    const url = [
      BASE_URL,
      mapFilterContextWithSubfolder(context),
      contextValueId,
      "institutions",
    ].join("/");

    const { data } = await axios.get<PaginatedResponse<Institution>>(url, {
      params: { page },
    });

    this.institutionsPossibleValues = data.items;
  }

  async uploadInstitutionsPossibleValues(page: number) {
    if (this.context === null || this.contextValueId === null)
      throw new Error("Context is not defined");

    this.fetchInstitutionsPossibleValues(
      this.context,
      this.contextValueId,
      page,
    );
  }

  get institutionsPossibleValues() {
    return this._institutionsPossibleValues;
  }

  get institutionsTotalPossibleValues() {
    return this._institutionsTotalPossibleValues;
  }

  set institutionsPossibleValues(value) {
    this._institutionsPossibleValues.push(...value);
  }

  get institutionsSelectedValues() {
    return this.institutions;
  }

  toggleInstitution(id: InstitutionId, isSelected: boolean) {
    if (isSelected) this.institutions[id] = true;
    else delete this.institutions[id];

    this.checkIsFavourite();
  }

  set institutionsTotalPossibleValues(value) {
    this._institutionsTotalPossibleValues = value;
  }

  get allAuthorsSelectedValues() {
    return {
      ...this.authorsSelectedValues,
      ...this.institutionsSelectedValues,
    };
  }

  get allAuthorsPossibleValues() {
    return [...this.authorsPossibleValues, ...this.institutionsPossibleValues];
  }

  get authorsQueryString() {
    return Object.entries(this.authors).reduce<Array<AuthorId>>(
      (filterQuery, [authorId, authorState]) => {
        if (authorState) {
          filterQuery.push(authorId);
        }

        return filterQuery;
      },
      [],
    );
  }

  get institutionsQueryString() {
    return Object.entries(this.institutions).reduce<Array<InstitutionId>>(
      (filterQuery, [institutionId, institutionState]) => {
        if (institutionState) {
          filterQuery.push(institutionId);
        }

        return filterQuery;
      },
      [],
    );
  }

  getAuthorsPossibleValuesByType(type: "person" | "institution") {
    if (type === "person") return this.authorsPossibleValues;
    if (type === "institution") return this.institutionsPossibleValues;

    throw new Error("Unknown author type");
  }

  getAuthorsTotalPossibleValuesByType(type: "person" | "institution") {
    if (type === "person") return this.authorsTotalPossibleValues;
    if (type === "institution") return this.institutionsTotalPossibleValues;

    throw new Error("Unknown author type");
  }

  getAuthorsPageByType(type: "person" | "institution") {
    if (type === "person") return this.authorsPossibleValuesPage;
    if (type === "institution") return this.institutionsPossibleValuesPage;

    throw new Error("Unknown author type");
  }

  getAuthorsSelectedValuesByType(type: "person" | "institution") {
    if (type === "person") return this.authorsSelectedValues;
    if (type === "institution") return this.institutionsSelectedValues;

    throw new Error("Unknown author type");
  }

  toggleAuthorByType(
    type: "person" | "institution" | null,
    id: AuthorId | InstitutionId,
    isSelected: boolean,
  ) {
    if (type === null) {
      if (Reflect.has(this.authors, id)) this.toggleAuthor(id, isSelected);
      if (Reflect.has(this.institutions, id))
        this.toggleInstitution(id, isSelected);
      return;
    }
    if (type === "person") return this.toggleAuthor(id, isSelected);
    if (type === "institution") return this.toggleInstitution(id, isSelected);

    throw new Error("Unknown author type");
  }

  uploadAuthorsPossibleValuesByType(
    type: "person" | "institution",
    page: number,
  ) {
    if (type === "person") {
      this.authorsPossibleValuesPage = page;
      return this.uploadAuthorsPossibleValues(page);
    }
    if (type === "institution") {
      this.institutionsPossibleValuesPage = page;
      return this.uploadInstitutionsPossibleValues(page);
    }

    throw new Error("Unknown author type");
  }

  setFromURLSearchParams(searchParams: URLSearchParams) {
    this.fieldsOfStudies =
      searchParams
        .get("foses")
        ?.split(",")
        .reduce<Record<FieldOfStudyId, true>>(
          (selectedValues, fieldOfStudyId) => {
            selectedValues[fieldOfStudyId] = true;

            return selectedValues;
          },
          {},
        ) || {};
    this.sources =
      searchParams
        .get("sources")
        ?.split(",")
        .reduce<Record<SourceId, true>>((selectedValues, sourceId) => {
          selectedValues[sourceId] = true;

          return selectedValues;
        }, {}) || {};
    this.authors =
      searchParams
        .get("authors")
        ?.split(",")
        .reduce<Record<AuthorId, true>>((selectedValues, authorId) => {
          selectedValues[authorId] = true;

          return selectedValues;
        }, {}) || {};
    this.institutions =
      searchParams
        .get("institutions")
        ?.split(",")
        .reduce<Record<InstitutionId, true>>(
          (selectedValues, institutionId) => {
            selectedValues[institutionId] = true;

            return selectedValues;
          },
          {},
        ) || {};
  }

  printfieldOfStudiesLabel(label = "Fields of Studies") {
    const size = Object.keys(this.fieldsOfStudies).length;

    return size ? [label, size].join(": ") : label;
  }

  printSourcesLabel(label = "Sources") {
    const size = Object.keys(this.sources).length;

    return size ? [label, size].join(": ") : label;
  }

  printAuthorsLabel(label = "Authors") {
    return Object.keys(this.authors).length +
      Object.keys(this.institutions).length
      ? [
          label,
          Object.keys(this.authors).length +
            Object.keys(this.institutions).length,
        ].join(": ")
      : label;
  }

  get fieldsOfStudiesCounter() {
    return Object.keys(this.fieldsOfStudies).length;
  }

  get sourcesCounter() {
    return Object.keys(this.sources).length;
  }

  get authorsCounter() {
    return (
      Object.keys(this.authors).length + Object.keys(this.institutions).length
    );
  }

  get isFavourite() {
    return !!this.favouriteId;
  }

  convert() {
    return {
      year_from:
        this.periodFilter.value.from || this.periodFilter.minPossibleValue,
      year_to: this.periodFilter.value.to || this.periodFilter.maxPossibleValue,
      authors: Object.keys(this.authors),
      institutions: Object.keys(this.institutions),
      sources: Object.keys(this.sources),
      foses: Object.keys(this.fieldsOfStudies),
    };
  }

  async toggleFavourite() {
    if (this.isFavourite) {
      await axios.delete(`${BASE_URL}/favorite/${this.favouriteId}`);

      this.favouriteId = null;
    } else {
      const { data } = await axios.post(`${BASE_URL}/favorite`, {
        scope_id: this.scopeValueId,
        scope_type: this.scope,
        object_id: this.contextValueId,
        object_type: mapFilterContextWithObjectTypeDTO(this.context),
        filters: this.convert(),
      });

      this.favouriteId = data.id;
    }
  }

  checkIsFavourite = flow(function* (this: FiltersState) {
    const { data } = yield axios.post(`${BASE_URL}/favorite/check`, {
      scope_id: this.scopeValueId,
      scope_type: this.scope,
      object_id: this.contextValueId,
      object_type: mapFilterContextWithObjectTypeDTO(this.context),
      filters: this.convert(),
    });

    this.favouriteId = data.favorite;
  });

  static getFilterFromLocation(params: URLSearchParams) {
    return Object.fromEntries(params.entries());
  }
}

export default new FiltersState();
