import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TERMINAL_STATES } from "./const";

const selectSearch = (state: RootState) => state.Search;

export const selectTask = createSelector(selectSearch, (search) => search.task);

export const selectState = createSelector(
  selectSearch,
  (search) => search.state,
);

export const selectResult = createSelector(
  selectSearch,
  (search) => search.result,
);

export const selectFocusedCluster = createSelector(
  selectSearch,
  (search) =>
    search.focusedIndex !== null && search.result[search.focusedIndex],
);

export const isLoading = createSelector(
  selectState,
  (state) => !!state && !TERMINAL_STATES.includes(state),
);
