import { FC } from "react";
import styles from "./AuthorItem.module.scss";
import Email from "~/icons/Email";
import LinkedInSquare from "~/icons/LinkedInSquare";
import Document from "~/icons/Document";
import Quotes from "~/icons/Quotes";
import Portfolio from "~/icons/Portfolio";
import { AuthorItemProps } from "./types";

const AuthorItem: FC<AuthorItemProps> = (props) => {
  return (
    <div className={styles.item}>
      <div className={styles.hindex}>{props.hindex}</div>
      <div className={styles.content}>
        <div className={styles.title}>
          {!props.isPerson && <Portfolio />}
          <h3 className={styles.name}>{props.name}</h3>
          {!!props.from && <p className={styles.from}>{props.from}</p>}
        </div>
        <div className={styles.info}>
          <div className={styles.contacts}>
            <Email width={16} height={16} />
            <LinkedInSquare width={16} height={16} />
          </div>
          <span className={styles.lastActivePeriod}>
            {props.lastActivePeriod}
          </span>
          <p className={styles.works}>
            <Document width={12} height={12} />
            {props.worksCount}
          </p>
          <p className={styles.citations}>
            <Quotes width={12} height={12} />
            {props.citedByCount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthorItem;
