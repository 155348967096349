import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const BuildingIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M3.0803 0.57944C3.16297 0.496775 3.32921 0.497686 3.66169 0.499509C3.71482 0.4998 3.77236 0.500115 3.83431 0.500115C3.89626 0.500115 3.9537 0.4998 4.00683 0.499509C4.33932 0.497686 4.50565 0.496775 4.58832 0.57944C4.67098 0.662105 4.67007 0.828348 4.66825 1.16083C4.66796 1.214 4.66764 1.27143 4.66764 1.33345C4.66764 1.7828 4.66764 1.99116 4.7715 2.08718C4.86131 2.17019 5.02876 2.16921 5.341 2.16738C5.39058 2.16709 5.44382 2.16678 5.50098 2.16678C6.32645 2.16678 6.33431 2.16681 6.32645 3.00014C6.33431 3.83345 6.32645 3.83345 5.50106 3.83348C5.08439 3.83349 4.59741 3.97523 4.28673 4.28592C3.97605 4.59661 3.89715 4.9927 3.86546 5.27793C3.83209 5.57825 3.83305 5.88208 3.83401 6.18438C3.83416 6.23409 3.83432 6.28388 3.83432 6.33348C3.83432 7.16677 3.83432 7.16677 2.58432 7.16677C1.33433 7.16677 1.33431 7.16677 1.33431 5.50014V3.83345C1.33431 2.20626 1.33433 2.16678 2.16764 2.16678C2.22474 2.16678 2.27807 2.16709 2.32762 2.16738C3.00096 2.17133 3.00098 2.17133 3.00098 1.33345C3.00098 1.27143 3.00066 1.21401 3.00037 1.16083C2.99855 0.82835 2.99764 0.662105 3.0803 0.57944Z" />
        <path d="M12.1677 6.29129L12.1677 13.0002C12.1677 14.6668 12.1677 14.6668 13.8342 14.6668H13.864C14.6776 14.6668 15.0868 14.6669 15.2926 14.4623C15.501 14.2552 15.501 13.8385 15.501 13.0002V6.33351C15.501 4.32914 14.9225 3.86753 13.4872 2.72225L13.3135 2.58351C11.751 1.33348 10.5002 0.497775 10.0836 0.500152C9.69748 0.502353 9.66978 0.502511 9.6678 3.83348H9.70975C10.09 3.83344 10.4422 3.8334 10.7232 3.86463C11.0085 3.89633 11.4046 3.97523 11.7153 4.28592C12.0259 4.59661 12.1048 4.9927 12.1365 5.27793C12.1677 5.55894 12.1677 5.9111 12.1677 6.29129Z" />
        <path d="M6.33431 4.66681H9.66764C11.3343 4.66681 11.3343 4.66686 11.3343 6.33348L11.3344 13.1429C11.335 13.9231 11.3353 14.3049 11.1446 14.4913C10.9618 14.6699 10.6036 14.669 9.90199 14.6672C9.82778 14.667 9.74973 14.6668 9.66764 14.6668H2.16764C0.500977 14.6668 0.500969 14.6668 0.500977 13.0001V9.66681C0.500977 8.83518 0.500977 8.42063 0.708248 8.21301C0.914253 8.00666 1.325 8.00471 2.14399 8.00082L2.16764 8.00071C3.83431 7.99276 3.83431 8.00071 3.83431 9.66681V10.9168C3.83431 11.1469 4.02086 11.3335 4.25098 11.3335C4.4811 11.3335 4.66764 11.1469 4.66764 10.9168V6.33348C4.66764 4.66686 4.66764 4.66681 6.33431 4.66681ZM6.33431 7.16681C6.33431 7.62705 6.70741 8.00014 7.16764 8.00014H8.83431C9.29455 8.00014 9.66764 7.62705 9.66764 7.16681C9.66764 6.70657 9.29455 6.33348 8.83431 6.33348H7.16764C6.70741 6.33348 6.33431 6.70657 6.33431 7.16681ZM6.33431 10.5001C6.33431 10.9604 6.70741 11.3335 7.16764 11.3335H8.83431C9.29455 11.3335 9.66764 10.9604 9.66764 10.5001C9.66764 10.0399 9.29455 9.66681 8.83431 9.66681H7.16764C6.70741 9.66681 6.33431 10.0399 6.33431 10.5001Z" />
      </g>
    </Icon>
  );
};

export default BuildingIcon;
