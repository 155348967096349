import { FC, useEffect } from "react";
import monitoringViewStyles from "../Monitoring.module.scss";
import styles from "./FolderLayout.module.scss";
import { observer } from "mobx-react-lite";
import { useAppState } from "~/state";
import {
  NavLink,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React from "react";
import { Alert, Skeleton } from "@mui/material";
import Back from "~/icons/Back";
import FolderIcon from "~/icons/Folder";
import Dot from "~/icons/Dot";
import { FolderContent } from "~/widgets/Folder";
import ClusterView from "~/widgets/Cluster/View";

const FolderLayout: FC = () => {
  const { Folder } = useAppState();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    Folder.open(id);

    return () => {
      Folder.destroy();
    };
  }, [Folder, id]);

  let content: React.JSX.Element | null = (
    <Skeleton height={32} animation="wave" variant="rounded" />
  );

  if (!Folder.isLoading && Folder.folder) {
    content = (
      <>
        <header>
          <i>
            <NavLink to={"/monitoring"}>
              <Back />
            </NavLink>
            <Dot width={8} height={8} />
          </i>
          <h1>
            <FolderIcon />
            {Folder.folder.name}
            <span>{Folder.folder.count} clusters</span>
          </h1>
        </header>
        <FolderContent />
      </>
    );
  }

  if (Folder.fetchingError) {
    content = <Alert severity="error">{Folder.fetchingError}</Alert>;
  }

  const { path } = useRouteMatch();

  return (
    <main className={monitoringViewStyles.view}>
      <aside className={styles.folder}>{content}</aside>
      <section>
        <Switch>
          <Route
            exact
            path={`${path}/cluster/:clusterId`}
            component={ClusterView}
          />
        </Switch>
      </section>
    </main>
  );
};

export default observer(FolderLayout);
