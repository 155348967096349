import { useEffect, type FC } from "react";
import Card from "../../../ui-kit/Card";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { InstitutionsState } from "~/store/Institutions";
import { Alert, Box, CircularProgress } from "@mui/material";
import { InstitutionId } from "../../../domain/Institution/Institution.types";
import InfoCard from "~/components/InfoCard";

interface InstitutionInfoProps {
  id: InstitutionId;
}

function getStaticMarkupTexts() {
  return {
    raitings: "Raitings",
  };
}

function useInstitutionInfo(id: InstitutionId) {
  const dispatch = useAppDispatch();
  const institution = useAppSelector(InstitutionsState.selectById(id));
  const isLoading = useAppSelector(InstitutionsState.selectFetchOneLoading);
  const error = useAppSelector(InstitutionsState.selectFetchOneError(id));

  useEffect(() => {
    dispatch(InstitutionsState.fetchById(id));
  }, [dispatch, id]);

  return {
    institution,
    isLoading,
    error,
  };
}

const InstitutionInfo: FC<InstitutionInfoProps> = ({ id }) => {
  const { institution, isLoading, error } = useInstitutionInfo(id);
  const { raitings } = getStaticMarkupTexts();

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!institution) return null;

  return (
    <>
      <InfoCard
        id={id}
        photo={institution.photo}
        name={institution.display_name}
        articlesCount={institution.works_count}
        quotesCount={institution.cited_by_count}
        hIndex={institution.hindex}
      />
      <Card title={raitings} collapsible={true} disabled={true} />
    </>
  );
};

export default InstitutionInfo;
