import { type ChangeEventHandler, useCallback, useState, type FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { type Credentials, useAuth } from "~/services/Auth";
import styles from "./LoginView.module.scss";
import Paper from "~/ui-kit/Paper";

interface LoginViewProps {}

function useLoginHandler(credentials: Credentials) {
  const auth = useAuth();
  const location = useLocation<{ from: string }>();
  const { from } = location.state || { from: { pathname: "/" } };
  const history = useHistory();

  return useCallback(() => {
    auth.signin(credentials, () => {
      history.replace(from);
    });
  }, [auth, credentials, from, history]);
}

function useCredentialsState() {
  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });

  return {
    credentials,
    handleCredentialsChange: useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        setCredentials({
          ...credentials,
          [e.target.name]: e.target.value,
        });
      },
      [credentials],
    ),
  };
}

const LoginView: FC<LoginViewProps> = () => {
  const { credentials, handleCredentialsChange } = useCredentialsState();
  const handleLogin = useLoginHandler(credentials);

  return (
    <Paper className={styles.form}>
      <Grid xs={12} item>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <form className="form" action="">
            <Stack spacing={2} mb={"20px"}>
              <TextField
                value={credentials.username}
                onChange={handleCredentialsChange}
                name="username"
                placeholder="email"
                size="small"
              />
              <TextField
                value={credentials.password}
                onChange={handleCredentialsChange}
                placeholder="password"
                type={"password"}
                name="password"
                size="small"
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button variant="contained" color="primary" onClick={handleLogin}>
                Log in
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Paper>
  );
};

export default LoginView;
