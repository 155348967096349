import React, { FC } from "react";
import Markdown, { Options } from "react-markdown";
import Scrollbars from "react-custom-scrollbars-2";
import styles from "./MarkdownViewer.module.scss";
import { useHistory } from "react-router-dom";
// @ts-expect-error
import { createTask } from "~/services/api";

interface MarkdownViewerProps {
  children: Options["children"];
  subtopics?: Array<string> | null;
}

const MarkdownViewer: FC<MarkdownViewerProps> = ({ children, subtopics }) => {
  const history = useHistory();
  return (
    <section className={styles.viewer}>
      <Scrollbars>
        <Markdown className={styles.content}>{children}</Markdown>
        {!!subtopics && (
          <ul style={{ margin: 0 }}>
            {subtopics.map((search_query, i) => (
              <li style={{ color: "blue" }} key={i}>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    const model = {
                      search_query,
                      year_from: 2014,
                      year_to: new Date().getFullYear(),
                    };

                    const { data } = await createTask(model);

                    history.push(`/task/${data.id}`);
                  }}
                >
                  {search_query}
                </p>
              </li>
            ))}
          </ul>
        )}
      </Scrollbars>
    </section>
  );
};

export default MarkdownViewer;
