import { type FC } from "react";
import { observer } from "mobx-react-lite";
import styles from "./styles.module.scss";
import { useAppState } from "~/state";
import { NavLink, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import Button from "~/ui-kit/Button";
import Angle from "~/icons/Angle";
import { Skeleton } from "@mui/material";
import SecondLevelClusterList from "../SecondLevelList";

const FolderContentClusterList: FC = () => {
  const { Folder } = useAppState();
  const { url } = useRouteMatch();

  if (!Folder.clusters)
    return (
      <ul className={styles["folder-content__cluster-list"]}>
        {Array.from(Array(Folder.folder?.count).keys()).map((i) => (
          <Skeleton height={32} animation="wave" variant="rounded" key={i} />
        ))}
      </ul>
    );

  return (
    <ul className={styles["folder-content__cluster-list"]}>
      {Folder.clusters.map((cluster) => (
        <li key={cluster.id}>
          <div>
            <NavLink
              to={`${url}/cluster/${cluster.id}`}
              activeClassName={cn(styles.active)}
            >
              <p>{cluster.name}</p>
              <span
                className={cn(
                  styles.trend,
                  cluster.trend[Folder.trendBy] === 0 && styles.neutral,
                  cluster.trend[Folder.trendBy] > 0 && styles.positive,
                  cluster.trend[Folder.trendBy] < 0 && styles.negative,
                )}
              >
                {cluster.trend[Folder.trendBy]}
              </span>
            </NavLink>
            <Button
              className={styles["toggle-shevron"]}
              shape="square"
              onClick={() => Folder.toggleCluster(cluster.id)}
            >
              <Angle
                width={16}
                height={16}
                style={{
                  transform: Folder.isClusterOpen(cluster.id)
                    ? "rotate(180deg)"
                    : "",
                }}
              />
            </Button>
          </div>
          <SecondLevelClusterList id={cluster.id} />
        </li>
      ))}
    </ul>
  );
};

export default observer(FolderContentClusterList);
