import { type FC, useMemo, useRef } from "react";
import { animated, useSpring, to } from "react-spring";
import { interpolate } from "d3";

import styles from "../InterestsChart.module.scss";

interface AnimatedPathProps {
  path: string;
  color: string;
  dataFos: string;
}

interface AnimatedSpringProps {
  t: number;
}

const AnimatedPath: FC<AnimatedPathProps> = ({ path, color, dataFos }) => {
  const currentPathData = useRef(path);
  const pathInterpolator = useMemo(
    () => interpolate(currentPathData.current, path),
    [path],
  );
  const springProps = useSpring<AnimatedSpringProps>({
    from: { t: 0 },
    to: { t: 1 },
    reset: currentPathData.current !== path,
    onChange: ({ value }) => {
      currentPathData.current = pathInterpolator(value.t || 1);
    },
    config: {
      friction: 1,
      bounce: 0,
    },
  });

  return (
    <animated.path
      data-fos={dataFos}
      d={to(springProps.t, pathInterpolator)}
      className={styles.shape}
      opacity={1}
      stroke="grey"
      fill={color}
      fillOpacity={0.8}
      cursor="pointer"
    />
  );
};

export default AnimatedPath;
