import {
  CircularProgress,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/system";
import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
// @ts-expect-error
import AuthorsList from "~/AuthorsList/AuthorsList";
// @ts-expect-error
import Clusters from "~/clusters/clusters";
// @ts-expect-error
import SearchBar from "~/common/searchBar";
// @ts-expect-error
import MainText from "~/mainText/MainText";
// @ts-expect-error
import { createTask } from "~/services/api";
// @ts-expect-error
import useStore from "~/services/store/store";
// @ts-expect-error
import Trends from "~/trends/Trends";
import MarkdownViewer from "~/main/MarkdownViewer";
import Toggler from "~/ui-kit/Toggler";
import { CirclePackingChart } from "~/components/POCCirclePackgingChart";
import { Workspace } from "~/components/Workspace";
import { buildCirclePackingChartData } from "~/components/POCCirclePackgingChart/CirclePackingChart";
import { SearchSlice } from "~/store/Search";
import { useAppSelector } from "~/store/hooks";
import Filters from "~/components/Filters";
import { useAppState } from "~/state";
import Works from "~/components/Works";
import styles from "./styles.module.scss";
import SearchStatus from "~/widgets/Search/components/Status";

const VIEWS = {
  list: "As a list",
  chart: "On a map",
};

// @ts-expect-error
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Task = () => {
  // @ts-expect-error
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  // @ts-expect-error
  const [selectedFoses, selectedPeriod] = useStore((state) => [
    state.selectedFoses,
    state.period,
  ]);
  const { path, url } = useRouteMatch();
  const [view, setView] = useState("list");
  // @ts-expect-error
  const handleViewChange = useCallback((event) => setView(event.value), []);
  const params = useParams<{ id: string }>();
  // @ts-expect-error
  SearchSlice.useSearchStateTrackingEffect(params.id);
  // @ts-expect-error
  SearchSlice.useSearchResultTrackingEffect(params.id);
  SearchSlice.useResetOnDestroyEffect();
  const result = useAppSelector(SearchSlice.selectResult);
  const handleClusterFocus = SearchSlice.useClusterFocusHandler();
  const handleChartFocusChange = useCallback(
    // @ts-expect-error
    (event) => {
      // @ts-expect-error
      handleClusterFocus(result.findIndex((c) => c.id === +event.value));
    },
    [handleClusterFocus, result],
  );
  const state = useAppSelector(SearchSlice.selectState);
  const task = useAppSelector(SearchSlice.selectTask);
  const isLoading = useAppSelector(SearchSlice.isLoading);
  const focusedCluster = useAppSelector(SearchSlice.selectFocusedCluster);
  const { filters } = useAppState();
  const { search } = useLocation();
  const history = useHistory();

  const prevFocusedCluster = usePrevious(focusedCluster);

  useEffect(() => {
    const res = window.location.pathname.match(/\/task\/\d*\/\w*\/(\d*)$/);
    const cluster = (res && res[1]) || (focusedCluster && focusedCluster.id);

    if (prevFocusedCluster === false && cluster) {
      history.replace(
        `${url}/cluster/${cluster}?${new URLSearchParams(search).toString()}`,
      );
    }
  }, [focusedCluster]);

  useEffect(() => {
    if (!focusedCluster || !filters) return;

    filters.init(
      "task",
      params.id,
      "search",
      `${focusedCluster.id}`,
      new URLSearchParams(search),
    );
    // @ts-expect-error
  }, [filters, focusedCluster, focusedCluster?.id, params.id, search]);

  // @ts-expect-error
  const postSearch = async (search) => {
    const model = {
      search_query: search,
      fos_ids: selectedFoses,
      year_from: selectedPeriod.from,
      year_to: selectedPeriod.to,
    };

    await createTask(model);
  };
  const [tabValue, setTabValue] = useState(0);

  return (
    <main className={styles.view}>
      <aside>
        <Stack
          spacing={1}
          sx={{
            p: 2,
            background: "#fafafa",
            height: "100%",
            boxSizing: "border-box",
          }}
          display="flex"
          flex="1"
          flexDirection="column"
        >
          <SearchBar showHistory={false} onSearch={postSearch} small />
          <SearchStatus />
          <Toggler
            value={view}
            options={VIEWS}
            onChange={handleViewChange}
            disabled={isLoading || !task?.clusters_map}
          />
          <Box ml={"-16px"} mr={"-16px"}>
            <Clusters
              list={result}
              // @ts-expect-error
              clusterId={focusedCluster?.id}
              onSelectCluster={handleClusterFocus}
              loading={isLoading}
            />
          </Box>
        </Stack>
      </aside>
      <section>
        <Switch>
          <Route path={`${path}/cluster/:clusterId`}>
            {/* @ts-expect-error */}
            {view === "list" && focusedCluster?.id && (
              <>
                <Box sx={{ p: 2 }} display="flex" flexDirection="column">
                  <MainText selectedCluster={focusedCluster} />
                </Box>
                <Box>
                  <Grid container spacing={0}>
                    <Grid item xs={7} sx={{ p: 2 }}>
                      {/* @ts-expect-error */}
                      <MarkdownViewer subtopics={focusedCluster.subtopics}>
                        {/* @ts-expect-error */}
                        {focusedCluster.summary}
                      </MarkdownViewer>
                    </Grid>
                    <Grid item xs={5}>
                      <Box sx={{ p: 2 }} display="flex" flexDirection="column">
                        <Tabs
                          value={tabValue}
                          onChange={(e, newValue) => setTabValue(newValue)}
                          style={{ marginBottom: "8px" }}
                        >
                          <Tab label="Maturity" />
                          <Tab label="Potential" />
                          <Tab label="Trends" />
                        </Tabs>
                        <div>
                          {tabValue === 0 && (
                            <div
                              style={{
                                height: "220px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* @ts-expect-error */}
                              {focusedCluster.maturity_reasoning === null ? (
                                <CircularProgress />
                              ) : (
                                <MarkdownViewer>
                                  {/* @ts-expect-error */}
                                  {focusedCluster.maturity_reasoning}
                                </MarkdownViewer>
                              )}
                            </div>
                          )}
                        </div>
                        <div>
                          {tabValue === 1 && (
                            <p
                              style={{
                                height: "220px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* @ts-expect-error */}
                              {focusedCluster.potential_reasoning === null ? (
                                <CircularProgress />
                              ) : (
                                <MarkdownViewer>
                                  {/* @ts-expect-error */}
                                  {focusedCluster.potential_reasoning}
                                </MarkdownViewer>
                              )}
                            </p>
                          )}
                        </div>
                        <div>
                          {tabValue === 2 && (
                            <Trends selectedCluster={focusedCluster} />
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} padding="16px">
                      <Filters />
                    </Grid>
                    <Grid item xs={7}>
                      <Box sx={{ p: 2 }} display="flex" flexDirection="column">
                        <Works
                          scope="search"
                          // @ts-expect-error
                          scopeValueId={focusedCluster.id}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box sx={{ p: 2 }} display="flex" flexDirection="column">
                        {/* @ts-expect-error */}
                        <AuthorsList clusterId={focusedCluster.id} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {view === "chart" &&
              (state === "finished" || state === "partiallyFinished") && (
                <Box height="100%">
                  <Workspace>
                    <CirclePackingChart
                      // @ts-expect-error
                      data={buildCirclePackingChartData(task.clusters_map)}
                      // @ts-expect-error
                      focus={focusedCluster.id}
                      // @ts-expect-error
                      onFocus={handleChartFocusChange}
                      onClick={(e) => setOpenOverlay({ ARTICLE: e.value })}
                    />
                  </Workspace>
                </Box>
              )}
          </Route>
        </Switch>
      </section>
    </main>
  );
};

export default Task;
