import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createTask } from "../api";
// define the store

export const periodInitial = {
  from: 2014,
  to: 2023,
};

const defaultState = {
  openOverlays: {},
  foses: [],
  selectedFoses: [],
  period: periodInitial,
  selectedFosesTypes: [],
  periodTypes: periodInitial,
  selectedWorkTypes: [],
  searchValue: "",
  clusters: [],
  mainSort: "",
  clustersSort: "",
  user: null,
  search: "",
};

const store = (set) => ({
  ...defaultState,
  setMainSort: (val) => set(() => ({ mainSort: val })),
  setSearch: (val) => set(() => ({ search: val })),
  setClustersSort: (val) => set(() => ({ clustersSort: val })),
  setOpenOverlay: (val) =>
    set((state) => ({ openOverlays: { ...state.openOverlays, ...val } })),
  closeOpenOverlays: () => set((state) => ({ openOverlays: {} })),
  setFoses: (val) => set(() => ({ foses: val })),
  setUser: (val) => set(() => ({ user: val })),
  setSelectedFoses: (val) =>
    set((state) => {
      if (val.children && val.children.length) {
        if (state.selectedFoses.includes(val.id)) {
          const values = state.selectedFoses.filter(
            (id) => val.id !== id && !val.children.some((i) => i.id !== id),
          );
          return { selectedFoses: values };
        } else {
          return {
            selectedFoses: [
              ...new Set([
                ...state.selectedFoses,
                val.id,
                ...val.children.map((i) => i.id),
              ]),
            ],
          };
        }
      } else {
        if (state.selectedFoses.includes(val.id)) {
          return {
            selectedFoses: state.selectedFoses.filter((i) => i !== val.id),
          };
        } else {
          return { selectedFoses: [...state.selectedFoses, val.id] };
        }
      }
    }),
  setSelectedFosesTypes: (val) =>
    set((state) => {
      if (val.children && val.children.length) {
        if (state.selectedFosesTypes.includes(val.id)) {
          const values = state.selectedFosesTypes.filter(
            (id) => val.id !== id && !val.children.some((i) => i.id !== id),
          );
          return { selectedFosesTypes: values };
        } else {
          return {
            selectedFosesTypes: [
              ...new Set([
                ...state.selectedFosesTypes,
                val.id,
                ...val.children.map((i) => i.id),
              ]),
            ],
          };
        }
      } else {
        if (state.selectedFosesTypes.includes(val.id)) {
          return {
            selectedFosesTypes: state.selectedFosesTypes.filter(
              (i) => i !== val.id,
            ),
          };
        } else {
          return { selectedFosesTypes: [...state.selectedFosesTypes, val.id] };
        }
      }
    }),
  setSelectedWorkTypes: (val) =>
    set((state) => {
      if (state.selectedWorkTypes.includes(val)) {
        return {
          selectedWorkTypes: state.selectedWorkTypes.filter((i) => i !== val),
        };
      } else {
        return { selectedWorkTypes: [...state.selectedWorkTypes, val] };
      }
    }),
  setPeriodTypes: (val) =>
    set((state) => {
      return { periodTypes: { ...state.periodTypes, ...val } };
    }),
  setPeriod: (val) =>
    set((state) => {
      return { period: { ...state.period, ...val } };
    }),
  setSearchValue: (val) =>
    set((state) => {
      return { searchValue: val };
    }),
  fetchSearch: (val) =>
    set((state) => {
      const res = createTask({
        search_query: "string",
        fos_ids: state.foses,
        year_from: 0,
        year_to: 0,
      });
      return { clusters: [] };
    }),
  resetFilters: () =>
    set((state) => {
      return {
        selectedFosesTypes: defaultState.selectedFosesTypes,
        selectedWorkTypes: defaultState.selectedWorkTypes,
        mainSort: defaultState.mainSort,
        periodTypes: defaultState.periodTypes,
      };
    }),
});

const useStore = create(devtools(store));

export default useStore;
