import { createEntityAdapter, createSlice, type Draft } from "@reduxjs/toolkit";

import type { AuthorId } from "~/domain/Author/Author.types";
import type { InstitutionId } from "~/domain/Institution/Institution.types";

import type { RootState } from "~/store";

import {
  fetchAuthorInterestsTimeline,
  fetchInstitutionInterestsTimeline,
} from "./effects";

export type ResearcherId = AuthorId | InstitutionId;

type Year = string;

type FieldOfStudy = string;

type FieldsOfStudiesInterest = Record<FieldOfStudy, number>;

// type InterestsTimeline = Record<Year, FieldsOfStudiesInterest>;
type InterestsTimeline = {
  timeline: Array<{ year: number } & FieldsOfStudiesInterest>;
  foses: Array<string>;
};

type ResearcherInterestsTimeline = {
  id: ResearcherId;
  interestsTimeline: InterestsTimeline;
};

interface ResearchersInterestsState {
  loading: ResearcherId | null;
  errors: Record<ResearcherId, string> | null;
}

const researchersInterests = createEntityAdapter<ResearcherInterestsTimeline>();

export const selectors = researchersInterests.getSelectors(
  (state: RootState) => state.ResearchersInterestsTimeline,
);

const ResearchersInterests = createSlice({
  name: "researchersInterests",
  initialState: researchersInterests.getInitialState<ResearchersInterestsState>(
    {
      loading: null,
      errors: null,
    },
  ),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuthorInterestsTimeline.pending, (state, { meta }) => {
      loadingReducerMutation(state, meta.arg.id);
    });
    builder.addCase(
      fetchAuthorInterestsTimeline.fulfilled,
      (state, { payload, meta }) => {
        interestsTimelineReducerMutation(state, meta.arg.id, payload);
      },
    );
    builder.addCase(
      fetchAuthorInterestsTimeline.rejected,
      (state, { payload, meta }) => {
        errorReducerMutation(state, meta.arg.id, payload);
      },
    );
    builder.addCase(
      fetchInstitutionInterestsTimeline.pending,
      (state, { meta }) => {
        loadingReducerMutation(state, meta.arg.id);
      },
    );
    builder.addCase(
      fetchInstitutionInterestsTimeline.fulfilled,
      (state, { payload, meta }) => {
        interestsTimelineReducerMutation(state, meta.arg.id, payload);
      },
    );
    builder.addCase(
      fetchInstitutionInterestsTimeline.rejected,
      (state, { payload, meta }) => {
        errorReducerMutation(state, meta.arg.id, payload);
      },
    );
  },
});

export default ResearchersInterests.reducer;

function loadingReducerMutation(
  state: Draft<RootState["ResearchersInterestsTimeline"]>,
  id: ResearcherId,
) {
  state.loading = id;

  resetErrorMutation(state, id);
}

function errorReducerMutation(
  state: Draft<RootState["ResearchersInterestsTimeline"]>,
  id: ResearcherId,
  errorMessage?: string,
) {
  state.loading = null;

  if (!state.errors) state.errors = {};

  state.errors[id] = errorMessage || "Unhandled exception";
}

function interestsTimelineReducerMutation(
  state: Draft<RootState["ResearchersInterestsTimeline"]>,
  id: ResearcherId,
  interestsTimeline: InterestsTimeline,
) {
  state.loading = null;

  resetErrorMutation(state, id);

  researchersInterests.setOne(state, {
    id,
    interestsTimeline,
  } as ResearcherInterestsTimeline);
}

function resetErrorMutation(
  state: Draft<RootState["ResearchersInterestsTimeline"]>,
  id: ResearcherId,
) {
  if (state.errors && state.errors[id]) delete state.errors[id];

  if (state.errors && Object.keys(state.errors).length === 0)
    state.errors = null;
}
