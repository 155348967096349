interface Meta {
  name?: string;
  data?: any;
}

export class EventObject<Value = string> {
  value: Value;
  meta: Meta = {};

  constructor(value: Value, meta?: Meta) {
    this.value = value;

    if (meta) {
      this.meta = meta;
    }
  }
}
