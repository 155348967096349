import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const TrashBinIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M4.88831 3.03518L5.16356 2.20943C5.50385 1.18858 6.4592 0.5 7.53527 0.5H8.46607C9.54215 0.5 10.4975 1.18858 10.8378 2.20943L11.113 3.03518C13.3799 3.1246 14.6673 3.44123 14.6673 4.5625C14.6673 5.5 14.2507 5.5 13.4173 5.5H2.58398C1.75065 5.5 1.33398 5.5 1.33398 4.5625C1.33398 3.44122 2.62141 3.12459 4.88831 3.03518ZM6.7447 2.73648L6.65612 3.00224C7.08231 3 7.53089 3 8.00065 3C8.47043 3 8.91902 3 9.34523 3.00224L9.25664 2.73648C9.14322 2.39619 8.82477 2.16667 8.46607 2.16667H7.53527C7.17658 2.16667 6.85813 2.39619 6.7447 2.73648Z" />
        <path d="M2.16732 7.16667C2.16732 6.56667 2.58398 6.33333 3.00065 6.33333H13.0007C13.4173 6.33333 13.834 6.56771 13.834 7.16667C13.834 13.8333 13.4173 15.5 8.00065 15.5C2.58398 15.5 2.16732 13.8333 2.16732 7.16667ZM6.33398 8.83333C5.87375 8.83333 5.50065 9.20643 5.50065 9.66667V11.3333C5.50065 11.7936 5.87375 12.1667 6.33398 12.1667C6.79422 12.1667 7.16732 11.7936 7.16732 11.3333V9.66667C7.16732 9.20643 6.79422 8.83333 6.33398 8.83333ZM10.5007 9.66667C10.5007 9.20643 10.1276 8.83333 9.66732 8.83333C9.20708 8.83333 8.83398 9.20643 8.83398 9.66667V11.3333C8.83398 11.7936 9.20708 12.1667 9.66732 12.1667C10.1276 12.1667 10.5007 11.7936 10.5007 11.3333V9.66667Z" />
      </g>
    </Icon>
  );
};

export default TrashBinIcon;
