import styled from '@emotion/styled';
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

const ChartWrapper = styled.div`
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;

const AreaChartComponent = ({ plot_data }) => {

  const graphData = Object.keys(plot_data).map(key => ({
    name: key,
    publications: plot_data[key]
  }));

  return (
    <ChartWrapper>
      <AreaChart
        width={80}
        height={28}
        data={graphData}
        margin={{
          left: 0,
          bottom: 0
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgba(163, 212, 20, 1)" stopOpacity={1} />
            <stop offset="100%" stopColor="rgba(212, 147, 20, 1)" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area type="monotone" stroke="none" dataKey="publications" fill="url(#colorUv)" />
      </AreaChart>
    </ChartWrapper>
  );
}

export default AreaChartComponent;
