import { createAsyncThunk } from "@reduxjs/toolkit";

import type {
  FieldOfStudy,
  FieldOfStudyId,
} from "~/domain/FieldOfStudy/FieldOfStudy.types";

import { AppDispatch, RootState } from "../store";

import { getFieldsOfStudies } from "../api";

export const fetchFieldsOfStudies = createAsyncThunk<
  Record<FieldOfStudyId, FieldOfStudy>,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>("filter/fetchFieldsOfStudies", async (_arg, { rejectWithValue }) => {
  try {
    const { data } = await getFieldsOfStudies();

    return data.reduce<Record<FieldOfStudyId, FieldOfStudy>>(
      (res, fieldOfStudy) => {
        res[fieldOfStudy.id] = fieldOfStudy;

        return res;
      },
      {},
    );
  } catch (err) {
    if (err instanceof Error) {
      rejectWithValue(err.message);
    }

    throw err;
  }
});
