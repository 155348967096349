import { FC, useCallback } from "react";
import styles from "./AuthorsList.module.scss";
import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { AuthorsState } from "../../store/Authors";
import {
  ActiveFilterState,
  type FiltrationScope,
} from "../../store/ActiveFilter";
import { InstitutionId } from "../../domain/Institution/Institution.types";
import { AuthorId } from "../../domain/Author/Author.types";
import { ClusterId } from "../../domain/Cluster/Cluster.types";
import { useAppDispatch } from "../../store/hooks";
import Scrollbars from "react-custom-scrollbars-2";
import AuthorItem from "../AuthorItem";

interface AuthorsListProps {
  id: AuthorId | ClusterId | InstitutionId;
}

const AuthorsList: FC<AuthorsListProps> = ({ id }) => {
  const { authors, hasMore, handleAuthorsLoad } = useAuthors(id);

  return (
    <section className={styles.authors}>
      <header>
        <h1>Авторы</h1>
      </header>
      <Scrollbars>
        <InfiniteScroll
          element="ul"
          key={id}
          loadMore={handleAuthorsLoad}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <div key="loader" className={styles.loader}>
              <CircularProgress />
            </div>
          }
        >
          {authors.map((author) => (
            <AuthorItem
              key={author.id}
              id={author.id}
              citeCount={author.cited_by_count}
              name={author.display_name}
              hIndex={author.hindex}
              worksCount={author.works_count}
              linkedinUrl={author.linkedin_url}
              linkedinUpdateDate={author.linkedin_url_updated_date}
              emails={author.emails}
              institution={author.institution_name}
            />
          ))}
        </InfiniteScroll>
      </Scrollbars>
    </section>
  );
};

export default AuthorsList;

function useAuthorLoadHandlerAdapter(
  id: AuthorId | ClusterId | InstitutionId,
  scope: FiltrationScope | null
) {
  const handleAuthorLoadByScope: Record<
    FiltrationScope,
    (page: number) => void
  > = {
    authors: useCoauthorsLoadHandler(id as AuthorId),
    clusters: (page: number) => {},
    institutions: (page: number) => {},
  };

  return scope ? handleAuthorLoadByScope[scope] : (page: number) => {};
}

function useAuthors(id: AuthorId | ClusterId | InstitutionId) {
  const authors = useSelector(AuthorsState.selectAll);
  const totalAuthors = useSelector(ActiveFilterState.selectTotalAuthorsCount);
  const filtrationScope = useSelector(ActiveFilterState.selectScope);

  return {
    authors,
    hasMore: totalAuthors ? totalAuthors > authors.length : false,
    handleAuthorsLoad: useAuthorLoadHandlerAdapter(id, filtrationScope),
  };
}

function useCoauthorsLoadHandler(id: AuthorId) {
  const dispatch = useAppDispatch();

  return useCallback(
    (page: number) => {
      dispatch(
        AuthorsState.fetchAuthorsByAuthorId({
          id,
          page: `${page + 1}`,
          size: "15",
        })
      );
    },
    [dispatch, id]
  );
}
