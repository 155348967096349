export const AUTO_SCROLL: Record<
  string,
  (callback: (x: number, y: number) => void, x: number, y: number, temp: number) => void
> = {
  scrollTop: (cb, x, y, temp) => cb(x, y + temp),
  scrollBottom: (cb, x, y, temp) => cb(x, y - temp),
  scrollRight: (cb, x, y, temp) => cb(x - temp, y),
  scrollLeft: (cb, x, y, temp) => cb(x + temp, y),
  scrollTopRight: (cb, x, y, temp) => cb(x - temp, y + temp),
  scrollTopLeft: (cb, x, y, temp) => cb(x + temp, y + temp),
  scrollBottomRight: (cb, x, y, temp) => cb(x - temp, y - temp),
  scrollBottomLeft: (cb, x, y, temp) => cb(x + temp, y - temp),
};

export const WHEEL_MAX_STEP = 0.1;
