import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/system";
import * as React from "react";
import useStore from "../services/store/store";

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    border-radius: 32px;
  }
`;

const BasicDialog = ({
  className,
  title,
  onClose,
  open,
  size = "xs",
  children,
  overlayType,
  beforeClose,
  style,
}) => {
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);

  const handleClose = () => {
    if (beforeClose) {
      beforeClose();
    }
    setOpenOverlay({ [overlayType]: "" });
    onClose();
  };

  return (
    <StyledDialog
      fullWidth
      maxWidth={size}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "32px",
          boxShadow: "none",
          padding: "24px",
        },
      }}
      open={!!open}
    >
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography style={{ padding: 0 }} variant="h3">
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack
        style={{
          minHeight: "200px",
          maxHeight: "calc(100vh - 64px)",
          overflow: "auto",
          ...style,
        }}
      >
        {children}
      </Stack>
    </StyledDialog>
  );
};

export default BasicDialog;
