import React from 'react';
import AuthorsListItem from './ListItem';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;

  li {
    margin: 20px 0;
  }

`;

const List = ({ list, onUpdate, totalItems = 15, pageSize = 15, handleRequestPage = () => null }) => {
  return (
    <ListWrapper>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleRequestPage}
        hasMore={totalItems > list.length}
        useWindow={false}
        loader={<div className="loader" key={0}>Loading ...</div>}
      >
        {list.map((i) => (
          <li key={i.id}>
            <AuthorsListItem
              id={i.id}
              onUpdate={onUpdate}
              cited={i.cited_by_count}
              name={i.display_name}
              hindex={i.hindex}
              works={i.works_count}
              linkedin={i.linkedin_url}
              emails={i.emails}
              searchDate={i.linkedin_url_updated_date}
              institution={i.institution_name}
            />
        </li>
        ))}
      </InfiniteScroll>
    </ListWrapper>
  )
};

export default List;
