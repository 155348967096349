import React, { useEffect, useState } from 'react';
import { fetchAuthors, fetchFoses } from '../services/api';
import useStore from '../services/store/store';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '../AuthorsList/List';
import AuthorsList from '../AuthorsList/AuthorsList';
import { Box } from '@mui/system';
import Institutions from '../AuthorsList/Institutions/Institutions';


const FieldsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const pageSize = 15;

const ONLY_AUTHORS = 'ONLY_AUTHORS';
const GROUP_BY_ORG = 'GROUP_BY_ORG';

const AuthorsOverlay = (props) => {
  const { open: { clusterId } } = props;

  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState('');


  useEffect(() => {
    const initOverlay = async () => {
      console.log(filters.length);
      const res = await fetchAuthors(clusterId, { params: { page: 1, size: pageSize, no_middle: filters.includes(ONLY_AUTHORS) } });
      setResults(res.data);
    };

    if (clusterId) {
      initOverlay();
    }
  }, [clusterId, filters.length]);

  const onUpdate = (item) => {
    const list = [...results.items];
    list[list.findIndex(el => el.id === item.id)] = item;
    setResults({...results, items: list});
  }

  const onRequestPage = async (page) => {
    try {
      const { data } = await fetchAuthors(clusterId, { params: { page, size: pageSize, no_middle: filters.includes(ONLY_AUTHORS) } });
      const list = [...data.items];
      setResults({ ...results, items: [...results.items, ...list] });
    } catch (error) {
      console.log(error);
    }
  }

  const updateFilter = (val) => {
    if (filters.includes(val)) {
      setFilters(filters.filter(i => i !== val));
    } else {
      setFilters([...filters, val]);
    }
  }

  return (
    <BasicDialog {...props}>
      <Box sx={{ display: 'flex', position: 'sticky', top: 0, padding: '0 20px' }}>
        <FormControlLabel
          onChange={(e) => updateFilter('ONLY_AUTHORS')}
          checked={filters.includes('ONLY_AUTHORS')} key={'ONLY_AUTHORS'} control={<Checkbox />}
          label={'Only authors and curators'}
        />
        <FormControlLabel
          onChange={(e) => updateFilter('GROUP_BY_ORG')}
          checked={filters.includes('GROUP_BY_ORG')} key={'GROUP_BY_ORG'} control={<Checkbox />}
          label={'Group by organization'}
        />
      </Box>
      {!filters.includes(GROUP_BY_ORG) && <List list={results.items || []} pageSize={pageSize} handleRequestPage={onRequestPage} totalItems={results.total} onUpdate={onUpdate} />}
      {filters.includes(GROUP_BY_ORG) && <Institutions clusterId={clusterId} />}
    </BasicDialog>
  )
};

export default AuthorsOverlay;
