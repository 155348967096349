import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { trackResult, trackState } from "./effects";
import { SearchState } from "./types";
import { Task } from "~/domain/SearchTask/SearchTask.types";
import { Cluster } from "~/domain/Cluster/Cluster.types";

interface SearchStoreSlice {
  state: SearchState | null;
  task: Task | null;
  result: Array<Cluster>;
  focusedIndex: number | null;
}

const INITIAL_STATE: SearchStoreSlice = {
  state: null,
  task: null,
  result: [],
  focusedIndex: null,
};

const Search = createSlice({
  name: "search",
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    focus: (state, { payload }: PayloadAction<number>) => {
      state.focusedIndex = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(trackState.fulfilled, (state, { payload }) => {
      state.task = payload.task;
      state.state = payload.state;
    });
    builder.addCase(trackResult.fulfilled, (state, { payload }) => {
      if (payload.length === state.result.length) return;

      if (state.focusedIndex === null) {
        const res = window.location.pathname.match(/\/task\/\d*\/\w*\/(\d*)$/);
        if (res && res[1]) {
          state.focusedIndex = payload.findIndex((c) => {
            // @ts-expect-error
            return c.id === +res[1];
          });
        } else {
          state.focusedIndex = 0;
        }
      }

      state.result = payload;
    });
  },
});

export default Search.reducer;

export const actions = Search.actions;
