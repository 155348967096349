import { type FC } from "react";
import { NavLink } from "react-router-dom";

import { useAuth } from "~/services/Auth";
import UserMenu from "~/widgets/UserMenu";

import logo from "./logo-inverted.svg";
import styles from "./Header.module.scss";

const Header: FC = () => {
  const auth = useAuth();

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <figure className={styles.logo}>
          <img src={logo} alt="Explority.AI logo" />
          <figcaption className={styles.title}>
            <NavLink to="/">Explority</NavLink>
          </figcaption>
        </figure>
        {auth.isAuthorized ? (
          <>
            <nav className={styles["navigation-menu"]}>
              <NavLink
                to={"/search"}
                exact
                className={styles["navigation-item"]}
                activeClassName={styles.active}
              >
                Search
              </NavLink>
              <NavLink
                to={"/monitoring"}
                className={styles["navigation-item"]}
                activeClassName={styles.active}
              >
                Monitoring
              </NavLink>
            </nav>
            <UserMenu user={auth.getUserName()} />
          </>
        ) : (
          <NavLink className={styles["login-button"]} to="/login">
            Log In
          </NavLink>
        )}
      </div>
    </header>
  );
};

export default Header;
