import type { PropsWithChildren, FC } from "react";
import { Redirect, Route, type RouteProps } from "react-router-dom";
import { useAuth } from "~/services/Auth";

const PrivateRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...props
}) => {
  const auth = useAuth();

  return (
    <Route
      {...props}
      render={({ location }) => {
        return auth.isAuthorized ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
