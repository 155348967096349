import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Favorites: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="3x" viewBoxCoef="3x" {...props}>
      <g className={styles.shape}>
        <path d="M9.35739 6.13568C10.5303 4.04523 11.1168 3 11.9999 3C12.8831 3 13.4695 4.04523 14.6425 6.13568C14.9016 6.5975 15.1266 7.17359 15.5675 7.49022C15.9874 7.79183 16.5737 7.82158 17.0697 7.91185C19.4257 8.34069 20.6037 8.55512 20.9122 9.36897C20.9348 9.42847 20.9537 9.48933 20.9689 9.5512C21.1766 10.3975 20.3372 11.1321 18.6529 12.8637C18.1259 13.4055 17.8865 13.662 17.7812 14.0127C17.6836 14.3377 17.7306 14.6899 17.8246 15.3942C18.1477 17.8143 18.3066 19.1556 17.6145 19.7033C16.375 20.6843 14.3758 18.9619 13.256 18.4452C12.6377 18.16 12.3286 18 11.9999 18C11.6713 18 11.3621 18.16 10.7438 18.4452C9.62404 18.9619 7.62489 20.6843 6.3854 19.7033C5.69329 19.1556 5.85751 17.8143 6.18059 15.3942C6.27461 14.6899 6.32163 14.3377 6.22402 14.0127C6.1187 13.662 5.87925 13.4055 5.35225 12.8637C3.668 11.1321 2.82321 10.3975 3.03096 9.5512C3.04615 9.48933 3.06507 9.42847 3.08763 9.36897C3.39613 8.55512 4.57415 8.34069 6.93019 7.91185C7.42615 7.82157 8.01243 7.79183 8.43237 7.49022C8.87321 7.17359 9.09827 6.5975 9.35739 6.13568Z" />
      </g>
    </Icon>
  );
};

export default Favorites;
