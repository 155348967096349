import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

export default function App({ plot_data }) {

  const graphData = Object.keys(plot_data).map(key => ({
    name: key,
    publications: plot_data[key]
  }));

  return (
    <ResponsiveContainer width="100%" height={150}>
      <BarChart
        data={graphData}
        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        {/* <YAxis yAxisId="left" orientation="left" /> */}
        {/* <YAxis yAxisId="right" orientation="right" /> */}
        <Tooltip />
        <Bar yAxisId="left" barCategoryGap={30} dataKey="publications" fill="#aaaaaa" />
      </BarChart>
    </ResponsiveContainer>
  );
}
