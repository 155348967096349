import { observer } from "mobx-react-lite";
import React, { type FC } from "react";
import { useAppState } from "~/state";
import FoldersListItem from "./Item/FolderListItem";
import styles from "./styles.module.scss";
import { FolderListItemSkeleton } from "./Item";

const FolderList: FC = () => {
  const { Folders } = useAppState();

  // folders are not requested (equal `null`) or are loading
  let content: React.JSX.Element | null = (
    <ul className={styles["folder-list"]}>
      {Array.from(Array(10).keys()).map((i) => (
        <FolderListItemSkeleton key={i} />
      ))}
    </ul>
  );

  if (!Folders.isLoading && Folders.folders) {
    content = Folders.folders.length ? (
      <ul className={styles["folder-list"]}>
        {Folders.folders.map((folder) => (
          <FoldersListItem key={folder.id} {...folder} />
        ))}
      </ul>
    ) : null;
  }

  return <section>{content}</section>;
};

export default observer(FolderList);
