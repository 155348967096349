import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Folder: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="4x" viewBoxCoef="4x" {...props}>
      <g className={styles.shape}>
        <path d="M8.5 17.6664C8.5 10.1662 8.5 9.33301 11.8333 9.33301C15.1661 9.33301 15.5834 9.33301 16.7812 11.3643C16.938 11.6301 16.8467 11.833 16.4167 11.833C14.8574 11.833 13.9178 11.8776 12.8747 12.0195C12.1789 12.1 11.2917 12.1862 10.7452 12.6823C10.2259 13.1537 10.1667 13.8732 10.1667 14.7496V18.9162C10.1667 19.1463 10.3532 19.3329 10.5833 19.3329C10.8135 19.3329 11 19.1463 11 18.9162V14.7495C11 13.8388 11.0662 13.2182 12.0054 13.0005C14.0654 12.6431 16.6199 12.5182 19.2919 12.8711C22.2209 13.2579 23.5 13.4998 23.5 17.6663C23.5 21.8329 22.6667 22.6663 16 22.6663C9.33333 22.6663 8.5 21.8329 8.5 17.6664Z" />
      </g>
    </Icon>
  );
};

export default Folder;
