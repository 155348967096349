import styled from "@emotion/styled";
import { Button, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useToken from "../hooks/useToken";
import { fetchUser, postCredentials } from "../services/api";
import useStore from "../services/store/store";
import BasicDialog from "./Basic";

const Form = styled.form`
  width: 100%;
`;

const InitialForm = {
  username: "",
  password: "",
};

const LoginOverlay = (props) => {
  const [form, setForm] = useState(InitialForm);
  const [setOpenOverlay, setUser] = useStore((state) => [
    state.setOpenOverlay,
    state.setUser,
  ]);

  const { token, setToken } = useToken();
  const history = useHistory();

  const onLogin = async () => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("username", form.username);
      bodyFormData.append("password", form.password);
      bodyFormData.append("grant_type", "password");

      const res = await postCredentials(bodyFormData);
      setToken(res.data.access_token);
      const user = await fetchUser();
      setUser(user.data);
      history.go(0);
      setOpenOverlay({ LOGIN: "" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BasicDialog {...props}>
      <Grid container>
        <Grid xs={12} item>
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Form className="form" action="">
              <Stack spacing={2} mb={"20px"}>
                <TextField
                  value={form.username}
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                  placeholder="email"
                  size="small"
                />
                <TextField
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  placeholder="password"
                  type={"password"}
                  size="small"
                />
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Button variant="contained" color="primary" onClick={onLogin}>
                  Log in
                </Button>
              </Stack>
            </Form>
          </Stack>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

export default LoginOverlay;
