import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Bowser from "bowser";
import Paper from "../../ui-kit/Paper";
import styles from "./InterestsChart.module.scss";
import {
  Alert,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import * as d3 from "d3";
import AnimatedPath from "./AnimatedPath/AnimatedPath";
import Cursor from "./Cursor";
import type {
  FieldOfStudy,
  WorksByYear,
  YearFieldsOfStudies,
} from "~/domain/Work/Work.types";
import { EventObject } from "~/ui-kit/EventObject";
import { FieldsOfStudyLevel } from "~/store/ResearchersInterestsTimeline/effects";

type WorksByYearItem = { year: number } & YearFieldsOfStudies;

function getStreamChartKeys(worksByYear: WorksByYear): Array<FieldOfStudy> {
  const keys = new Set<FieldOfStudy>();

  Object.values(worksByYear).forEach((fieldsOfStudies) => {
    Object.keys(fieldsOfStudies).forEach((fieldOfStudy) => {
      keys.add(fieldOfStudy);
    });
  });

  return Array.from(keys).sort((a, b) => a.localeCompare(b));
}

function normalizeWorksByYearData(
  fieldsOfStudies: Array<FieldOfStudy>,
  worksByYear: WorksByYear,
  displayState: Record<FieldOfStudy, boolean>,
): [Array<FieldOfStudy>, Array<WorksByYearItem>] {
  // const data: Array<WorksByYearItem> = Object.entries(worksByYear).map(
  //   ([year, yearFieldsOfStudies]) => {
  //     const dataItem: WorksByYearItem = {
  //       x: +year,
  //     };

  //     fieldsOfStudies.forEach((fieldOfStudy) => {
  //       if (displayState[fieldOfStudy]) {
  //         dataItem[fieldOfStudy] = yearFieldsOfStudies[fieldOfStudy] || 0;
  //       }
  //     });

  //     return dataItem;
  //   },
  // );
  const data = worksByYear.timeline.map(({ year, ...yearFieldsOfStudies }) => {
    const dataItem = { year } as { year: number } & YearFieldsOfStudies;

    fieldsOfStudies.forEach((fieldOfStudy) => {
      if (displayState[fieldOfStudy]) {
        dataItem[fieldOfStudy] = yearFieldsOfStudies[fieldOfStudy] || 0;
      }
    });

    return dataItem;
  });

  const keys = fieldsOfStudies.filter(
    (fieldOfStudy) => displayState[fieldOfStudy],
  );

  return [keys, data];
}

function useStreamChartSeries(
  fieldsOfStudies: Array<FieldOfStudy>,
  worksByYear: WorksByYear,
  displayState: Record<FieldOfStudy, boolean>,
): [Array<WorksByYearItem>, Array<d3.Series<WorksByYearItem, string>>] {
  return useMemo(() => {
    const [keys, data] = normalizeWorksByYearData(
      fieldsOfStudies,
      worksByYear,
      displayState,
    );

    return [
      data,
      d3
        .stack<WorksByYearItem>()
        .keys(keys)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetSilhouette)(data),
    ];
  }, [displayState, fieldsOfStudies, worksByYear]);
}

function useStreamChartAreaBuilder(
  data: Array<WorksByYearItem>,
  series: Array<d3.Series<WorksByYearItem, string>>,
  width: number,
  height: number,
) {
  return useMemo(() => {
    const topYValues = series.flatMap((s) => s.map((d) => d[1]));
    const yMax = Math.max(...topYValues);

    const bottomYValues = series.flatMap((s) => s.map((d) => d[0]));
    const yMin = Math.min(...bottomYValues);

    const yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);

    const [xMin, xMax] = d3.extent(data, (d) => d.year);
    const xScale = d3
      .scaleLinear()
      .domain([xMin || 0, xMax || 0])
      .range([0, width]);

    return d3
      .area<any>()
      .x((d) => {
        return xScale(d.data.year);
      })
      .y1((d) => yScale(d[1]))
      .y0((d) => yScale(d[0]))
      .curve(d3.curveCardinal);
  }, [data, height, series, width]);
}

function getColors(categories: Array<string>): Array<string> {
  const sl = d3
    .scaleLinear(["#c7ebff", "#e0c7ff", "#ffc7c7", "#ffe9c7", "#b8eebd"])
    .interpolate(d3.interpolateHsl);

  const colors = [];

  for (let i = 0; i < categories.length; i++) {
    colors.push(sl(i / 5));
  }

  return colors;
}

interface InterestsChartProps {
  worksByYears: WorksByYear;
  fieldsOfStudyLevel: FieldsOfStudyLevel;
  onFieldsOfStudyLevelChange: (e: EventObject<FieldsOfStudyLevel>) => void;
}

const browser = Bowser.getParser(window.navigator.userAgent);

const InterestsChart: FC<InterestsChartProps> = ({
  worksByYears,
  fieldsOfStudyLevel,
  onFieldsOfStudyLevelChange,
}) => {
  const { title } = useStaticMarkupTexts();
  const [interactionData, setInteractionData] =
    useState<WorksByYearItem | null>(null);
  const timeline = useMemo(
    () =>
      worksByYears.timeline.map(({ year, ...works }, index) => ({
        value: (index / (Object.keys(worksByYears.timeline).length - 1)) * 100,
        label: (
          <div className={styles.mark}>
            <p className={styles.primaryLabel}>{year}</p>
            <p className={styles.secondaryLabel}>
              {Object.values(works).reduce((sum, worksAmount) => {
                sum += worksAmount;
                return sum;
              }, 0)}
            </p>
          </div>
        ),
      })),
    [worksByYears],
  );

  const value = useMemo(
    () => [timeline.at(0)!.value, timeline.at(-1)!.value],
    [timeline],
  );

  const keys = worksByYears.foses; // useMemo(() => getStreamChartKeys(worksByYears), [worksByYears]);
  const [displayState, setDisplayState] = useState<
    Record<FieldOfStudy, boolean>
  >(() =>
    keys.reduce(
      (state, key) => {
        state[key] = true;

        return state;
      },
      {} as Record<FieldOfStudy, boolean>,
    ),
  );
  const [hoveredFos, setHoveredFos] = useState<string | null>(null);
  const [data, series] = useStreamChartSeries(keys, worksByYears, displayState);
  const chartRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(250);
  const areaBuilder = useStreamChartAreaBuilder(data, series, width, height);
  const handleResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      entries.forEach((entry) => {
        const { width: newWidth, height: newHeight } =
          (entry && entry.contentRect) || {};

        if (newWidth !== width || newHeight !== height) {
          setWidth(newWidth);
          setHeight(newHeight);
        }
      });
    },
    [height, width],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldOfStudyToggle = useCallback<
    MouseEventHandler<HTMLLIElement>
  >(
    (e) => {
      if (e.target instanceof HTMLLIElement && e.target.dataset.name) {
        if (e.target.dataset.name === "all") {
          const newDisplayState: Record<string, boolean> = {};
          const selectedAmount = Object.values(displayState).reduce(
            (amount, state) => {
              return state ? (amount += 1) : amount;
            },
            0,
          );
          const unselectedAmount = keys.length - selectedAmount;

          if (unselectedAmount > selectedAmount) {
            keys.forEach((key) => (newDisplayState[key] = true));
          } else {
            keys.forEach((key) => (newDisplayState[key] = false));
          }

          setDisplayState(newDisplayState);
        } else {
          setDisplayState({
            ...displayState,
            [e.target.dataset.name]: !displayState[e.target.dataset.name],
          });
        }
      }
    },
    [displayState, keys],
  );

  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([data[0].year, data[data.length - 1].year])
        .range([0, width]),
    [data, width],
  );

  const getClosestPoint = useCallback(
    (cursorPixelPosition: number) => {
      const x = xScale.invert(cursorPixelPosition);

      let minDistance = Infinity;
      let closest: WorksByYearItem | null = null;

      for (const point of data) {
        const cursorYear = point.year;

        if (!cursorYear) {
          return null;
        }

        const distance = Math.abs(cursorYear - x);

        if (distance < minDistance) {
          minDistance = distance;
          closest = point;
        }
      }

      return closest;
    },
    [data, xScale],
  );

  const handleMouseMove = useCallback<MouseEventHandler<SVGRectElement>>(
    (e) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const closest = getClosestPoint(mouseX);

      if (e.target instanceof SVGPathElement && e.target.dataset.fos) {
        setHoveredFos(e.target.dataset.fos);
      }
      setInteractionData(closest);
    },
    [getClosestPoint],
  );

  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(keys)
    .range(getColors(keys));

  if (browser.getBrowserName() === "Safari") {
    const majorVersion = +browser.getBrowserVersion().split(".")[0];

    if (majorVersion < 17) {
      return <Alert severity="warning">Update your browser</Alert>;
    }
  }

  return (
    <Paper className={styles.chart}>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <ul className={styles.fieldsOfStudies}>
          {keys.length !== 0 && (
            <li
              data-name="all"
              className={styles.fieldOfStudy}
              onClick={handleFieldOfStudyToggle}
            >
              All
            </li>
          )}
          {keys.map((fieldOfStudy) => (
            <li
              key={fieldOfStudy}
              data-name={fieldOfStudy}
              className={styles.fieldOfStudy}
              style={{
                background:
                  (displayState[fieldOfStudy] && hoveredFos === fieldOfStudy) ||
                  (displayState[fieldOfStudy] && hoveredFos === null)
                    ? colorScale(fieldOfStudy)
                    : "#0000000A",
              }}
              onClick={handleFieldOfStudyToggle}
            >
              {fieldOfStudy}
              {interactionData && (
                <span>: {interactionData[fieldOfStudy]}</span>
              )}
            </li>
          ))}
        </ul>
        <FormControl size="small">
          <Select
            labelId="select-label"
            id="demo-select-small"
            value={fieldsOfStudyLevel}
            onChange={(e) => {
              onFieldsOfStudyLevelChange(
                new EventObject(+e.target.value as FieldsOfStudyLevel),
              );
            }}
          >
            <MenuItem value={0}>Zero</MenuItem>
            <MenuItem value={1}>First</MenuItem>
            <MenuItem value={2}>Second</MenuItem>
            <MenuItem value={3}>Third</MenuItem>
          </Select>
          <FormHelperText>Fields Of Study Level</FormHelperText>
        </FormControl>
      </header>
      <figure className={styles.body} style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            margin: "0 24px",
            width: "calc(100% - 48px)",
            height: "100%",
            background: `repeating-linear-gradient(
    90deg,
    ${Array(data.length)
      .fill(0, 0)
      .map((y, i) => {
        let color = i % 2 === 0 ? "transparent" : "#00000008";
        let start = i * (100 / (data.length - 1)) + "%";
        let end = i * (100 / (data.length - 1)) + 100 / (data.length - 1) + "%";

        return [[color, start].join(" "), [color, end].join(" ")];
      })
      .join(",")}
  )`,
          }}
        ></div>
        <svg ref={chartRef} height={height}>
          <g
            width={width}
            height={height}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setInteractionData(null)}
          >
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              visibility="hidden"
              pointerEvents="all"
              cursor="pointer"
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setInteractionData(null)}
            />
            <g
              className={styles.container}
              onMouseLeave={() => setHoveredFos(null)}
            >
              {series.map((serie, i) => {
                const path = areaBuilder(serie);

                if (!path) {
                  return null;
                }

                return (
                  <AnimatedPath
                    dataFos={serie.key}
                    key={i}
                    path={path}
                    color={colorScale(serie.key)}
                  />
                );
              })}
            </g>
            {interactionData && (
              <Cursor height={height} x={xScale(interactionData.year)} />
            )}
          </g>
        </svg>
        <footer className={styles.footer}>
          <Slider
            step={data.length}
            marks={timeline}
            value={value}
            onChange={() => {}}
          />
        </footer>
      </figure>
    </Paper>
  );
};

export default InterestsChart;

function useStaticMarkupTexts() {
  return {
    title: "Research interests",
  };
}
