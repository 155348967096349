import { AuthorsState } from "."; // @TODO
import { useAppSelector } from "../hooks";

export function useTopAuthors() {
  const persons = useAppSelector(AuthorsState.selectTopCoauthors);
  const isLoading = useAppSelector(AuthorsState.selectLoadingState);
  const error = useAppSelector(AuthorsState.selectFetchManyError);

  return {
    persons,
    isLoading,
    error,
  };
}
