import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getWorkById,
  getWorksByAuthorId,
  getWorksByInstitutionId,
} from "../api";
import type {
  PaginatedRequestParams,
  PaginatedResponse,
} from "../api/response.types";
import type { AuthorId } from "../../domain/Author/Author.types";
import type { RootState } from "../store";
import type { Work, WorkId } from "../../domain/Work/Work.types";
import { InstitutionId } from "../../domain/Institution/Institution.types";

const worksAdapter = createEntityAdapter<Work>();

const Works = createSlice({
  name: "works",
  initialState: worksAdapter.getInitialState({
    fetchOneLoading: false,
    fetchManyLoading: false,
    fetchManyError: null,
    fetchOneError: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkById.pending, (state) => {
      state.fetchOneLoading = true;
      state.fetchOneError = null;
    });
    builder.addCase(fetchWorkById.fulfilled, (state, { payload }) => {
      worksAdapter.setOne(state, payload);
      state.fetchOneLoading = false;
      state.fetchOneError = null;
    });
    builder.addCase(fetchWorkById.rejected, (state, { payload }) => {
      state.fetchOneLoading = false;
      // @ts-expect-error
      state.fetchOneError = payload;
    });
    builder.addCase(fetchWorksByAuthorId.pending, fetchManyLoadingReducer);
    builder.addCase(fetchWorksByAuthorId.fulfilled, (state, { payload }) => {
      state.fetchManyLoading = false;
      state.fetchManyError = null;
      if (payload.page === 1) {
        worksAdapter.setAll(state, payload.items);
      } else {
        worksAdapter.addMany(state, payload.items);
      }
    });
    builder.addCase(fetchWorksByAuthorId.rejected, (state, { payload }) => {
      state.fetchManyLoading = false;
      // @ts-expect-error
      state.fetchManyError = payload;
    });
    builder.addCase(fetchWorksByInstitutionId.pending, fetchManyLoadingReducer);
    builder.addCase(
      fetchWorksByInstitutionId.fulfilled,
      fetchManySuccessReducer,
    );
    builder.addCase(fetchWorksByInstitutionId.rejected, fetchManyFailReducer);
  },
});

function fetchManyLoadingReducer(state: RootState["Works"]) {
  state.fetchManyLoading = true;
  state.fetchManyError = null;
}

function fetchManySuccessReducer(
  state: RootState["Works"],
  { payload }: PayloadAction<PaginatedResponse<Work>>,
) {
  state.fetchManyLoading = false;
  state.fetchManyError = null;
  if (payload.page === 1) {
    worksAdapter.setAll(state, payload.items);
  } else {
    worksAdapter.addMany(state, payload.items);
  }
}

// @ts-expect-error
function fetchManyFailReducer(state: RootState["Works"], { payload }) {
  state.fetchManyLoading = false;
  state.fetchManyError = payload;
}

const selectors = worksAdapter.getSelectors((state: RootState) => state.Works);

export default Works.reducer;

const fetchWorksByAuthorId = createAsyncThunk(
  "works/fetchByAuthorId",
  async (params: PaginatedRequestParams<AuthorId>, { rejectWithValue }) => {
    try {
      const { data } = await getWorksByAuthorId(params);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

const fetchWorksByInstitutionId = createAsyncThunk(
  "works/fetchByInstitutionId",
  async (
    params: PaginatedRequestParams<InstitutionId>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await getWorksByInstitutionId(params);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

const fetchWorkById = createAsyncThunk(
  "work/fetchById",
  async (id: WorkId, { rejectWithValue }) => {
    try {
      const { data } = await getWorkById(id);

      return data;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

const selectWorks = (state: RootState) => selectors.selectAll(state);

const selectWorkById = (id: WorkId) => (state: RootState) =>
  selectors.selectById(state, id) || null;

const selectFetchManyLoadingState = (state: RootState) =>
  state.Works.fetchManyLoading;

const selectFetchManyError = (state: RootState) => state.Works.fetchManyError;

const selectFetchOneLoadingState = (state: RootState) =>
  state.Works.fetchOneLoading;

const selectFetchOneError = (state: RootState) => state.Works.fetchOneError;

export const WorksState = {
  fetchWorkById,
  fetchWorksByAuthorId,
  fetchWorksByInstitutionId,
  selectWorks,
  selectWorkById,
  selectFetchManyLoadingState,
  selectFetchManyError,
  selectFetchOneLoadingState,
  selectFetchOneError,
};
