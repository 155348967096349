import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import React, { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, ToggleButton, Tooltip, Typography } from "@mui/material";
import AuthorsListItem, { StyledGroup } from "../ListItem";

const ListItemWrapper = styled(Box)`
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__bottom {
        padding-left: 36px;
      }

      &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  ul {
    margin: 20px 0 0 20px;
    padding: 0;
    list-style: none;

    li {
      margin-top: 16px;
    }
  }
`;

const InstitutionItem = ({
  name,
  hindex = 0,
  works = 0,
  items,
  onUpdate,
  id,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <ListItemWrapper justifyContent="space-between">
      <div className="heading">
        <div className="left">
          {items.length && (
            <div className="left__top">
              <IconButton size="xs" onClick={() => setOpen(!open)}>
                {!open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <Link to={`/institution/${id}`}>
                <Typography variant="h4">{name}</Typography>
              </Link>
            </div>
          )}
          <div className="left__bottom">
            <StyledGroup
              value={"left"}
              exclusive
              size="small"
              aria-label="text alignment"
            >
              {works && (
                <Tooltip title="works" placement="top" arrow>
                  <ToggleButton value="left" aria-label="left aligned">
                    {works}
                  </ToggleButton>
                </Tooltip>
              )}
              {hindex && (
                <Tooltip title="max h-index" placement="top" arrow>
                  <ToggleButton
                    className="Mui-selected"
                    value="right"
                    aria-label="right aligned"
                  >
                    {hindex}
                  </ToggleButton>
                </Tooltip>
              )}
            </StyledGroup>
          </div>
        </div>
      </div>
      {open && (
        <ul className="list">
          {items.map((item) => (
            <li>
              <AuthorsListItem
                id={item.id}
                onUpdate={onUpdate}
                cited={item.cited_by_count}
                name={item.display_name}
                hindex={item.hindex}
                works={item.works_count}
                linkedin={item.linkedin_url}
                emails={item.emails}
                searchDate={item.linkedin_url_updated_date}
              />
            </li>
          ))}
        </ul>
      )}
    </ListItemWrapper>
  );
};

export default InstitutionItem;
