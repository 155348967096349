import * as selectors from "./selectors";
import * as effects from "./effects";
import { actions } from "./state";

export * from "./types";

export { default } from "./state";

export const ActiveFilterState = {
  ...selectors,
  ...actions,
  ...effects,
};
