import {
  createContext,
  useContext,
  type FC,
  type PropsWithChildren,
} from "react";
import filtersState from "./filters";
import WorksState from "./works";
import { FoldersState, FolderState } from "~/widgets/Folder";

const AppStateContext = createContext({
  filters: filtersState,
  Works: WorksState,
  Folders: FoldersState,
  Folder: FolderState,
});

export const AppStateProvider: FC<PropsWithChildren> = ({ children }) => (
  <AppStateContext.Provider
    value={{
      filters: filtersState,
      Works: WorksState,
      Folders: FoldersState,
      Folder: FolderState,
    }}
  >
    {children}
  </AppStateContext.Provider>
);

export const useAppState = () => useContext(AppStateContext);
