import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Avatar, Tooltip, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "@mui/system";
import React, { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { searchAuthorLinkedin, searchAuthorEmails } from "../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import useStore from "../services/store/store";

export const StyledGroup = styled(ToggleButtonGroup)`
  border: 0;
  margin: 4px;
  & {
    button {
      line-height: 1;
      padding: 4px 8px;
      border: none !important;
      background: rgba(0, 0, 0, 0.04);
      &.Mui-selected {
        background: rgba(0, 0, 0, 0.06);
        padding: 4px 8px;
        font-weight: 700;
      }
      &.Mui-disabled {
        border: none;
      }
    }

    button:not(:first-of-type) {
      border-radius: 8px;
    }

    button:first-of-type {
      border-radius: 8px;
    }
  }
`;

const AuthorEmailItemWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;

  .email-items {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .email-item-content {
    padding: 4px 0;
    border-bottom: 1px solid #fff;
  }
`;

const StyledTootip = styled(Tooltip)`
  max-height: 300px;
  overflow: scroll;
`;

const ListItemWrapper = styled(Box)`
  .linkedin__icon,
  .email__icon {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background: #96bafc;
    width: 16px;
    height: 16px;
  }

  .email__icon {
    background: #4caf50;
  }

  .email__icon_empty {
    background: #ef5350;
  }

  .linkedin__icon_empty {
    background: #001a34;
    opacity: 0.2;
  }

  .author {
    display: flex;
    &__contacts {
      display: flex;
      flex: 1 0 40px;
      justify-content: space-between;
    }
    &__linkedin,
    &__email {
      cursor: pointer;
      &__item {
        font-size: 20px;
      }
    }
    &__info {
      margin-left: 8px;
      .info__name {
      }
      .info__universtity {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.42);
      }
    }
  }
`;

const AuthorsListItem = ({
  cited,
  name,
  hindex,
  works,
  linkedin,
  emails,
  institution,
  onUpdate,
  id,
  searchDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [setOpenOverlay] = useStore((state) => [state.setOpenOverlay]);

  const onSearchLinkedin = async (id) => {
    if (linkedin) {
      window.open(linkedin, "_blank")?.focus();
      return;
    }
    setLoading(true);
    try {
      const res = await searchAuthorLinkedin(id);
      onUpdate(res.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onSearchEmail = async (id) => {
    if (emails?.length) {
      setOpenOverlay({ EMAILS: emails });
      return;
    }
    setLoading(true);
    try {
      const res = await searchAuthorEmails(id);
      onUpdate(res.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ListItemWrapper display="flex" flex={1} justifyContent="space-between">
      <div className="left">
        <div className="author">
          <div className="author__contacts">
            <div
              onClick={() => onSearchLinkedin(id)}
              className="author__linkedin"
            >
              {linkedin && (
                <Tooltip title="link" placement="top" arrow>
                  <Avatar
                    width="20px"
                    height="20px"
                    className="linkedin__icon"
                    alt="Remy Sharp"
                  >
                    in
                  </Avatar>
                </Tooltip>
              )}
              {!linkedin && !searchDate && (
                <Tooltip title="not searched" placement="top" arrow>
                  <Avatar
                    width="20px"
                    height="20px"
                    className="linkedin__icon linkedin__icon_empty"
                    alt="Remy Sharp"
                  >
                    {loading ? (
                      <CircularProgress color="white" size={30} />
                    ) : (
                      <LanguageIcon fontSize="small" />
                    )}
                  </Avatar>
                </Tooltip>
              )}
              {!linkedin && searchDate && (
                <Tooltip title="not found" placement="top" arrow>
                  <Avatar
                    width="20px"
                    height="20px"
                    className="linkedin__icon linkedin__icon_empty"
                    alt="Remy Sharp"
                  >
                    {loading ? (
                      <CircularProgress color="white" size={20} />
                    ) : (
                      <NotInterestedIcon fontSize="small" />
                    )}
                  </Avatar>
                </Tooltip>
              )}
            </div>
            <div onClick={() => onSearchEmail(id)} className="author__email">
              {!!emails?.length && (
                <Avatar
                  width="20px"
                  height="20px"
                  className="email__icon"
                  alt="Remy Sharp"
                >
                  @
                </Avatar>
              )}
              {!emails?.length && (
                <Tooltip title="not found" placement="top" arrow>
                  <Avatar
                    width="20px"
                    height="20px"
                    className="email__icon email__icon_empty"
                    alt="Remy Sharp"
                  >
                    {loading ? (
                      <CircularProgress color="white" size={30} />
                    ) : (
                      <Avatar
                        width="20px"
                        height="20px"
                        className="email__icon email__icon_empty"
                        alt="Remy Sharp"
                      >
                        <s>@</s>
                      </Avatar>
                    )}
                  </Avatar>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="author__info">
            <Link to={`/author/${id}`}>
              <Typography
                color={linkedin ? "#005BFF" : "rgba(0, 0, 0, 0.92)"}
                variant="h4"
              >
                {name}
              </Typography>
            </Link>
            {institution && (
              <div className="info__universtity">{institution}</div>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        <StyledGroup
          value={"left"}
          exclusive
          size="small"
          aria-label="text alignment"
        >
          {works && (
            <Tooltip title="works" placement="top" arrow>
              <ToggleButton value="left" aria-label="left aligned">
                {works}
              </ToggleButton>
            </Tooltip>
          )}
          {hindex && (
            <Tooltip title="h-index" placement="top" arrow>
              <ToggleButton
                className="Mui-selected"
                value="right"
                aria-label="right aligned"
              >
                {hindex}
              </ToggleButton>
            </Tooltip>
          )}
        </StyledGroup>
      </div>
    </ListItemWrapper>
  );
};

export default AuthorsListItem;
