import {
  useCallback,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
} from "react";
import cn from "classnames";

import Paper from "~/ui-kit/Paper";
import Button from "~/ui-kit/Button";

import Angle from "~/icons/Angle";

import styles from "./Card.module.scss";

interface CardProps {
  title: string;
  collapsible?: boolean;
  disabled?: boolean;
}

const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  disabled = false,
  collapsible = false,
}) => {
  const { collapsed, handleToggle } = useCollapsibleCard(collapsible);
  const togglerStyles = useMemo(
    () => ({
      transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
    }),
    [collapsed]
  );

  return (
    <Paper className={cn(styles.card, disabled && styles.disabled)}>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        {collapsible && (
          <Button onClick={handleToggle} shape="icon">
            <Angle className={styles.toggler} style={togglerStyles} />
          </Button>
        )}
      </header>
      {disabled && <div className={styles.disabled} />}
    </Paper>
  );
};

export default Card;

function useCollapsibleCard(collapsible: boolean) {
  const [collapsed, toggleCollapse] = useState(collapsible ? true : null);
  const handleToggle = useCallback(() => {
    toggleCollapse(collapsed ? false : true);
  }, [collapsed, toggleCollapse]);

  return {
    collapsed,
    handleToggle,
  };
}
