import { useEffect, type FC, type HTMLProps } from "react";
import cn from "classnames";

import { Alert, Box, CircularProgress } from "@mui/material";

import TopAuthors from "../../../components/TopAuthors";

import { AuthorsState } from "../../../store/Authors";

import { useAppDispatch } from "../../../store/hooks";
import { useTopAuthors } from "../../../store/Authors/hooks";

import type { InstitutionId } from "../../../domain/Institution/Institution.types";

import styles from "./InstitutionAuthors.module.scss";

interface InstitutionAuthorsProps {
  id: InstitutionId;
}

const InstitutionAuthors: FC<
  InstitutionAuthorsProps & HTMLProps<HTMLElement>
> = ({ id, className }) => {
  const { persons, isLoading, error } = useTopAuthors();
  useInstitutionAuthorsInitialFetchRequest(id);

  if (!persons.length && isLoading) {
    return (
      <Box className={className} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" className={className}>
        {error}
      </Alert>
    );
  }

  return (
    <section className={cn(className, styles.coauthors)}>
      <TopAuthors
        className={cn(isLoading && styles.loading)}
        id={id}
        persons={persons}
      />
    </section>
  );
};

export default InstitutionAuthors;

function useInstitutionAuthorsInitialFetchRequest(id: InstitutionId) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      AuthorsState.fetchAuthorsByInstitutionId({ id, page: "1", size: "15" })
    );
  }, [dispatch, id]);
}
