import { Chip } from "@mui/material";
import { styled } from "@mui/system";

const CustomChip = styled(Chip)`
  border-radius: 0;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  height: 24px;
  margin-left: 0;

  &.active {
    background: #005BFF;
    color: #fff;
  }
`;

export default CustomChip;
