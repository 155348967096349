import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";

import SearchBar from "~/common/searchBar";

const Main = () => {
  const history = useHistory();

  const setSearchFromStory = async (task) => {
    history.push(`/search/task/${task.id}`);
  };

  return (
    <Box alignItems="center" display="flex" flex={1}>
      <Box display="flex" flex="1" justifyContent="center">
        <SearchBar showHistory onSelectFromHistory={setSearchFromStory} />
      </Box>
    </Box>
  );
};

export default Main;
