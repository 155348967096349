import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const QuotesIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M3.83277 6.40707C3.73422 6.58448 3.64966 6.80059 3.57962 7.0615C3.8838 7.01749 4.24315 6.99998 4.66667 6.99998C6.66667 6.99998 7.33333 7.66665 7.33333 9.66665C7.33333 11.6666 6.66667 12.3333 4.66667 12.3333C2.66667 12.3333 2 11.6666 2 9.66665C2 7.99221 2.16046 6.67174 2.66723 5.75955C2.93222 5.28257 3.29423 4.91161 3.76761 4.66775C4.23265 4.42818 4.76215 4.33331 5.33333 4.33331C5.70152 4.33331 6 4.63179 6 4.99998C6 5.36817 5.70152 5.66665 5.33333 5.66665C4.90452 5.66665 4.60068 5.73844 4.37822 5.85305C4.1641 5.96335 3.98445 6.13405 3.83277 6.40707Z" />
        <path d="M8.66667 9.66665C8.66667 7.99221 8.82712 6.67174 9.3339 5.75955C9.59888 5.28257 9.9609 4.91161 10.4343 4.66775C10.8993 4.42818 11.4288 4.33331 12 4.33331C12.3682 4.33331 12.6667 4.63179 12.6667 4.99998C12.6667 5.36817 12.3682 5.66665 12 5.66665C11.5712 5.66665 11.2673 5.73844 11.0449 5.85305C10.8308 5.96335 10.6511 6.13405 10.4994 6.40707C10.4009 6.58448 10.3163 6.80059 10.2463 7.0615C10.5505 7.01749 10.9098 6.99998 11.3333 6.99998C13.3333 6.99998 14 7.66665 14 9.66665C14 11.6666 13.3333 12.3333 11.3333 12.3333C9.33333 12.3333 8.66667 11.6666 8.66667 9.66665Z" />
      </g>
    </Icon>
  );
};

export default QuotesIcon;
