export type TSubscriber = (viewport: TViewport) => void;
export type TViewport = {
  x: number;
  y: number;
  scale: number;
};

const INITIAL_VIEWPORT: TViewport = {
  x: document.body.offsetWidth / 2,
  y: 0,
  scale: 1,
};

export class UnexistedElementError extends Error {
  constructor(elementName: string) {
    super(`The ${elementName} element does not exist.`);
  }
}

class WorkspaceViewportService {
  private subscribes: Array<TSubscriber> = [];
  private viewport: TViewport | undefined = undefined;
  private canvas: SVGSVGElement | undefined = undefined;
  private graph: SVGGElement | undefined = undefined;

  private change(viewport: Partial<TViewport>): void {
    const { viewport: existedViewport } = this;

    if (!existedViewport) {
      throw new Error(`Attempt to change empty viewport.`);
    }

    const nextViewport = {
      ...(this.viewport as TViewport),
      ...viewport,
    };

    this.viewport = nextViewport;

    this.subscribes.forEach((handler) => handler(nextViewport));
  }

  public inititalize(viewport: Partial<TViewport> = {}): void {
    this.viewport = {
      ...viewport,
      ...INITIAL_VIEWPORT,
    };

    this.subscribes.forEach((handler) => handler(this.viewport as TViewport));
  }

  public clear(): void {
    this.viewport = undefined;
    this.canvas = undefined;
    this.graph = undefined;
  }

  public subscribe(handler: TSubscriber): void {
    this.subscribes.push(handler);

    if (this.viewport) {
      handler(this.viewport);
    }
  }

  public unsubscribe(handler: TSubscriber): void {
    const subscriberIndex = this.subscribes.indexOf(handler);

    if (subscriberIndex === -1) {
      throw new Error(`Attempt to unsubscribe for unexisted subscriber.`);
    } else {
      this.subscribes.splice(subscriberIndex, 1);
    }
  }

  public getViewport(): TViewport {
    if (!this.viewport) {
      throw new Error(`Attempt to get empty viewport.`);
    }

    return this.viewport;
  }

  public zoom(x: TViewport['x'], y: TViewport['y'], scale: TViewport['scale']): void {
    this.change({
      x,
      y,
      scale,
    });
  }

  public move(x: TViewport['x'], y: TViewport['y']): void {
    this.change({
      x,
      y,
    });
  }

  public getGraphElement(): SVGGElement {
    if (!this.graph) {
      throw new UnexistedElementError('graph');
    }

    return this.graph;
  }

  public getCanvasElement(): SVGSVGElement {
    if (!this.canvas) {
      throw new UnexistedElementError('canvas');
    }

    return this.canvas;
  }

  public setCanvasElement(element: SVGSVGElement): void {
    if (this.canvas) {
      throw new Error('Canvas element already exists');
    }

    this.canvas = element;
  }

  public setGraphElement(element: SVGGElement): void {
    if (this.graph) {
      throw new Error('Graph element already exists');
    }

    this.graph = element;
  }
}

export const workspaceViewportService = new WorkspaceViewportService();
