import React, { useEffect, useState } from 'react';
import BasicDialog from './Basic';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  li {
    padding: 8px 0;
  }

  li + li {
    border-top: 1px solid #eee;
  }
`;


const EmailsOverlay = (props) => {
  const { open } = props;

  return (
    <BasicDialog {...props}>
      <Box sx={{ display: 'flex', position: 'sticky', top: 0, padding: '0' }}>
        <List>
          {open.map(i => (
            <li>
              <div className="heading">
                <Typography color={'primary'} variant="h3">{i.email}</Typography>
                <Typography variant="h4">{i.publication_date}</Typography>
              </div>
              <div>{i.institution}</div>
            </li>
          ))}
        </List>
      </Box>
    </BasicDialog>
  )
};

export default EmailsOverlay;
