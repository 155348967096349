import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Add: FC<IconProps> = (props) => {
  return (
    <Icon {...props} sizeCoef="2x" viewBoxCoef="2x">
      <g className={styles.strokeShape}>
        <path
          d="M7.99967 3.33301V12.6663M3.33301 7.99967H12.6663"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
};

export default Add;
